import { SET_STAFFID } from './actions';
import { SET_STAFFNAME } from './actions';
import { SET_EMAIL } from './actions';
import { SET_DESIGNATION } from './actions';
//  import { SET_AGE } from './actions';
//  import { SET_PHONE } from './actions';

const initialState = {
  staffid: '',
  staffname:'',
  email:'',
  designation:'',
//  age:'',
//  phone:''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFFID:
      return {
        ...state,
        staffid: action.payload,
      };
      case SET_STAFFNAME:
        return {
          ...state,
          staffname: action.payload,
        };
        case SET_EMAIL:
          return {
            ...state,
            email: action.payload,
          };
          case SET_DESIGNATION:
            return {
              ...state,
              designation: action.payload,
            };
      // case SET_AGE:
      // return {
      //   ...state,
      //   age: action.payload,
      // };

      // case SET_PHONE:
      //   return {
      //     ...state,
      //     phone: action.payload,
      //   };
    default:
      return state;
  }
};

export default reducer;
