import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTachometerAlt,
  FaTasks,
  FaUserAlt,
  FaTools,
  FaRegCommentDots,
  FaMoneyBillAlt,
  FaBox,
  FaClipboardList,
  FaFileAlt,
  FaUsers,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaUserFriends,
  FaBuilding,
  FaCog,
  FaWrench,
  FaChevronRight,
  FaChevronDown,
  FaUserShield,
  FaEllipsisV,
} from "react-icons/fa";
import "./Sidebar.css";

const Sidebar = () => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("dashboard"); // Default active menu

  const handleNavigation = (route, menu) => {
    setActiveMenu(menu);
    navigate(route);
  };

  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const toggleAdminMenu = () => setIsAdminOpen(!isAdminOpen);

  const [isStockOpen, setIsStockOpen] = useState(false);
  const toggleStockMenu = () => setIsStockOpen(!isStockOpen);

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <div className="logo">INTERJET</div>

        <ul className="menu">
          <li
            className={`menu-item ${
              activeMenu === "dashboard" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/dashboard", "dashboard")}
          >
            <FaTachometerAlt className="icon" />
            <span className="items">Dashboard</span>
          </li>

          <li
            className={`menu-item ${activeMenu === "task" ? "active" : ""}`}
            onClick={() => handleNavigation("/task", "task")}
          >
            <FaTasks className="icon" />
            <span className="items">Task</span>
          </li>

          <li
            className={`menu-item ${activeMenu === "enquiry" ? "active" : ""}`}
            onClick={() => handleNavigation("/enquiry", "enquiry")}
          >
            <FaUserAlt className="icon" />
            <span className="items">Enquiry</span>
          </li>

          <li
            className={`menu-item ${
              activeMenu === "installation" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/installation", "installation")}
          >
            <FaTools className="icon" />
            <span className="items">Installation</span>
          </li>

          <li
            className={`menu-item ${
              activeMenu === "complaint" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/complaint", "complaint")}
          >
            <FaRegCommentDots className="icon" />
            <span className="items">Complaint</span>
          </li>

          <li
            className={`menu-item ${activeMenu === "duessection" ? "active" : ""}`}
            onClick={() => handleNavigation("/duessection", "duessection")}
          >
            <FaMoneyBillAlt className="icon" />
            <span className="items">Dues</span>
          </li>

          <li className="menu-item" onClick={toggleStockMenu}>
            <FaBox className="icon" />
            <span
              className={`items ${activeMenu === "stock" ? "active" : ""}`}
              onClick={() => handleNavigation("/stock", "stock")}
            >
              Stock
            </span>
            {isStockOpen ? (
              <FaChevronDown className="icon" />
            ) : (
              <FaChevronRight className="icon" />
            )}
          </li>

          {isStockOpen && (
            <ul className="submenu">
              <li
                className={`menu-item ${activeMenu === "managestock" ? "active" : ""}`}
                onClick={() => handleNavigation("/managestock", "managestock")}
              >
                <FaEllipsisV className="icon-stock" />
                <span className="items-stock">Menu</span>
              </li>
            </ul>
          )}

          <li
            className={`menu-item ${activeMenu === "recovery" ? "active" : ""}`}
            onClick={() => handleNavigation("/recovery", "recovery")}
          >
            <FaClipboardList className="icon" />
            <span className="items">Recovery</span>
          </li>

          <li
            className={`menu-item ${
              activeMenu === "customerreportsection" ? "active" : ""
            }`}
            onClick={() =>
              handleNavigation("/customerreportsection", "customerreportsection")
            }
          >
            <FaFileAlt className="icon" />
            <span className="items">Customer Report</span>
          </li>

          <li
            className={`menu-item ${
              activeMenu === "staffanalysis" ? "active" : ""
            }`}
            onClick={() =>
              handleNavigation("/staffanalysissection", "staffanalysis")
            }
          >
            <FaUsers className="icon" />
            <span className="items">Staff Analysis</span>
          </li>

 
          <li
            className={`menu-item ${
              activeMenu === "attendance" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/attendancesection", "attendance")}
          >
 
            <FaCalendarAlt className="icon" />
            <span className="items">Attendance</span>
          </li>

          <li
            className={`menu-item ${activeMenu === "location" ? "active" : ""}`}
            onClick={() => handleNavigation("/locationsection", "location")}
          >
            <FaMapMarkerAlt className="icon" />
            <span className="items">Location</span>
          </li>

          <li
            className={`menu-item ${activeMenu === "staffs" ? "active" : ""}`}
            onClick={() => handleNavigation("/staffsection", "staffs")}
          >
            <FaUserFriends className="icon" />
            <span className="items">Staffs</span>
          </li>

          <li
            className={`menu-item ${
              activeMenu === "customers" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/customersection", "customers")}
          >
            <FaBuilding className="icon" />
            <span className="items">Customers</span>
          </li>

          <li className="menu-item" onClick={toggleAdminMenu}>
            <FaCog className="icon" />
            <span
              className={`items ${activeMenu === "admin" ? "active" : ""}`}
              onClick={() =>
                handleNavigation("/administrationsection", "admin")
              }
            >
              Administration
            </span>
            {isAdminOpen ? (
              <FaChevronDown className="icon" />
            ) : (
              <FaChevronRight className="icon" />
            )}
          </li>

          {isAdminOpen && (
            <ul className="submenu">
              <li
                className={`menu-item ${
                  activeMenu === "roles" ? "active" : ""
                }`}
                onClick={() => handleNavigation("/roles", "roles")}
              >
                <FaUserShield className="icon-admin" />
                <span className="items-admin">Roles</span>
              </li>
            </ul>
          )}

          <li
            className={`menu-item ${activeMenu === "settings" ? "active" : ""}`}
            onClick={() => handleNavigation("/settingssection", "settings")}
          >
            <FaWrench className="icon" />
            <span className="items">Settings</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
