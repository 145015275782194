import React, { useEffect, useState } from "react";
import "./AddStock.css";
import Topbar from "../Topbar/Topbar";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";

export const AddStock = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [materialFetch, setMaterialFetch] = useState([]);

  const [stock, setStock] = useState({
    date: "",
    category: "",
    name: "",
    materials: [],
  });

  const [materialInput, setMaterialInput] = useState({
    material: "",
    materialType: "",
    unit: "",
    sellingCost: "",
  });

  const handleMaterialInputChange = (e) => {
    const { name, value } = e.target;

    const numericFields = ["unit", "sellingCost"];

    setMaterialInput((prev) => ({
      ...prev,
      [name]: numericFields.includes(name) ? Number(value) : value,
    }));
  };

  const addMaterialToStock = (e) => {
    e.preventDefault();

    const { material, materialType, unit, sellingCost } = materialInput;

    if (!material || !materialType || !unit || !sellingCost) {
      toast.error("Please fill in all material fields before adding.");
      return;
    }

    const isDuplicate = stock.materials.some(
      (item) => item.material === material && item.materialType === materialType
    );

    if (isDuplicate) {
      toast.warning("This material with the same type is already added.");
      return;
    }

    setStock((prev) => ({
      ...prev,
      materials: [...prev.materials, materialInput],
    }));

    setMaterialInput({
      material: "",
      materialType: "",
      unit: "",
      sellingCost: "",
    });
  };

  const handleRemoveMaterial = (index) => {
    const updated = [...stock.materials];
    updated.splice(index, 1);
    setStock((prev) => ({
      ...prev,
      materials: updated,
    }));
  };

  const formatToINR = (amount) => {
    if (isNaN(amount)) return "₹0";

    return `₹${Number(amount).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  useEffect(() => {
    const fetchStockDropdownMaterials = async () => {
      try {
        const response = await axios.get(`${apiUrl}/manage-stock/get-all`);
        setMaterialFetch(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchStockDropdownMaterials();
  }, [apiUrl]);

  const handleStockInputChange = (e) => {
    const { name, value } = e.target;
    setStock((prev) => ({
      ...prev,
      [name]: value,
      materialTypes: name === "materials" ? "" : prev.materialType,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { date, category, name, materials } = stock;

    if (!date || !category || !name) {
      toast.error(
        "Please fill in all stock details (date, category, vendor / customer name)."
      );
      return;
    }

    if (materials.length === 0) {
      toast.warning("Please add at least one material before submitting.");
      return;
    }

    try {
      await axios.post(apiUrl + "stock/entries", stock).then((res) => {
        navigate("/stock");
      });
    } catch (error) {
      toast.error("Error submitting stock entry");
      console.error(
        "Error submitting stock entry:",
        error.response?.data || error.message
      );
      // Optional: show error messages to the user
    }

    console.log("Stock submitted:", stock);
    toast.success("Stock submitted successfully!");
  };

  const handleUpdate = async (id, data) => {

    try {
      const response = await axios.put(`${apiUrl}/stock/entries/${id}`, data);
      toast.success("Stock updatted successfully!");
      console.log("Updated entry:", response.data);
    } catch (error) {
      toast.error("Error updating stock entry");
      console.log(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const filteredMaterialTypes = materialInput.material
    ? materialFetch.filter((d) => d.material === materialInput.material)
    : [];

  useEffect(() => {
    const fetchStockEntry = async () => {
      try {
        const response = await axios.get(`${apiUrl}stock/entries/${id}`);
        const fetchedData = response.data;

        // Update stock state
        setStock({
          date: new Date(fetchedData.date).toISOString().split("T")[0] || "",
          category: fetchedData.category || "",
          name: fetchedData.name || "",
          materials: fetchedData.materials || [],
        });
      } catch (err) {
        console.error("Error fetching stock entry:", err.message);
      }
    };

    if (id) {
      fetchStockEntry();
    }
  }, [id, apiUrl]);

  return (
    <>
      <Topbar />
      <Container className="mt-1">
        <form onSubmit={addMaterialToStock}>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col md={2} sm={2} className="text-start">
              <h3>Stock</h3>
            </Col>
            <Col className="text-end">
              <button
                type="button"
                className="blue-btn stock-submit-btn"
                onClick={(e) => !id ? handleSubmit(e) : handleUpdate(id, stock)}
              >
                {id ? "Update" : "Submit"}
              </button>
            </Col>
          </Row>
          <div className="border-container1">
            <h6 className="form-head text-white stock-head mb-0">Add Stock</h6>
          </div>
          <div className="border-container-stock">
            <Row className="border-container-plus1">
              <Col xs={12} md={6} className="text-start">
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <label className="">Date</label>
                    <br />
                    <input
                      className="form-input"
                      type="Date"
                      name="date"
                      value={stock.date}
                      onChange={(e) =>
                        setStock({ ...stock, date: e.target.value })
                      }
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <label className="">category</label>
                    <br />
                    <select
                      className="dropbtn form-input-select"
                      name="category"
                      value={stock.category}
                      onChange={(e) =>
                        setStock({ ...stock, category: e.target.value })
                      }
                    >
                      <option value="">Select</option>
                      <option className="" value="Purchase">
                        Purchase
                      </option>
                      <option className="" value="Recovery">
                        Recovery
                      </option>
                      <option className="" value="Damage">
                        Damage
                      </option>
                      <option className=" " value="Installation">
                        Installation
                      </option>
                      <option className="" value="Complaint">
                        Complaint
                      </option>
                    </select>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={6} className="text-start">
                <Row>
                  <Col xs={12} md={12} className="text-start">
                    <lable className="">Vendor / Customer Name</lable>
                    <br />
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={stock.name}
                      onChange={(e) =>
                        setStock({ ...stock, name: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              {stock.materials.length > 0 && (
                <>
                  <h6 className="add-material">Added Materials</h6>
                  <Col xs={12} className="mt-1 mb-2 border-container-plus">
                    {stock.materials.map((mat, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-center bg-white border rounded p-2 mb-2"
                      >
                        {/* <div className="fw-bold">
                          <span>{mat.material} ({mat.materialType}) • </span><span className="text-primary">{mat.unit}</span>
                        </div> */}
                        <div className="fw-bold">
                          <span>
                            {mat.material} ({mat.materialType}) •{" "}
                          </span>
                          <span className="text-primary">
                            {["2F", "4F", "6F", "Cat 6 Cable"].includes(
                              mat.materialType
                            )
                              ? `${mat.unit} M`
                              : mat.unit}
                          </span>
                        </div>

                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                          onClick={() => handleRemoveMaterial(index)}
                        >
                          <span style={{ fontSize: "20px" }}> ×</span>
                        </button>
                      </div>
                    ))}
                  </Col>
                </>
              )}

              <Col xs={12} md={6} className="text-start">
                <Row className="mt-3">
                  <Col xs={12} md={6} className="text-start">
                    <label className="">Materials</label>
                    <br />
                    <select
                      className="dropbtn form-input-select"
                      name="material"
                      value={materialInput.material}
                      onChange={handleMaterialInputChange}
                    >
                      <option value="">Select Material</option>
                      {[...new Set(materialFetch.map((d) => d.material))].map(
                        (material, i) => (
                          <option key={i} value={material}>
                            {material}
                          </option>
                        )
                      )}
                    </select>
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    {stock.materials && ( // Show only if materials is selected
                      <>
                        <label className="">Material Types</label>
                        <br />
                        <select
                          className="dropbtn form-input-select"
                          name="materialType"
                          value={materialInput.materialType}
                          onChange={handleMaterialInputChange}
                        >
                          <option value="">Select Material Type</option>
                          {filteredMaterialTypes.map((d, i) => (
                            <option key={i} value={d.materialType}>
                              {d.materialType}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={6} className="text-start">
                <Row className="mt-3">
                  <Col xs={12} md={6} className="text-start">
                    <label className="">Unit</label>
                    <br />
                    <input
                      className="form-input"
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      name="unit"
                      value={materialInput.unit}
                      onChange={handleMaterialInputChange}
                    />
                  </Col>

                  <Col xs={12} md={4} className="text-start">
                    <label className="">Selling Cost</label>
                    <br />
                    <input
                      className="form-input"
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Rs. 0"
                      name="sellingCost"
                      value={materialInput.sellingCost}
                      onChange={handleMaterialInputChange}
                    />
                  </Col>

                  <Col xs={12} md={2} className="text-start mt-3">
                    <label></label>
                    <br />
                    <button
                      type="submit"
                      className="blue-btn plus-blue-btn"
                      style={{ fontSize: "20px" }}
                    >
                      +
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
};
