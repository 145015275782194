import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "../../components/header/Header"; // Adjust the import path

import "./App.css";

import Login from "../../components/Login/Login";
import Register from "../../components/Login/Register";
import Password from "../../components/Login/Password";
import Newpassword from "../../components/Login/Newpassword";
import CustomerSection from "../../components/CustomerSection/CustomerSection";
import StaffSection from "../../components/StaffSection/StaffSection";
import Addstaff from "../../components/Staff/AddStaff";
import { Edit_Staff } from "../../components/Staff/Edit_Staff";
import SettingsSection from "../../components/SettingsSection/SettingsSection";
import AddCustomer from "../../components/Customer/Add_Customer";
import EditCustomer from "../../components/Customer/Edit_Customer";
import Ottdetails from "../../components/Settings/Ottdetails";
import Leadsource from "../../components/Settings/Leadsource";
import LocationSection from "../../components/LocationSection/LocationSection";
import AdministrationSection from "../../components/AdministrationSection/AdministrationSection";
import RolesList from "../../components/Adminstration/RolesList";
import Dashboard from "../../components/Dashboard/Dashboard";

import Task from "../../components/Task/Task";
// import CreateTask from "../../components/Task/CreateTask";
// import MyTask from "../../components/Task/MyTask";
// import MapView from "../../components/Task/MapView";
// import TaskListView from "../../components/Task/TaskListView";
import AddEnquiry from "../../components/Enquiry/AddEnquiry";
import Add_Complaint from "../../components/Complaint/Add_Complaint";
import Enquiry from "../../components/Enquiry/Enquiry";
import Installation from "../../components/Installation/Installation";
// import Recovery from "../../components/Recovery/Recovery";
import Complaint from "../../components/Complaint/Complaint";

// import Stock from "../../components/Stock/Stock";
import AttendanceSection from "../../components/AttendanceSection/AttendanceSection";

import Stock from "../../components/Stock/Stock";
import View_Complaint from "../../components/Complaint/View_Complaint";
import Feedback_Complaint from "../../components/Complaint/Feedback_Complaint";

import FiberTeam_Complaint from "../../components/Complaint/FiberTeam_Complaint";
import NetworkTeam_Complaint from "../../components/Complaint/NetworkTeam_Complaint";
import OrderDetails_Complaint from "../../components/Complaint/OrderDetails_Complaint";
import Payment_Complaint from "../../components/Complaint/Payment_Complaint";
import Verification_Complaint from "../../components/Complaint/Verification_Complaint";
import WorkAssign_Complaint from "../../components/Complaint/WorkAssign_Complaint";
// import Menu from "../../components/Menu/Menu";

import Roles from "../../components/Roles/Roles";

import Logout from "../../components/Login/Logout";
import NoPage from "../../components/NoPage";

import Add_Installation from "../../components/Installation/Add_Installation";
// import Add_Enquiry from "../../components/Enquiry/Add_Enquiry";
// import Edit_Installation from "../../components/Installation/Edit_Installation";
import CustomerReportSection from "../../components/CustomerReportSection/CustomerReportSection";
import StaffAnalysisSection from "../../components/StaffAnalysisSection/StaffAnalysisSection";
// import LocationSection from "../../components/LocationSection/LocationSection";

import View_Customer from "../../components/Customer/View_Customer";
import { AddStock } from "../../components/Stock/AddStock";
import { PlusStock } from "../../components/Stock/PlusStock";
// import { AddSctock, AddStock } from "../../components/Stock/AddStock";
import ManageStock from "../../components/Stock/ManageStock";
import DuesSection from "../../components/DuesSection/DuesSection";
import AddDues from "../../components/Dues/AddDues";

const MainLayout = () => {
  const location = useLocation();

  // Routes that should not display the Header
  const noHeaderRoutes = ["/", "/password", "/newpassword", "/register", "/*"];
  const is404Page = ![
    "/",
    "/logout",
    "/register",
    "/password",
    "/newpassword",
    "/customersection",
    "/add_customer",
    "/edit_customer",
    "/staffsection",
    "/addstaff",
    "/roles",
    "/editStaff",
    "/administrationsection",
    "/role",
    "/settingssection",
    "/ottdetails",
    "/leadsource",
    "/page1",
    "/page2",
    "/edit_installation",
    "/add_installation",
    "/dashboard",
    "/task",
    "/createtask",
    "/tasklistview",
    "/mytask",
    "/denyupopup",
    "/enquiry",
    "/installation",
    "/add_installation",
    "/edit_installation",
    "/recovery",
    "/complaint",
    "/duessection",
    "/adddues",
    "/stocks",
    "/addstock",
    "/plusstock",
    "/addstock",
    "/plusstock",
    "/stock",
    "/menu",


    "/attendancesection",
    "/locationsection",
    "/customerreportsection",
    "/staffanalysissection",
    "/view_customer",

    "/addenquiry",
    "/add_complaint",

    "/managestock",
    "/view_complaint",
  ].includes(location.pathname);

  return (
    <div className="app-container">
      {/* Conditionally render Header based on current pathname */}

      {!noHeaderRoutes.includes(location.pathname) && !is404Page && <Header />}

      <div className="content-container">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password" element={<Password />} />
          <Route path="/newpassword" element={<Newpassword />} />
          <Route path="/customersection" element={<CustomerSection />} />
          <Route path="/add_customer" element={<AddCustomer />} />
          <Route path="/view_customer" element={<View_Customer />} />
          {/* <Route path="/edit_installation" element={<Edit_Installation />} /> */}

          <Route path="/edit_customer" element={<EditCustomer />} />
          <Route path="/staffsection" element={<StaffSection />} />
          <Route path="/addstaff" element={<Addstaff />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/editStaff" element={<Edit_Staff />} />
          <Route
            path="/administrationsection"
            element={<AdministrationSection />}
          />
          <Route path="/role" element={<RolesList />} />
          <Route path="/settingssection" element={<SettingsSection />} />
          <Route path="/ottdetails" element={<Ottdetails />} />
          <Route path="/leadsource" element={<Leadsource />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/add_installation" element={<Add_Installation />} />
          <Route path="/addenquiry" element={<AddEnquiry />} />

          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/task" element={<Task />} />
          

          <Route path="/edit_installation" element={<Edit_Installation />} /> */}

<Route path="/installation" element={<Installation />} />

 
           <Route path="/dashboard" element={<Dashboard />} />
           <Route path="/customerreportsection" element={<CustomerReportSection />} />
 
        {/*
          <Route path="/task" element={<Task />} />
          <Route path="/createtask" element={<CreateTask />} />
          <Route path="/mytask" element={<MyTask />} />
          <Route path="/taskmapview" element={<MapView />} />
          <Route path="/tasklistview" element={<TaskListView />} />

      

          <Route path="/recovery" element={<Recovery />} />
        
           */}
          <Route path="/duessection" element={<DuesSection />} />
          <Route path="/adddues" element={<AddDues/>} />
          {/* <Route path="/menu" element={<Menu />} /> */}
 
         <Route path="/attendancesection" element={<AttendanceSection />} />
          <Route path="/locationsection" element={<LocationSection />} />
           
 
          <Route path="/staffanalysissection" element={<StaffAnalysisSection />} />

          <Route path="/stock" element={<Stock />} />
          <Route path="/addstock" element={<AddStock />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/add_complaint" element={<Add_Complaint />} />
          <Route path="/view_complaint" element={<View_Complaint />} />

          <Route path="/task" element={<Task />} />

          <Route path="/plusstock" element={<PlusStock />} />
          <Route path="/managestock" element={<ManageStock />} />

          <Route path="*" element={<NoPage />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
};

export default App;
