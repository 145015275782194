import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Add_Customer.css";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaStarOfLife } from "react-icons/fa6";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import * as Yup from "yup";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";

export default function View_Customer() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const locate = useLocation();
  const [customerDetails, setCustomerDetails] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [errorrefund, setErrorRefund] = useState("");
  const [errorinstalltioncharge, setErrorinstallationcharge] = useState("");
  const [receivedDataCustomer, setReceivedDataCustomer] = useState(locate.state?.customerid || "");

  useEffect(() => {
    axios
      .post(apiUrl+"getdata", {
        customerid: locate.state?.customerid,
      })
      .then((res) => {
        setCustomerDetails(res.data.message);
        setOriginalData(res.data.message); // Store original data for comparison
      });
  }, [locate.state?.customerid]);

  const handleChange = (e) => {
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneNumberBlur = () => {
    const regex = /^[0-9]{10}$/;
    if (!regex.test(customerDetails.contactnum || "")) {
      setError("Please enter a valid 10-digit phone number");
    } else {
      setError("");
    }
  };
  const handlePhoneNumberBlur1 = () => {
    const regex1 = /^[0-9]{10}$/;
    if (!regex1.test(customerDetails.alternatenum || "")) {
      setError1("Please enter a valid 10-digit phone number");
    } else {
      setError1("");
    }
  };

  const [value, setValue] = useState("");

  const handledecimal = (e) => {
    const inputValue = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const [value1, setValue1] = useState("");

  const handledecimal1 = (e) => {
    const inputValue1 = e.target.value;
    const regex1 = /^\d*\.?\d{0,2}$/;
    if (regex1.test(inputValue1)) {
      setValue1(inputValue1);
    }
  };

  const handlerefunddeposit = (e) => {
    setCustomerDetails({ ...customerDetails, refunddeposit: e.target.value });
    handledecimal(e);
  };

  const handleintallationcharge = (e) => {
    setCustomerDetails({
      ...customerDetails,
      installationcharge: e.target.value,
    });
    handledecimal1(e);
  };

  const navigate = useNavigate();

  const gotoEditCustomer=(e)=>
  {
    e.preventDefault();
    navigate("/edit_customer",{ state: { customerid: receivedDataCustomer } })
  }

  const updateCustomerData = (e) => {
    e.preventDefault();

    // Validate phone numbers
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(customerDetails.contactnum || "")) {
      setError("Please enter a valid 10-digit phone number");
      return;
    }

    // Validate decimal inputs (installation charge and refund deposit)

    const decimalRegex1 = /^\d*\.?\d{0,2}$/;
    if (!decimalRegex1.test(customerDetails.installationcharge || "")) {
      // alert("Please enter a valid installation charge (up to 2 decimal places)");
      setErrorinstallationcharge(
        "Please enter a valid refund deposit (up to 2 decimal places"
      );

      return;
    }

    const decimalRegex = /^\d*\.?\d{0,2}$/;
    if (!decimalRegex.test(customerDetails.refunddeposit || "")) {
      // alert("Please enter a valid refund deposit (up to 2 decimal places)");
      setErrorRefund(
        "Please enter a valid refund deposit (up to 2 decimal places"
      );

      return;
    }

    // Check if any field has changed
    const isDataChanged = Object.keys(originalData).some(
      (key) => customerDetails[key] !== originalData[key]
    );

    if (!isDataChanged) {
      // alert("Nothing to update");

      Swal.fire({
        title: "<strong>Nothing to update </strong>",
        icon: "info",
        // html: `
        //   You can use <b>bold text</b>,
        //   <a href="#" autofocus>links</a>,
        //   and other HTML tags
        // `,
        showCloseButton: true,

        focusConfirm: false,
      });

      return;
    }
    try {
      axios
        .post(apiUrl+"update", customerDetails)
        .then((res) => {
          // alert(res.data.Message);
          Swal.fire({
            title: "Customer Details Updated Successfully!..",
            icon: "success",
            draggable: true,
          }).then(() => {
            navigate("/customersection");
          });

          console.log(res.data);
        });
    } catch (error) {
      console.log("Error submitting data", error);
    }
  };

  return (
    <>

    <Topbar/>
      <Container className="mt-1  margin-right ">
        <form onSubmit={updateCustomerData}>
          <Row>
            <Col>
              <h3>Customer</h3>
            </Col>

            <Col className="mx-5 text-end">
                                <button type="button" className='black-btn mx-3' onClick={gotoEditCustomer}> <MdEdit size={18} /> Edit</button>
{/*         
              <button
                className=" blue-btn  px-4 text-white"
                value="submit"
                type="submit"
              >
                Submit
              </button> */}
            </Col>
          </Row>

          <div className="border-container1">
            <h6 className="form-head  m-0 text-white  ">Customer Details</h6>
          </div>

          <div className="border-container">
            <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12} md={6} className="text-start">
                    <lable sm={12} className="rowdown ">
                      Customer Id <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      sm={12}
                      type="text"
                      name="customerid"
                      value={customerDetails.customerid || ""}
                      onChange={handleChange}
                      className="form-input"
                      disabled
                      placeholder="Enter Customer Id"
                      readOnly
                    />
                  </Col>

                  <Col sm={12} md={6} className="text-start">
                    <lable className="rowdown"> Plan Name</lable>
                    <br/>
                    <input
                      type="text"
                      name="planname"
                      value={customerDetails.planname || ""}
                      onChange={handleChange}
                      className="form-input"
                      disabled
                      placeholder="Enter Plan Name"
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Name</lable>
                <br />
                <input
                  type="text"
                  name="customername"
                  value={customerDetails.customername || ""}
                  onChange={handleChange}
                  style={{ textTransform: "capitalize " }}
                  className="form-input inputfield "
                  placeholder="Enter Customer Name"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Mail Id</lable>
                    <br />
                    <input
                      type="email"
                      name="mailid"
                      disabled
                      value={customerDetails.emailid || ""}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Enter Email ID"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Area</lable>
                    <br />
                    <input
                      type="text"
                      name="area"
                      disabled
                      value={customerDetails.area || ""}
                      onChange={handleChange}
                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      placeholder="Enter Area"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>
                      Contact Number{" "}
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      onBlur={handlePhoneNumberBlur}
                      name="contactnum"
                      value={customerDetails.contactnum || ""}
                      onChange={handleChange}
                      required
                      disabled
                      placeholder="Enter Phone Number"
                      className="form-input"
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Alternate Number </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      onBlur={handlePhoneNumberBlur1}
                      name="alternatenum"
                      value={customerDetails.alternatenum || ""}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Enter Phone Number"
                      disabled
                    />
                    {error1 && <p style={{ color: "red" }}>{error1}</p>}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="text-start">
                <lable>Installation Address</lable>
                <br />
                <textarea
                  type="text"
                  name="installationadd"
                  value={customerDetails.installationadd || ""}
                  onChange={handleChange}
                  style={{ textTransform: "capitalize", resize: "none" }}
                  className=" box inputfield"
                  placeholder="Enter Installtion Address"
                  disabled
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Account Manager</lable>
                    <br />
                    <input
                      name="accountmanager"
                      value={customerDetails.accountmanager || ""}
                      onChange={handleChange}
                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      disabled
                      type="text"
                      placeholder="Enter Account Manager"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Activation Date</lable>
                    <br />
                    <input
                      name="activationdate"
                      value={customerDetails.activationdate || ""}
                      onChange={handleChange}
                      className="field form-input"
                      type="date"
                      placeholder="Enter"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Expiry Date</lable>
                    <br />

                    <input
                      className="field form-input"
                      name="expirydate"
                      value={customerDetails.expirydate || ""}
                      onChange={handleChange}
                      disabled
                      type="date"
                      placeholder="Enter"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Last Renewal Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      name="lastrenewaldate"
                      value={customerDetails.lastrenewaldate || ""}
                      onChange={handleChange}
                      type="date"
                      disabled
                      placeholder="Enter"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="text-start">
                <lable>Billing Address</lable>
                <br />
                <textarea
                  style={{ textTransform: "capitalize", resize: "none" }}
                  name="billingadd"
                  value={customerDetails.billingadd || ""}
                  onChange={handleChange}
                  type="text"
                  className="box inputfield"
                  placeholder=" Billing Address"
                  disabled
                  
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Last Logoff Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      name="lastlogoffdate"
                      value={customerDetails.lastlogoffdate || ""}
                      onChange={handleChange}
                      type="date"
                      placeholder="Enter"
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Installation Charges</lable>
                    <br />
                    <input
                      name="installationcharge"
                      onBlur={handleintallationcharge}
                      value={customerDetails.installationcharge || ""}
                      onChange={handleChange}
                      className="form-input"
                      disabled
                      type="tel"
                    //   onKeyDown={(e) => {
                    //     if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                    //      e.preventDefault();
                    //    }
                    //  }}
                      placeholder="Enter Installation Charges"
                      
                    />
                    {errorinstalltioncharge && (
                      <p style={{ color: "red" }}>{errorinstalltioncharge}</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Refund Deposit</lable>
                    <br />
                    <input
                      name="refunddeposit"
                      disabled
                      onBlur={handlerefunddeposit}
                      value={customerDetails.refunddeposit || ""}
                      onChange={handleChange}
                      type="tel"
                    //   onKeyDown={(e) => {
                    //     if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                    //      e.preventDefault();
                    //    }
                    //  }}
                      className="form-input"
                      placeholder="Enter Refund Deposit"
                    />
                    {errorrefund && (
                      <p style={{ color: "red" }}>{errorrefund}</p>
                    )}
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Refund Description</lable>
                    <br />
                    <input
                      style={{ textTransform: "capitalize" }}
                      name="refunddes"
                      value={customerDetails.refunddes || ""}
                      onChange={handleChange}
                      disabled
                      className="form-input"
                      type="text"
                      placeholder="Enter Description"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Account Status</lable>
                    <br />

                    <select
                      name="accountstatus"
                      value={customerDetails.accountstatus || ""}
                      onChange={handleChange}
                      disabled
                      class=" dropbtn form-input-select "
                    >
                      <option value="">Select</option>
                      <option className="activeback " value="Active">
                        Active
                      </option>
                      <option className="inactiveback" value="Inactive">
                        Inactive
                      </option>
                      <option className="suspendedback" value="Suspended">
                        Suspended
                      </option>

                      <option className="disableback" value="Disable">
                        Disable
                      </option>
                    </select>
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Device Status </lable>
                    <br />

                    <select
                      name="devicestatus"
                      value={customerDetails.devicestatus || ""}
                      onChange={handleChange}
                      class="dropbtn form-input-select"
                      disabled
                    >
                      <option value="">Select</option>
                      <option
                        className="onlineback"
                        style={{ background: "#fee792" }}
                        value="Online"
                      >
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}