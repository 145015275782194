import React, { useState } from 'react';
import AdminPanel from "../Adminstration/AdminPanel"

const  AdministrationSection = (props) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }


    return (

        <section className="service-section" id="Services">
            
                <div className="row">
                 <AdminPanel/>
                
                  
                </div>
            
        </section>
    );
}

export default AdministrationSection;