import React from 'react'
import { Container } from 'react-bootstrap'
import './Add_Complaint.css'


export default function Feedback_Complaint() {
  return (
    <div>

        <Container>
            <form>
            <div className='plan-details-container-border-complaint mt-5 text-center ms-5'>
  <p  style={{ fontSize:"13px"}}className='mt-3'><span className='text-primary'>Network</span> Complaint - <span className='text-primary'>Fiber Cut,</span> Customer Affected - <span className='text-primary'>Saravanan @ </span>Location - <span className='text-primary'>Ryy2345wwer,</span></p>                         
   <p style={{ fontSize:"13px"}}> Complaint Open Date - <span className='text-primary'>12/12/2024 , </span> Complaint Open Last - <span className='text-primary'>12 Days</span></p>                         
   </div>

   <div className='mt-5 mb-4 feedback-installation-container '>
    <label><strong>Feedback</strong></label>
    <div className=' mb-5 mt-3 feedback-installation  '>
        <p className='ms-5 me-5 mt-4' >Our service are very nice and company staffs are vry humble. Especially fiber team is very good</p>

        <p className='text-end mb-0 me-3 feedback-collectedby'>collected By <span className='text-primary'>RaviKumar  At <span className='text-primary'>12/12/2024  12.20 PM</span></span></p>

    </div>

   </div>
            </form>
        </Container>
    </div>
  )
}
