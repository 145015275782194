import React from "react";
import Topbar from "../Topbar/Topbar";
import { Container, Row, Col } from "react-bootstrap";
import "./Task.css";
import { useNavigate } from "react-router-dom";
import { HiPencil } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { Card, Badge, Image } from "react-bootstrap";
import "./TaskCard.css";


export default function Task() {
  const gotomyTask = () => {
    navigate("/mytask");
  };

  const gotomyTask1 = () => {
    navigate("/task");
  };

  const [statusCounts, setStatusCounts] = useState({
    open: 0,
    progreass: 0,
    cancelled: 0,
    completed: 0,
  });

  const [show, setShow] = useState(false);
  const [customerdelete, setCustomerdelete] = useState(false);
  const [data, setData] = useState([]);

  const handleShow = () => setShow((prev) => !prev);
  const handleClose = () => setShow(false);
  const [openPopup, setOpenPopup] = useState(null);

  const navigate = useNavigate();

  const gotoCreateTask = (e) => {
    e.preventDefault();
    navigate("/createtask");
  };

  const gotoMapView = (e) => {
    e.preventDefault();
    navigate("/taskmapview");
  };

  const gotoListView = (e) => {
    e.preventDefault();
    navigate("/tasklistview");
  };

  const [setting, setSetting] = useState(false);
  const settingPopupDots = (e) => {
    e.preventDefault();
    setSetting(!setting);
  };
  
  const [settingPopupDotsClose, setSettingPopupDotsClose]=useState(false);

   const togglePopup = (taskId) => {
 
    setOpenPopup(openPopup === taskId ? null : taskId);
  };

  const taskData = [
    {
      id: 1,
      type: "Enquiry",
      typeClass: "purplebox",
      status: "Progress",
      statusClass: "progressbox",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 2,
      type: "Recovery",
      typeClass: "orangebox",
      status: "Open",
      statusClass: "openbox",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 3,
      type: "Installation",
      typeClass: "greenbox",
      status: "Open",
      statusClass: "openbox",

      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 4,
      type: "Due Collection",
      typeClass: "bluebox",
      status: "Open",
      statusClass: "openbox",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
  ];
  const taskData_2 = [
    {
      id: 1,
      type: "Installation",
      typeClass: "greenbox",
      status: "Progress",
      statusClass: "progressbox",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 2,
      type: "Update PC",
      typeClass: "bluebox",
      status: "Open",
      statusClass: "openbox",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 3,
      type: "Recovery",
      typeClass: "orangebox",
      status: "Open",
      statusClass: "openbox",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 4,
      type: "Complaint",
      typeClass: "brownbox",
      status: "Open",
      statusClass: "openbox",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
  ];
  const taskData_3 = [
    {
      id: 1,
      type: "Enquiry",
      typeClass: "purplebox",
      status: "Complete",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 2,
      type: "Recovery",
      typeClass: "orangebox",
      status: "Complete",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 3,
      type: "Complaint",
      typeClass: "brownbox",
      status: "Complete",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 4,
      type: "Installation",
      typeClass: "greenbox",
      status: "Complete",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
  ];
  const taskData_Upcomming = [
    {
      id: 1,
      type: "Enquiry",
      typeClass: "purplebox",
      status: "Open",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 2,
      type: "Recovery",
      typeClass: "orangebox",
      status: "Open",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 3,
      type: "Installation",
      typeClass: "greenbox",
      status: "Open",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
    {
      id: 4,
      type: "Cash Collection",
      typeClass: "bluebox",
      status: "Open",
      assignedBy: "Saravanan",
      priority: "Low",
      assignedTo: [1, 2, 3, 4],
      approvedBy: [5, 6],
      description:
        "Brainstorming brings team members' diverse experience into play.",
    },
  ];

  const handleDelete = async (customerid) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.post("http://localhost:3001/api/delete", {
          customerid: customerid,
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        setCustomerdelete(true);
      } catch (error) {
        Swal.fire("Error!", "Something went wrong while deleting.", "error");
        console.error("Delete Error:", error);
      }
    }
  };
  return (
    <div className="customer-scroll-stop">
      <div className="container-fluid">
        <Topbar />
      </div>
      <Container fluid>
        <Row className="taskRow1">
          <Col>
            <h3 className="px-2">My Task</h3>
          </Col>
          <Col sm={8} className="taskRight">
            <button className="taskBtn text-white" onClick={gotoListView}>
              List View
            </button>

            <button
              className="taskBtn"
              onClick={handleShow}
              style={{ backgroundColor: "#CEA87A" }}
            >
              Upcoming Task
            </button>

            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
            >
              <Offcanvas.Header closeButton className="mb-0 pb-0">
                <Offcanvas.Title>
                  <Row className="text-start">
                    <Col border className="d-flex flex-direction-column">
                      <div className="status-container">
                        <div className="status-item text-center">
                          <span className="dot orangebox"></span>
                          <span className="status-text">Upcomming</span>
                          <span className="status-count">4</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <div className="underlineTask_SB"></div>
              <Offcanvas.Body className="h-100 mt-0 border">
              {taskData.map((task1, ind1) => (
  <Card
    key={ind1}
    className="task-card-box p-0 mx-auto"
    style={{ backgroundColor: "#D8D8D8", borderRadius: "20px" }}
  >
    <div className="mx-auto task-card-item">
      <Row className="mb-2">
        <Col className="pt-1">
          <Badge
            bg={task1.typeClass}
            className="task-enquiry"
            onClick={gotomyTask}
          >
            {task1.type}
          </Badge>
        </Col>
        <Col className="text-end mb-0 pb-0">
          <Badge
            className="progress-main"
            bg={task1.statusClass}
            onClick={gotomyTask}
          >
            <p className="progress-body">{task1.status}</p>
          </Badge>
        </Col>

        <span
          className="dots mt-2"
          onClick={() => togglePopup(ind1)}
        >
          ...
        </span>
        {openPopup === ind1 && (
          <div
            className="task-popup-container p-0 m-0"
            style={{
              width: "150px",
              position: "absolute",
              right: "10px",
              zIndex: 1000,
              background: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              borderRadius: "10px",
              padding: "8px",
            }}
          >
            <button
              className="task-close-btn"
              onClick={() => togglePopup(ind1)}
              style={{
                border: "none",
                background: "transparent",
                float: "right",
                fontSize: "20px",
              }}
            >
              &times;
            </button>
            <div className="task-popup-content w-100 p-2">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-auto">
                  <HiPencil size={20} />
                  <br />
                  <MdDelete size={20} className="text-danger" />
                </div>
                <div className="col text-start">
                  <div>
                    Edit
                    <br />
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Row>

      <Card.Text className="task-description">
        {task1.description}
      </Card.Text>

      <div>
        <div className="d-flex justify-content-between align-items-center mb-5">
          <p className="assignedBy">
            <b>Assigned By:</b>{" "}
            <span className="fw-bold text-black">
              {task1.assignedBy}
            </span>
          </p>
          <Badge bg="primary assignedBy">{task1.priority}</Badge>
        </div>

        <div>
          <p className="assignedBy">
            <b>Assigned To:</b>
            <div className="avatars mt-0">
              {task1.assignedTo.map((img, i) => (
                <Image
                  key={i}
                  src={`https://i.pravatar.cc/40?img=${img}`}
                  roundedCircle
                  className="avatar-img"
                />
              ))}
            </div>
          </p>

          <p className="assignedBy text-end approvedBy pe-0">
            <b>Approve Person:</b>
            <div className="avatars1 text-end ps-2">
              {task1.approvedBy.map((num, j) => (
                <Image
                  key={j}
                  src={`https://i.pravatar.cc/40?img=${num}`}
                  roundedCircle
                  className="avatar-img1"
                />
              ))}
            </div>
          </p>
        </div>
      </div>
    </div>
  </Card>
))}
              </Offcanvas.Body>
            </Offcanvas>

            <button
              className="taskBtn text-white"
              onClick={gotoCreateTask}
              style={{ backgroundColor: "#105cad" }}
            >
              &#x002B; Create Task
            </button>

            <button className="mapImage" onClick={gotoMapView}></button>
          </Col>
        </Row>
      </Container>
      {/* <Container> */}
      <Row className="taskRow2 pe-5 ps-4">
        <Col className="taskColumn">
          <Row className="status-container-row">
            <Col border className="border-3">
              <div className="status-container">
                <div className="status-item">
                  <span className="dot red"></span>
                  <span className="status-text">Over Due</span>
                  <span className="status-count">{taskData.length}</span>
                </div>
              </div>
            </Col>
            <Col>
              <div className="status-container1">
                <div className="status-item">
                  <span className="dot purplebox"></span>
                  <span className="status-text">Progress</span>
                  <span className="status-count">{taskData.length}</span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="underlineTask"></div>

          {taskData.map((task1, ind1) => (
            <Card
              key={ind1}
              className="task-card-box p-0 mx-auto"
              style={{ backgroundColor: "#D8D8D8", borderRadius: "20px" }}
            >
              <div className="mx-auto task-card-item">
                <Row className="mb-2">
                  <Col className="pt-1">
                    <Badge
                      bg={task1.typeClass}
                      className="task-enquiry"
                      onClick={gotomyTask}
                    >
                      {task1.type}
                    </Badge>
                  </Col>
                  <Col className="text-end mb-0 pb-0">
                    <Badge
                      className="progress-main"
                      bg={task1.statusClass}
                      onClick={gotomyTask}
                    >
                      <p className="progress-body">{task1.status}</p>
                    </Badge>
                  </Col>

                  {/* <span className="dots mt-2" onClick={settingPopupDots}> */}
                  <span
                    className="dots mt-2"
                    onClick={() => togglePopup(ind1)}
                   >
                     
                    ...
                  </span>
                  {openPopup === ind1 && (
                    <div
                      className="task-popup-container p-0 m-0"
                      style={{
                        width: "150px",
                        position: "absolute",
                        right: "10px",
                        zIndex: 1000,
                        background: "white",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <button
                        className="task-close-btn"
                        onClick={() => togglePopup(taskData.id)}
                        style={{
                          border: "none",
                          background: "transparent",
                          float: "right",
                          fontSize: "20px",
                        }}
                      >
                        &times;
                      </button>
                      <div className="task-popup-content w-100 p-2">
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col-auto">
                            <HiPencil size={20} />
                            <br />
                            <MdDelete size={20} className="text-danger" />
                          </div>
                          <div className="col text-start">
                            <div>
                              Edit
                              <br />
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {setting && (
                    <div className="task-popup-container p-0 m-0">
                      <button
                        className="task-close-btn"
                        onClick={settingPopupDots}
                      >
                        &times;
                      </button>
                      <div className="task-popup-content p-1 ms-2">
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col pt-0">
                            <HiPencil /> <br />
                            <MdDelete />
                          </div>
                          <div className="col pt-1 text-start w-50">
                            Edit <br /> Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>

                <Card.Text className="task-description">
                  {task1.description}
                </Card.Text>

                <div>
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <p className="assignedBy">
                      <b>Assigned By:</b>{" "}
                      <span className="fw-bold text-black">
                        {task1.assignedBy}
                      </span>
                    </p>
                    <Badge bg="primary assignedBy">{task1.priority}</Badge>
                  </div>

                  <div>
                    <p className="assignedBy">
                      <b>Assigned To:</b>
                      <div className="avatars mt-0">
                        {task1.assignedTo.map((img, i) => (
                          <Image
                            key={i}
                            src={`https://i.pravatar.cc/40?img=${img}`}
                            roundedCircle
                            className="avatar-img"
                          />
                        ))}
                      </div>
                    </p>

                    <p className="assignedBy text-end approvedBy pe-0">
                      <b>Approve Person:</b>
                      <div className="avatars1 text-end ps-2">
                        {task1.approvedBy.map((num, j) => (
                          <Image
                            key={j}
                            src={`https://i.pravatar.cc/40?img=${num}`}
                            roundedCircle
                            className="avatar-img1"
                          />
                        ))}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </Col>
        <Col className="taskColumn">
          <Row className="status-container-row">
            <Col border className="border-3">
              <div className="status-container">
                <div className="status-item">
                  <span className="dot orangebox"></span>
                  <span className="status-text">Open</span>
                  <span className="status-count">{taskData.length}</span>
                </div>
              </div>
            </Col>
            <Col>
              <div className="status-container1">
                <div className="status-item">
                  <span className="dot purplebox"></span>
                  <span className="status-text">Progress</span>
                  <span className="status-count">{taskData.length}</span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="underlineTask_2"></div>

          {taskData_2.map((task, index) => (
            <Card
              key={index}
              className="task-card-box p-0 mx-auto"
              style={{ backgroundColor: "#D8D8D8", borderRadius: "20px" }}
            >
              <div className="mx-auto task-card-item">
                <Row className="mb-2">
                  <Col className="pt-1">
                    <Badge
                      bg={task.typeClass}
                      className="task-recovery"
                      onClick={gotomyTask}
                    >
                      {task.type}
                    </Badge>
                  </Col>
                  <Col className="text-end mb-0 pb-0">
                    <Badge
                      className="progress-main"
                      bg="progressbox"
                      onClick={gotomyTask}
                    >
                      <p className="progress-body">{task.status}</p>
                    </Badge>
                  </Col>

                  <span className="dots mt-2" onClick={()=>{togglePopup(index)}}>
                    ...
                  </span>

                  {openPopup === index && (
                    <div className="task-popup-container p-0 m-0">
                      <button
                        className="task-close-btn"
                        onClick={settingPopupDots}
                      >
                        &times;
                      </button>
                      <div className="task-popup-content w-100 p-1 ms-2">
                        <div className="row d-flex justify-content-center align-items-center w-75">
                          <div className="col pt-1">
                            <HiPencil /> <br />
                            <MdDelete />
                          </div>
                          <div className="col pt-1 text-start">
                            Edit <br /> Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>

                <Card.Text className="task-description">
                  {task.description}
                </Card.Text>

                <div>
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <p className="assignedBy">
                      <b>Assigned By:</b>{" "}
                      <span className="fw-bold text-black">
                        {task.assignedBy}
                      </span>
                    </p>
                    <Badge bg="primary assignedBy">{task.priority}</Badge>
                  </div>

                  <div>
                    <p className="assignedBy">
                      <b>Assigned To:</b>
                      <div className="avatars mt-0">
                        {task.assignedTo.map((img, i) => (
                          <Image
                            key={i}
                            src={`https://i.pravatar.cc/40?img=${img}`}
                            roundedCircle
                            className="avatar-img"
                          />
                        ))}
                      </div>
                    </p>

                    <p className="assignedBy text-end approvedBy pe-0">
                      <b>Approve Person:</b>
                      <div className="avatars1 text-end ps-2">
                        {task.approvedBy.map((num, j) => (
                          <Image
                            key={j}
                            src={`https://i.pravatar.cc/40?img=${num}`}
                            roundedCircle
                            className="avatar-img1"
                          />
                        ))}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </Col>
        <Col className="taskColumn">
          <Row className="status-container-row">
            <Col border className="border-3">
              <div className="status-container">
                <div className="status-item">
                  <span className="dot greenbox"></span>
                  <span className="status-text">Completed</span>
                  <span className="status-count">{taskData.length}</span>
                </div>
              </div>
            </Col>
            <Col>
              <div className="status-container1">
                <div className="status-item">
                  {/* <span className="dot purplebox"></span>  */}
                  <span className="status-text">Approve Pending</span>
                  <span className="status-count">{taskData.length}</span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="underlineTask_3"></div>
          {taskData_3.map((task, index) => (
            <Card
              key={index}
              className="task-card-box p-0 mx-auto"
              style={{ backgroundColor: "#D8D8D8", borderRadius: "20px" }}
            >
              <div className="mx-auto task-card-item">
                <Row className="mb-2">
                  <Col className="pt-1">
                    <Badge
                      bg={task.typeClass}
                      className="task-enquiry"
                      onClick={gotomyTask}
                    >
                      {task.type}
                    </Badge>
                  </Col>
                  <Col className="text-end mb-0 pb-0">
                    <Badge
                      className="progress-main"
                      bg="progressbox"
                      onClick={gotomyTask}
                    >
                      <p className="progress-body">{task.status}</p>
                    </Badge>
                  </Col>

                  <span className="dots mt-2" onClick={()=>{togglePopup(index)}}>
                    ...
                  </span>

                  {openPopup === index && (
                    <div className="task-popup-container p-0 m-0">
                      <button
                        className="task-close-btn"
                        onClick={settingPopupDots}
                      >
                        &times;
                      </button>
                      <div className="task-popup-content w-100 p-1 ms-2">
                        <div className="row d-flex justify-content-center align-items-center w-75">
                          <div className="col pt-1">
                            <HiPencil /> <br />
                            <MdDelete />
                          </div>
                          <div className="col pt-1 text-start">
                            Edit <br /> Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>

                <Card.Text className="task-description">
                  {task.description}
                </Card.Text>

                <div>
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <p className="assignedBy">
                      <b>Assigned By:</b>{" "}
                      <span className="fw-bold text-black">
                        {task.assignedBy}
                      </span>
                    </p>
                    <Badge bg="primary assignedBy">{task.priority}</Badge>
                  </div>

                  <div>
                    <p className="assignedBy">
                      <b>Assigned To:</b>
                      <div className="avatars mt-0">
                        {task.assignedTo.map((img, i) => (
                          <Image
                            key={i}
                            src={`https://i.pravatar.cc/40?img=${img}`}
                            roundedCircle
                            className="avatar-img"
                          />
                        ))}
                      </div>
                    </p>

                    <p className="assignedBy text-end approvedBy pe-0">
                      <b>Approve Person:</b>
                      <div className="avatars1 text-end ps-2">
                        {task.approvedBy.map((num, j) => (
                          <Image
                            key={j}
                            src={`https://i.pravatar.cc/40?img=${num}`}
                            roundedCircle
                            className="avatar-img1"
                          />
                        ))}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </Col>
      </Row>

      {/* </Container> */}
    </div>
  );
}
