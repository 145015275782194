import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import ImageViewer from "react-simple-image-viewer";
import Topbar from "../Topbar/Topbar";
import "./StaffAnalysis.css";
import { Doughnut, Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Col, Row } from "react-bootstrap";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  colors,
} from "@mui/material";
ChartJS.register(ArcElement, Tooltip, Legend);

const dataLine = {
  labels: [
    "APR 01",
    "APR 05",
    "APR 10",
    "APR 15",
    "APR 20",
    "APR 25",
    "APR 30",
  ],
  datasets: [
    {
      label: "Open",
      data: [35, 20, 28, 38, 25, 30, 34],
      borderColor: "green",
      backgroundColor: "rgba(0, 255, 0, 0.2)",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      borderWidth: 3,
    },
    {
      label: "Solved",
      data: [25, 15, 18, 30, 22, 26, 30],
      borderColor: "blue",
      backgroundColor: "rgba(0, 0, 255, 0.2)",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      borderWidth: 3,
    },
  ],
};

const optionsLine = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  tension: 0.4,
  scales: {
    x: { grid: { display: false } },
    y: {
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
  },
};
//graph2
const dataLine1 = {
  labels: [
    "APR 01",
    "APR 05",
    "APR 10",
    "APR 15",
    "APR 20",
    "APR 25",
    "APR 30",
  ],
  datasets: [
    {
      label: "Open",
      data: [35, 20, 28, 38, 25, 30, 34],
      borderColor: "green",
      backgroundColor: "rgba(0, 255, 0, 0.2)",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      borderWidth: 3,
    },
    {
      label: "Solved",
      data: [25, 15, 18, 30, 22, 26, 30],
      borderColor: "blue",
      backgroundColor: "rgba(0, 0, 255, 0.2)",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      borderWidth: 3,
    },
  ],
};
const optionsLine1 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  tension: 0.4,
  scales: {
    x: { grid: { display: false } },
    y: {
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
  },
};

export default function StaffAnalysis() {
  const totalValue = 277.6; // Total (Work + Extra + Idle + buffer)

  const dataPie = {
    datasets: [
      {
        label: "Work Time",
        data: [251.2, totalValue - 251.2],
        backgroundColor: ["#195C9A", "#E6E6E6"],
        cutout: "70%",
        circumference: 360,
        rotation: 225,
      },
      {
        label: "Extra Time",
        data: [17.15, totalValue - 17.15],
        backgroundColor: ["#66E6FF", "#E6E6E6"],
        cutout: "55%",
        circumference: 360,
        rotation: 225,
      },
      {
        label: "Idle Time",
        data: [100.25, totalValue - 100.25],
        backgroundColor: ["#B87333", "#E6E6E6"],
        cutout: "40%",
        circumference: 360,
        rotation: 225,
      },
    ],
  };

  const optionsPie = {
    responsive: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  };
  //select option
  const optionsSelect = ["Saravanan", "Ravikumar", "Sathishkumar", "Venkatesh"];
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSelectedValues(
        selectedValues.length === optionsSelect.length ? [] : optionsSelect
      );
    } else {
      setSelectedValues(value);
    }
  };

  // Image --------------------------------------------------------------
  // Viewer 1
  const [currentImage1, setCurrentImage1] = useState(0);
  const [isViewerOpen1, setIsViewerOpen1] = useState(false);
  const images1 = [
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvGwIDkavEFjEH0V7QsTYdIxeWCy3V5TI8g&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG2ZyW_FQQSDYh2hNTetrPxPGUXBgamNA7QQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvGwIDkavEFjEH0V7QsTYdIxeWCy3V5TI8g&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG2ZyW_FQQSDYh2hNTetrPxPGUXBgamNA7QQ&s",
  ];

  const openViewer1 = (index) => {
    setCurrentImage1(index);
    setIsViewerOpen1(true);
  };

  const closeViewer1 = () => {
    setCurrentImage1(0);
    setIsViewerOpen1(false);
  };

  const deleteImage1 = () => {
    alert(`Delete image at index ${currentImage1} (Gallery 1)`);
    closeViewer1();
  };

  // Viewer 2
  const [currentImage2, setCurrentImage2] = useState(0);
  const [isViewerOpen2, setIsViewerOpen2] = useState(false);
  const images2 = [
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvGwIDkavEFjEH0V7QsTYdIxeWCy3V5TI8g&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG2ZyW_FQQSDYh2hNTetrPxPGUXBgamNA7QQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvGwIDkavEFjEH0V7QsTYdIxeWCy3V5TI8g&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG2ZyW_FQQSDYh2hNTetrPxPGUXBgamNA7QQ&s",
  ];

  const openViewer2 = (index) => {
    setCurrentImage2(index);
    setIsViewerOpen2(true);
  };

  const closeViewer2 = () => {
    setCurrentImage2(0);
    setIsViewerOpen2(false);
  };

  const deleteImage2 = () => {
    alert(`Delete image at index ${currentImage2} (Gallery 2)`);
    closeViewer2();
  };

  // Viewer 3
  const [currentImage3, setCurrentImage3] = useState(0);
  const [isViewerOpen3, setIsViewerOpen3] = useState(false);
  const images3 = [
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvGwIDkavEFjEH0V7QsTYdIxeWCy3V5TI8g&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG2ZyW_FQQSDYh2hNTetrPxPGUXBgamNA7QQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvGwIDkavEFjEH0V7QsTYdIxeWCy3V5TI8g&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG2ZyW_FQQSDYh2hNTetrPxPGUXBgamNA7QQ&s",
  ];

  const openViewer3 = (index) => {
    setCurrentImage3(index);
    setIsViewerOpen3(true);
  };

  const closeViewer3 = () => {
    setCurrentImage3(0);
    setIsViewerOpen3(false);
  };

  const deleteImage3 = () => {
    alert(`Delete image at index ${currentImage3} (Gallery 3)`);
    closeViewer3();
  };

  return (
    <div>
      <Topbar />
      <div className="container-fluid">
        <Row>
          <h1>Staff Analysis section</h1>
        </Row>
        <Row className="mt-3">
          <Col md={2} sm={12}>
            <FormControl
              sx={{ width: "150px" }}
              className="satffanalysis-selectcheckbox"
            >
              <InputLabel
                id="checkbox-select-label"
                shrink={selectedValues.length > 0}
              ></InputLabel>
              <Select
                labelId="checkbox-select-label"
                id="checkbox-select"
                multiple
                value={selectedValues}
                onChange={handleChange}
                displayEmpty
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  textAlign: "center", // ✅ Align text to center
                  display: "flex",
                  alignItems: "center", // ✅ Vertically center text
                }}
                renderValue={(selected) =>
                  selected.length === 0 ? "Select Staff" : selected.join(", ")
                }
              >
                <MenuItem value="all">
                  <ListItemText primary="All" />
                  <Checkbox
                    checked={selectedValues.length === optionsSelect.length}
                  />
                </MenuItem>
                {optionsSelect.map((optionSelect) => (
                  <MenuItem key={optionSelect} value={optionSelect}>
                    <ListItemText primary={optionSelect} />
                    <Checkbox checked={selectedValues.includes(optionSelect)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={2} sm={12}>
            <select name="cars" id="cars" className="staffanalysis-select">
              <option value="Fiber Parameter">Fiber Parameters</option>
              <option value="Network Parameter">Network Parameter</option>
              <option value="Marketting Parameter">Marketting Parameter</option>
              <option value="Task Parameter">Task Parameter</option>
            </select>
          </Col>
          <Col md={2} sm={12}>
            <div style={{ marginLeft: "10%" }}>
              <FormControl
                sx={{ width: "150px" }}
                className="satffanalysis-selectcheckbox"
              >
                <InputLabel
                  id="checkbox-select-label"
                  shrink={selectedValues.length > 0}
                ></InputLabel>
                <Select
                  labelId="checkbox-select-label"
                  id="checkbox-select"
                  multiple
                  value={selectedValues}
                  onChange={handleChange}
                  displayEmpty
                  sx={{
                    height: "40px",
                    fontSize: "14px",
                    textAlign: "center", // ✅ Align text to center
                    display: "flex",
                    alignItems: "center", // ✅ Vertically center text
                  }}
                  renderValue={(selected) =>
                    selected.length === 0 ? "Select Staff" : selected.join(", ")
                  }
                >
                  <MenuItem value="all">
                    <ListItemText primary="All" />
                    <Checkbox
                      checked={selectedValues.length === optionsSelect.length}
                    />
                  </MenuItem>
                  {optionsSelect.map((optionSelect) => (
                    <MenuItem key={optionSelect} value={optionSelect}>
                      <ListItemText primary={optionSelect} />
                      <Checkbox
                        checked={selectedValues.includes(optionSelect)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Col>
          <Col md={2} sm={12}>
            <input type="date" className="ms-3" />
          </Col>
          <Col md={4} sm={12}>
            <div className="staff-generate">
              <button className="staff-generate-btn">Generate</button>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="sa-box mt-5">
            <Row className="">
              <Col md={6} sm={12}>
                <h4>Saravanan</h4>
              </Col>
              <Col md={6} sm={12}>
                <button className="black-btn" style={{ float: "right" }}>
                  Export
                </button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={7} sm={12}>
                <div className="sa-linechart-box">
                  <Row>
                    <Col md={5}>
                      <h4>Complaints Report</h4>
                    </Col>
                    <Col md={7}>
                      {" "}
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                          marginTop: 10,
                        }}
                      >
                        <span style={{ color: "green", fontWeight: "bold" }}>
                          Open: {56}
                        </span>{" "}
                        &nbsp;&nbsp;
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Solved: {56}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Line data={dataLine} options={optionsLine} />
                </div>
              </Col>
              <Col md={5} sm={12}>
                <div className="sa-piechart-box">
                  <Row>
                    <Col md={7} sm={12}>
                      <h4>Time Contributions</h4>
                    </Col>
                    <Col md={5} sm={12}>
                      <p>
                        WT Avg/D : <b>07 h</b>
                      </p>
                      <p>
                        ET Avg/D : <b>01:20 h</b>
                      </p>
                      <p>
                        IT Avg/D : <b>01:20 h</b>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={9} sm={12}>
                      <Doughnut
                        data={dataPie}
                        options={optionsPie}
                        width={250}
                        height={250}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      <p>
                        <span class="dot sa-darkblue"></span>Work Time
                      </p>
                      <p>
                        <b>251.20 H</b>
                      </p>
                      <p>
                        <span class="dot sa-skyblue"></span>Extra Time
                      </p>
                      <p>
                        <b>17.15 H</b>
                      </p>
                      <p>
                        <span class="dot sa-brown"></span>Idle Time
                      </p>
                      <p>
                        <b>10.25 H</b>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* //graph2------------------------------------------------- */}
            <Row className="mt-4">
              <Col md={5} sm={12}>
                <div className="sa-piechart-box">
                  <Row>
                    <div className="sa-piechart-box">
                      <Row className="d-flex">
                        <Col> KM Traveled</Col>
                        <Col>Avg/D : 12KM</Col>
                      </Row>
                      <Row className="mt-3">
                        <div>
                          {" "}
                          <span class="dot sa-purple"></span>Total KM Traveled :
                          <strong> 250KM</strong>{" "}
                        </div>
                        <div>
                          {" "}
                          <span class="dot sa-pink"></span>Own Bike :
                          <strong> 20KM</strong>
                        </div>
                      </Row>
                    </div>
                  </Row>
                  <Row>
                    <div className="sa-piechart-box mt-3">
                      <Row className="d-flex">
                        <Col>Cable Used</Col>
                        <Col>Avg/D : 12KM</Col>
                      </Row>
                      <Row className="mt-3">
                        <div>
                          {" "}
                          <span class="dot sa-purple"></span>Combined Cable Used
                          :<strong> 250KM</strong>{" "}
                        </div>
                        <div>
                          {" "}
                          <span class="dot sa-pink"></span>Individual Cable Used
                          :<strong> 20KM</strong>
                        </div>
                      </Row>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col md={7} sm={12}>
                <div className="sa-linechart-box">
                  <h4>Complaints Report</h4>
                  <Line data={dataLine1} options={optionsLine1} />
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      marginTop: 10,
                    }}
                  >
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      Open: {56}
                    </span>{" "}
                    &nbsp;&nbsp;
                    <span style={{ color: "blue", fontWeight: "bold" }}>
                      Solved: {56}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
        {/* image box 1------------------------------------------------------------ */}
        <Row className="mt-4">
          <div className="sa-piechart-box">
            <Row>
              {" "}
              <h4>
                Reviews <sapn style={{ color: "gray" }}>(12)</sapn>
              </h4>
            </Row>
            <Row>
              <div>
                {images1.map((src, index) => (
                  <img
                    src={src}
                    onClick={() => openViewer1(index)}
                    width="300"
                    key={index}
                    style={{ margin: "10px", cursor: "pointer" }}
                    alt=""
                  />
                ))}

                {isViewerOpen1 && (
                  <div
                    style={{
                      position: "fixed",
                      zIndex: 9999,
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <ImageViewer
                      src={images1}
                      currentIndex={currentImage1}
                      disableScroll={false}
                      closeOnClickOutside={true}
                      onClose={closeViewer1}
                      backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    />

                    {/* Overlay content */}
                    <div
                      style={{
                        position: "fixed",
                        top: "20px",
                        right: "30px",
                        color: "white",
                        zIndex: 10000,
                      }}
                    >
                      <button
                        onClick={deleteImage1}
                        style={{
                          marginRight:'50px',
                          padding: "10px 20px",
                          fontSize: "16px",
                          backgroundColor: "#e74c3c",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </button>
                    </div>

                    <div
                      style={{
                        position: "fixed",
                        bottom: "30px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "white",
                        fontSize: "18px",
                        zIndex: 10000,
                      }}
                    >
                      Image {currentImage1 + 1} of {images1.length}
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </Row>
        {/* image box2 -------------------------------------------------------------*/}
        <Row className="mt-4">
          <div className="sa-piechart-box">
            <Row>
              {" "}
              <h4>
                Speed Test Photos <sapn style={{ color: "gray" }}>(12)</sapn>
              </h4>
            </Row>
            <Row>
            <div>
                {images2.map((src, index) => (
                  <img
                    src={src}
                    onClick={() => openViewer2(index)}
                    width="300"
                    key={index}
                    style={{ margin: "10px", cursor: "pointer" }}
                    alt=""
                  />
                ))}

                {isViewerOpen2 && (
                  <div
                    style={{
                      position: "fixed",
                      zIndex: 9999,
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <ImageViewer
                      src={images2}
                      currentIndex={currentImage2}
                      disableScroll={false}
                      closeOnClickOutside={true}
                      onClose={closeViewer2}
                      backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    />

                    {/* Overlay content */}
                    <div
                      style={{
                        position: "fixed",
                        top: "20px",
                        right: "30px",
                        color: "white",
                        zIndex: 10000,
                      }}
                    >
                      <button
                        onClick={deleteImage2}
                        style={{
                          marginRight:'50px',
                          padding: "10px 20px",
                          fontSize: "16px",
                          backgroundColor: "#e74c3c",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </button>
                    </div>

                    <div
                      style={{
                        position: "fixed",
                        bottom: "30px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "white",
                        fontSize: "18px",
                        zIndex: 10000,
                      }}
                    >
                      Image {currentImage2 + 1} of {images2.length}
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </Row>
        {/* image box 3 -------------------------------------------------------------*/}
        <Row className="mt-4">
          <div className="sa-piechart-box">
            <Row>
              {" "}
              <h4>
                Router Sticker Photos{" "}
                <sapn style={{ color: "gray" }}>(12)</sapn>
              </h4>
            </Row>
            <Row>
            <div>
                {images3.map((src, index) => (
                  <img
                    src={src}
                    onClick={() => openViewer3(index)}
                    width="300"
                    key={index}
                    style={{ margin: "10px", cursor: "pointer" }}
                    alt=""
                  />
                ))}

                {isViewerOpen3 && (
                  <div
                    style={{
                      position: "fixed",
                      zIndex: 9999,
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <ImageViewer
                      src={images3}
                      currentIndex={currentImage3}
                      disableScroll={false}
                      closeOnClickOutside={true}
                      onClose={closeViewer3}
                      backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    />

                    {/* Overlay content */}
                    <div
                      style={{
                        position: "fixed",
                        top: "20px",
                        right: "30px",
                        color: "white",
                        zIndex: 10000,
                      }}
                    >
                      <button
                        onClick={deleteImage3}
                        style={{
                          marginRight:'50px',
                          padding: "10px 20px",
                          fontSize: "16px",
                          backgroundColor: "#e74c3c",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </button>
                    </div>

                    <div
                      style={{
                        position: "fixed",
                        bottom: "30px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "white",
                        fontSize: "18px",
                        zIndex: 10000,
                      }}
                    >
                      Image {currentImage3 + 1} of {images3.length}
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </Row>
      </div>
    </div>
  );
}
