import React from 'react'
import Topbar from '../Topbar/Topbar';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './View_Complaint.css'
import { FaUserEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useRef,useEffect } from 'react';

import OrderDetails_Complaint from './OrderDetails_Complaint';
import Verification_Complaint from './Verification_Complaint';
import WorkAssign_Complaint from './WorkAssign_Complaint';
import WorkDetails_Complaint from './WorkDetails_Complaint';
import Feedback_Complaint from './Feedback_Complaint';
import Payment_Complaint from './Payment_Complaint';


export default function View_Complaint() {

    const [selectedNames, setSelectedNames] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const names = [
    'Saravanan',
    'Ravikumar',
    'Sathishkumar',
    'Venkatesh'
  ];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (name) => {
    setSelectedNames(prev => 
      prev.includes(name) 
        ? prev.filter(n => n !== name) 
        : [...prev, name]
    );
  };

  const displayText = selectedNames.length > 0 
    ? selectedNames.join(', ') 
    : 'Select names';


    const [activeTab, setActiveTab] = useState("orderDetails");
    const [isOpenAssign, setIsOpenAssign] = useState(false);
    const [installTaskName, setinstallTaskName] = useState("");
    const [installAssigDataTime, setinstallAssignDateTime] = useState("");
    const [installTaskType, setinstallTaskType] = useState("");
    const [installTaskPriority, setinstallTaskPriority] = useState("");
    const [installDeadline, setinstallDealine] = useState("");
    const [installTime, setinstallTime] = useState("");
    const [installTaskAssignTo, setinstallTaskAssignTo] = useState("");
    const [installApprovePerson, setinstallApprovePerson] = useState("");
    const [installDescription, setinstallDescription] = useState("");
    const [installLeadID, setinstallLeadId] = useState("");
    const [installName, setinstallName] = useState("");
    const [installTaskStatus, setinstallTaskStatus] = useState("");







    const renderContent = () => {
        switch (activeTab) {
            case "orderDetails":
                return <div>

                <OrderDetails_Complaint/>

                </div>;
            case "verification":
                return <div>
                   <Verification_Complaint/>
                </div>;
            case "workAssign":
                return <div>
                    <WorkAssign_Complaint/>
                </div>;
            case "workDetails":
                return <div><WorkDetails_Complaint/></div>;
            case "feedback":
                return <div><Feedback_Complaint/></div>;
            case "payments":
                return <div><Payment_Complaint/></div>;
            default:
                return <div>Select a tab</div>;
        }
    };
    return (
        <div>

            <Topbar />
            <Container fluid>
                <Row className='mt-4'>
                    <Col className='text-start'>
                        <h3>Complaint</h3>
                    </Col>
                    <Col className='text-end'>
                        <button type="button" className='assign-btn mx-3 ' onClick={() => setIsOpenAssign(true)}><FaUserEdit size={18} /> Assign</button>

                        {isOpenAssign && (
                            <div className="overlay3">
                                <div className="modal3">
                                    <Container>

                                    <div className=" text-end mx-2">
                                            <button type="button" className="black-btn mx-3" onClick={() => setIsOpenAssign(false)}>
                                                Cancel
                                            </button>
                                            <button type="button" className=" blue-btn mx-3" onClick={() => setIsOpenAssign(false)}>
                                                Submit
                                            </button>

                                        </div>

                                        <Row className='mt-4'>
                                            <Col xs={12} md={6}>
                                                <Row>
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Task Name</lable>
                                                        <br />
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setinstallTaskName(e.target.value)}
                                                            className="form-input "
                                                            placeholder="Enter Plan Speed"
                                                            readOnly

                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Assign Date/Time</lable>
                                                        <br />
                                                        <input
                                                            type="tel"
                                                            onChange={(e) => setinstallAssignDateTime(e.target.value)}
                                                            className="form-input "
                                                            placeholder="Enter Plan Month"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>
                                                            Task Type

                                                        </lable>

                                                        <br />
                                                        <select
                                                            onChange={(e) => setinstallTaskPriority(e.target.value)}
                                                            class="dropbtn form-input-select overlay2"
                                                        >
                                                            <option value="">Select</option>
                                                            <option
                                                                className="onlineback"

                                                                value="Online"
                                                            >
                                                                Online
                                                            </option>
                                                            <option className="offlineback" value="Offline">
                                                                Offline
                                                            </option>
                                                        </select>
                                                       

                                                    </Col>

                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Task Priority  </lable>
                                                        <br />
                                                        <select
                                                            onChange={(e) => setinstallTaskPriority(e.target.value)}
                                                            class="dropbtn form-input-select overlay2"
                                                        >
                                                            <option value="">Select</option>
                                                            <option
                                                                className="onlineback"

                                                                value="Online"
                                                            >
                                                                Online
                                                            </option>
                                                            <option className="offlineback" value="Offline">
                                                                Offline
                                                            </option>
                                                        </select>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Row>
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Deadline</lable>
                                                        <br />
                                                        <input
                                                            type="date"
                                                            onChange={(e) => setinstallDealine(e.target.value)}
                                                            className="form-input overlay2"
                                                            placeholder=""
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Time</lable>
                                                        <br />
                                                        <input
                                                            type="time"
                                                            onChange={(e) => setinstallTime(e.target.value)}
                                                            className="form-input overlay2"
                                                            placeholder=""
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>
                                                            Task Asign To

                                                        </lable>
                                                        <br />
                                                        {/* <select
                                                            onChange={(e) => setinstallTaskAssignTo(e.target.value)}
                                                            class="dropbtn form-input-select overlay2"
                                                        >
                                                            <option value="">Select</option>
                                                            <option
                                                                className="onlineback"

                                                                value="Online"
                                                            >
                                                                Online
                                                            </option>
                                                            <option className="offlineback" value="Offline">
                                                                Offline
                                                            </option>
                                                        </select> */}

<div ref={dropdownRef} style={{ position: 'relative', width: '85%' }}>
      <div
        onClick={toggleDropdown}
        style={{
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          cursor: 'pointer',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <span style={{ 
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
          {displayText}
        </span>
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>

      {isOpen && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          marginTop: '5px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: '#fff',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          zIndex: 1000,
          maxHeight: '200px',
          overflowY: 'auto'
        }}>
          {names.map((name) => (
            <label
              key={name}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid #eee',
                // backgroundColor: selectedNames.includes(name) ? '#f0f8ff' : '#fff'
              }}
            //   onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f5f5f5'}
            //   onMouseLeave={(e) => e.currentTarget.style.backgroundColor = selectedNames.includes(name) ? '#f0f8ff' : '#fff'}
            >
              <input
                type="checkbox"
                checked={selectedNames.includes(name)}
                onChange={() => handleCheckboxChange(name)}
                style={{ marginRight: '10px' }}
              />
              {name}
            </label>
          ))}
        </div>
      )}
    </div>


                                                    </Col>

                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Approve Person  </lable>
                                                        <br />
                                                        <select
                                                            onChange={(e) => setinstallApprovePerson(e.target.value)}
                                                            class="dropbtn form-input-select overlay2"
                                                        >
                                                            <option value="">Select</option>
                                                            <option
                                                                className="onlineback"

                                                                value="Online"
                                                            >
                                                                Online
                                                            </option>
                                                            <option className="offlineback" value="Offline">
                                                                Offline
                                                            </option>
                                                        </select>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>




                                        <Row>
                                            
                                                   
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Others</lable>
                                                        <br />
                                                        <textarea
                                                            type="text"
                                                            onChange={(e) => setinstallDescription(e.target.value)}
                                                            style={{
                                                                textTransform: "capitalize",
                                                                resize: "none",
                                                                
                                                              }}
                                                            className="form-input inputfield-assign "
                                                            placeholder="Enter Notes "
                                                            
                                                        />
                                                  
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>
                                                            Lead ID

                                                        </lable>
                                                        <br />
                                                        <input
                                                            type="text"

                                                            onChange={(e) => setinstallLeadId(e.target.value)}
                                                            placeholder=""
                                                            className="form-input overlay2"
                                                        />

                                                    </Col>

                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Name  </lable>
                                                        <br />
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setinstallDescription(e.target.value)}
                                                            style={{ textTransform: "capitalize" }}
                                                            className="form-input "
                                                            placeholder="Enter Notes "
                                                        />
                                                       
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    

                                                    
                                                    <Col xs={12} md={6} className="text-start">
                                                        <lable>Task Status  </lable>
                                                        <br />
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setinstallTaskStatus(e.target.value)}
                                                            style={{ textTransform: "capitalize" }}
                                                            className="form-input "
                                                            placeholder="open "
                                                            readonly
                                                        />
                                                    
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>


                                       



                                    </Container>


                                </div>
                            </div>



                        )}




                        <button type="button" className='black-btn mx-3'> <MdEdit size={18} /> Edit</button>
                        <button type="button" className='blue-btn mx-3'>Submit</button>


                    </Col>
                </Row>

                <div className="tabs-container mt-5">
                    {/* Tabs */}
                    <div className="tabs">
                        <Row>
                            <Col>
                                <button type="button" className='order-details-btn' onClick={() => setActiveTab("orderDetails")}>Order Details</button>

                            </Col>
                            <Col>
                                <button onClick={() => setActiveTab("verification")}>Verification</button>

                            </Col>
                            <Col>
                                <button onClick={() => setActiveTab("workAssign")}>Work Assign</button>

                            </Col>
                            <Col>
                                <button onClick={() => setActiveTab("workDetails")}>Work Details</button>

                            </Col>
                            <Col>
                                <button onClick={() => setActiveTab("feedback")}>Feedback</button>

                            </Col>
                            <Col>
                                <button onClick={() => setActiveTab("payments")}>Payments</button>

                            </Col>


                        </Row>
                    </div>


                    {/* Dynamic Content */}
                    <div className="tab-content">

                        {renderContent()}






                    </div>



                </div>

            </Container>

        </div>
    )
}