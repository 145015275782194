import React, { useState, useEffect } from "react";
import { Table, Button, Form, Container, Row, Col } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./AccessTable.css";
import axios from "axios";
import Swal from "sweetalert2";

// Titles and configurations
const titles = [
  "Side Bar",
  "Dashboard",
  "Task",
  "Enquiry",
  "Installation",
  "Complaint",
  "Recovery",
  "Due",
  "Stock",
  "Customer",
  "Staff",
  "Staff Analysis",
  "Attendance",
  "Location",
  "Administration",
  "Settings",
];

// Modules structure with initial permissions

// Define which permissions should be shown for each title
const permissionsVisibility = {
  "Side Bar": ["View", "Access"],
  Dashboard: ["View", "Delete"],
  Task: ["View", "Access", "Delete"],
  Enquiry: ["View", "Access", "Delete"],
  Installation: ["View", "Access", "Delete"],
  Complaint: ["View", "Access", "Delete"],
  Recovery: ["View", "Access", "Delete"],
  Due: ["View", "Access", "Delete"],
  Stock: ["View", "Access", "Delete"],
  Customer: ["View", "Access", "Delete"],
  Staff: ["View", "Access", "Delete"],
  "Staff Analysis": ["View", "Access"],
  Attendance: ["View", "Access", "Delete"],
  Location: ["View", "Access", "Delete"],
  Administration: ["View", "Access", "Delete"],
  Settings: ["View", "Access", "Delete"],
};

const AccessTable = (props) => {
  const [initialPermissions, setinitialPermissions] = useState({
    "Side Bar": {
      Dashboard: { View: false, Access: false },
      Task: { View: false, Access: false },
      Enquiry: { View: false, Access: false },
      "Task Create": { View: false, Access: false },
      Installation: { View: false, Access: false },
      Complaints: { View: false, Access: false },
      Dues: { View: false, Access: false },
      Stock: { View: false, Access: false },
      "Stock Details": { View: false, Access: false },
      Recovery: { View: false, Access: false },
      "Customer Reports": { View: false, Access: false },
      "Staff Analysis": { View: false, Access: false },
      Attendance: { View: false, Access: false },
      Location: { View: false, Access: false },
      Staffs: { View: false, Access: false },
      Customers: { View: false, Access: false },
      Administration: { View: false, Access: false },
      Settings: { View: false, Access: false },
    },
    Dashboard: {
      "Show all Statistics": { View: false },
      "Show Installation Statistics": { View: false },
      "Show Complaint Statistics": { View: false },
      "Show Task Statistics": { View: false },
      "Show Recovery Statistics": { View: false },
      "Show Dues Statistics": { View: false },
      "Show Enquiry Chart": { View: false },
      "Show Installation Chart": { View: false },
      "Show Complaint Chart": { View: false },
      "Show Task Chart": { View: false },
      "Show Recovery Chart": { View: false },
      "Show Dues Chart": { View: false },
      "General Updates": { View: false, Delete: false },
      "Enquiry Updates": { View: false, Delete: false },
      "Installation Updates": { View: false, Delete: false },
      "Complaint Updates": { View: false, Delete: false },
      "Recovery Updates": { View: false, Delete: false },
      "Due Updates": { View: false, Delete: false },
    },
    Task: {
      "Task Statistics": { View: false },
      "Task Module": { View: false, Access: false, Delete: false },
      "Map View": { View: false, Access: false },
      "Task Create": { View: false, Access: false },
      "Enquiry Task Assign": { View: false, Access: false },
      "Installation Task Assign": { View: false, Access: false },
      "Complaint Task Assign": { View: false, Access: false },
      "Recovery Task Assign": { View: false, Access: false },
      "Due Task Assign": { View: false, Access: false },
      "General Task": { View: false, Access: false, Delete: false },
      "Enquiry Task": { View: false, Access: false, Delete: false },
      "Installation Task": { View: false, Access: false, Delete: false },
      "Complaint Task": { View: false, Access: false, Delete: false },
      "Recovery Task": { View: false, Access: false, Delete: false },
      "Due Task": { View: false, Access: false, Delete: false },
      "General Task Update": { View: false, Access: false, Delete: false },
      "Enquiry Update": { View: false, Access: false, Delete: false },
      "Installation Update": { View: false, Access: false, Delete: false },
      "Complaint Update": { View: false, Access: false, Delete: false },
      "Recovery Update": { View: false, Access: false, Delete: false },
      "Due Update": { View: false, Access: false, Delete: false },
      "General Task Approval": { View: false, Access: false, Delete: false },
    },
    Enquiry: {
      "Enquiry Statistics": { View: false },
      "Enquiry Module": { View: false, Access: false, Delete: false },
      "Map View": { View: false, Access: false },
      "Add Enquiry": { View: false, Access: false, Delete: false },
      "Enquiry Task Assign": { View: false, Access: false },
      "Enquiry Updates": { View: false, Access: false, Delete: false },
      "Enquiry Task Approve": { View: false, Access: false },
    },
    Installation: {
      "Installation Statistics": { View: false },
      "Installation Module": { View: false, Access: false, Delete: false },
      "Map View": { View: false, Access: false },
      "Add Installation": { View: false, Access: false },
      "Installation Verification": { View: false, Access: false },
      "Installation Task Assign": { View: false, Access: false },
      "Installation Updates": { View: false, Access: false, Delete: false },
      "Installation Task Approve": {
        View: false,
        Access: false,
        Delete: false,
      },
      "Work Details": { View: false, Access: false, Delete: false },
      Feedback: { View: false, Access: false },
      Payment: { View: false, Access: false },
    },
    Complaint: {
      "Complaint Statistics": { View: false },
      "Complaint Module": { View: false, Access: false, Delete: false },
      "Map View": { View: false, Access: false },
      "Add Complaint": { View: false, Access: false },
      "Complaint Verification": { View: false, Access: false },
      "Complaint Task Assign": { View: false, Access: false },
      "Complaint Updates": { View: false, Access: false, Delete: false },
      "Complaint Task Approve": { View: false, Access: false, Delete: false },
      "Work Details": { View: false, Access: false, Delete: false },
      Feedback: { View: false, Access: false },
      Payment: { View: false, Access: false },
    },
    Recovery: {
      "Recovery Statistics": { View: false },
      "Recovery Module": { View: false, Access: false, Delete: false },
      "Map view": { View: false, Access: false },
      "Add Recovery": { View: false, Access: false },
      "Recovery Task Assign": { View: false, Access: false },
      "Recovery Updates": { View: false, Access: false, Delete: false },
      "Recovery Task Approve": { View: false, Access: false, Delete: false },
    },
    Due: {
      "Due Statistics": { View: false },
      "Due Module": { View: false, Access: false, Delete: false },
      "Map view": { View: false, Access: false },
      "Add Dues": { View: false, Access: false },
      "Due Task Assign": { View: false, Access: false },
      "Due Updates": { View: false, Access: false, Delete: false },
      "Due Task Approve": { View: false, Access: false, Delete: false },
    },
    Stock: {
      "Stock Statistics": { View: false },
      "Stock Module": { View: false, Access: false, Delete: false },
      "Add Stock": { View: false, Access: false },
    },
    Customer: {
      "Customer Statistics": { View: false },
      "Customer Module": { View: false, Access: false, Delete: false },
      "Add Customer": { View: false, Access: false },
    },
    Staff: {
      "Staff Details": { View: false, Access: false, Delete: false },
      "Staff Module": { View: false, Access: false, Delete: false },
      "Add Staff": { View: false, Access: false },
    },
    "Staff Analysis": {
      "Staff Analysis Details": { View: false, Access: false },
    },
    Attendance: {
      "Attendance Statistics": { View: false },
      "Attendance Module": { View: false, Access: false, Delete: false },
      "Mark Attendance": { View: false, Access: false },
    },
    Location: {
      "Location Statistics": { View: false },
      "Location Module": { View: false, Access: false, Delete: false },
      "Map View": { View: false, Access: false },
    },
    Administration: {
      Permission: { View: false, Access: false, Delete: false },
      "Add Roles": { View: false, Access: false, Delete: false },
    },
    Settings: {
      "Plan Details": { View: false, Access: false, Delete: false },
      "New Plans": { View: false, Access: false, Delete: false },
    },
  });
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [currentPage, setCurrentPage] = useState(0);
  const [permissions, setPermissions] = useState(initialPermissions);

  const currentTitle = titles[currentPage];
  const currentModules = permissions[currentTitle];
  const visiblePermissions = permissionsVisibility[currentTitle];

  console.log("initialPermissions", initialPermissions);
  console.log("permissions", permissions);
  console.log("currentTitle", currentTitle);
  console.log("currentModules", currentModules);

  // Get module names for current title
  const moduleNames = Object?.keys(currentModules);

  const isAllChecked = () => {
    let isCheck = true;
    visiblePermissions.forEach((visibiity) => {
      if (!isGlobalChecked(visibiity)) return (isCheck = false);
    });

    return isCheck;
  };

  // const isAllChecked = moduleNames.every(moduleName => {
  //   visiblePermissions.every(perm => currentModules[moduleName][perm])
  // });

  // Check if a specific permission is selected for all modules
  const isGlobalChecked = (permissionType) => {
    let defaultValue = [];
    moduleNames.forEach((moduleName) => {
      if (
        JSON.stringify(currentModules[moduleName])?.includes(permissionType)
      ) {
        defaultValue.push(moduleName);
      }
    });
    return defaultValue?.every(
      (moduleName) => currentModules[moduleName][permissionType]
    );
  };

  // Handle individual permission change
  const [selectedPermission, setSelectedPermission] = useState({
    moduleName: "",
    permissionType: "",
    checked: false,
  });

  const handleModulePermissionChange = async (moduleName, permissionType) => {
    setPermissions((prev) => ({
      ...prev, // Preserve other titles
      [currentTitle]: {
        ...prev[currentTitle], // Preserve other modules under the title
        [moduleName]: {
          ...prev[currentTitle]?.[moduleName], // Preserve other permissions of the module
          [permissionType]: !prev[currentTitle]?.[moduleName]?.[permissionType], // Toggle permission
        },
      },
    }));

    // Get the new checked status
    const newCheckedState =
      !permissions[currentTitle]?.[moduleName]?.[permissionType];

    // Update selectedPermission state
    setSelectedPermission({
      moduleName,
      permissionType,
      checked: newCheckedState,
    });

    // Simulate API update (replace with actual DB update function)
    updatePermissionInDB({
      ...permissions, // Spread existing permissions
      [currentTitle]: {
        ...permissions[currentTitle], // Spread other modules under the same title
        [moduleName]: {
          ...permissions[currentTitle]?.[moduleName], // Spread other permissions of the module
          [permissionType]: newCheckedState, // Update specific permission
        },
      },
    });
  };

  // Mock function to update permissions in the database
  const updatePermissionInDB = (updatedPermissions) => {
    console.log("Updating DB with permissions:", updatedPermissions);
    console.log("roles", props.roles);
    // Perform actual DB update call here
  };

  // Handle all permissions toggle
  const handleAllPermissionChange = () => {
    const newState = !moduleNames.every((moduleName) =>
      Object.values(currentModules[moduleName]).every((perm) => perm)
    );
    const updatedModules = { ...currentModules };
    moduleNames.forEach((moduleName) => {
      Object.keys(updatedModules[moduleName]).forEach((perm) => {
        updatedModules[moduleName][perm] = newState;
      });
    });
    setPermissions((prev) => ({
      ...prev,
      [currentTitle]: updatedModules,
    }));
  };

  // Handle global permission column toggle
  const handleGlobalPermissionChange = (permissionType) => {
    const newState = !isGlobalChecked(permissionType);
    const updatedModules = { ...currentModules };
    moduleNames.forEach((moduleName) => {
      if (
        JSON.stringify(updatedModules[moduleName])?.includes(permissionType)
      ) {
        updatedModules[moduleName][permissionType] = newState;
      }
    });

    setPermissions((prev) => ({
      ...prev,
      [currentTitle]: updatedModules,
    }));
  };

  const handleNext = () =>
    currentPage < titles.length - 1 && setCurrentPage((p) => p + 1);
  const handlePrev = () => currentPage > 0 && setCurrentPage((p) => p - 1);

  const filteredModuleNames = moduleNames.filter((moduleName) =>
    visiblePermissions.some((perm) =>
      currentModules[moduleName].hasOwnProperty(perm)
    )
  );

  //Save
  // const savePermissions = async () => {
  //   try {
  //     const payload = {
  //       roles: props.roles, // Include the role from props
  //       // moduleName: selectedPermission.moduleName, // Include the selected module name
  //       permissions: permissions // Include the entire permissions object
  //     };
  //     await axios.post("http://localhost:5000/api/add", payload);
  //     alert("Permissions saved successfully!");
  //   } catch (error) {
  //     console.error("Error saving permissions:", error);
  //     alert("Failed to save permissions.");
  //   }
  // };

  const fetchPermissions = async () => {
    try {
      const response = await axios.post(
        apiUrl + "adminview",
        { roleName: props.roles },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.message === "Admin role not found") {
        console.log("Admin role not found");
        // setError("Role not found. Default permissions loaded.");
      } else {
        setinitialPermissions(response.data.message.modules);
        setPermissions(response.data.message.modules);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const savePermissions = async () => {
    try {
      const payload = {
        roleName: props.roles, // Corrected from "roles"
        modules: permissions, // Changed from "permissions" (ensure correct data format)
      };

      const response = await axios.post(apiUrl + "adminadd", payload, {
        headers: { "Content-Type": "application/json" },
      });
      //   alert("Permissions saved successfully!");
      Swal.fire({
        title: "Permissions saved successfully!",
        icon: "success",
        draggable: true,
      });
    } catch (error) {
      console.error(
        "Error saving permissions:",
        error.response?.data || error.message
      );
      alert("Failed to save permissions.");
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  return (
    <Container fluid className="fullcomponent">
      <div className="border-header-admin">

      <h5 className="roles-top1 text-center">{props.roles}</h5>

      </div>
      <button onClick={savePermissions} className="blue-btn admin-submit">
        Submit
      </button>
      <div className="tableborder-admin">

        <table style={{ width: "100%", backgroundColor: "white", borderBottom: "2px" }} className="table-admin ">
          <thead style={{backgroundColor:"white"}} >
            <tr style={{backgroundColor:"white"}} >
              <th className="accesstable-th-fontsize">

                <Row>
                  <Col md={8} sm={8} xs={8}>
                    <Row>
                      <Row className="taskrow">
                        <Col md={5} sm={5} xs={5}>
                          <h5 style={{ paddingTop: "15px" }}>{currentTitle}</h5>
                        </Col>
                        <Col>
                          <Row style={{}}>

                            <Col md={2} sm={2} xs={2} >
                              <Button style={{ paddingTop: "10px", paddingLeft: "-10px" }} variant="link" className="arrow-button" onClick={handlePrev} disabled={currentPage === 0}>
                                <FaChevronLeft />
                              </Button>
                            </Col>
                            <Col style={{ paddingTop: "8px", paddingLeft: "30px", }} md={2} sm={2} xs={2}>
                              <p >{currentPage + 1}</p>
                            </Col>
                            <Col style={{ paddingTop: "5px", }} md={2} sm={2} xs={2}>
                              <Button variant="link" className="arrow-button" onClick={handleNext} disabled={currentPage === titles.length - 1}>

                                <FaChevronRight />
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                  <Col style={{ paddingTop: "10px" }} md={4} sm={4} xs={4}>
                    <Form.Check
                      type="checkbox"
                      label="All"
                      checked={isAllChecked()}

                      
                      className="checkbox-label accesstable all-accesstable accesstable-th-fontsize"

                      onChange={handleAllPermissionChange}
                    />
                  </Col>
                </Row>
              </th>
              {/*             
            {visiblePermissions.map(perm => (
              <th key={perm} className="col-1">{perm}</th>
            ))} */}

              {visiblePermissions.map(perm =>
                <th  key={perm} className="text-center accesstable-th-fontsize">
                  
                  <Form.Check
                    type="checkbox"
                    label={perm}
                    checked={isGlobalChecked(perm)}
                    className="checkboxadmin accesstable"
                    onChange={() => handleGlobalPermissionChange(perm)}
                  />

                </th>
              )}
            </tr>
         </thead>

          <tbody >

          
            {filteredModuleNames.map(moduleName => (
              <tr key={moduleName}>
                <td className="accesstable-font-size">{moduleName}</td>
                {visiblePermissions.map(perm => (
                  <td key={perm}>
                    {
                      currentModules[moduleName].hasOwnProperty(perm) ? (
                        <Form.Check
                          type="checkbox"
                          checked={currentModules[moduleName][perm]}
                          onChange={() => handleModulePermissionChange(moduleName, perm)}
                          className="accesstable1"
                        />
                      ) : null}

                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export default AccessTable;
