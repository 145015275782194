import React, { useState } from 'react';
// import Services from '../../api/service'
// import ServiceSingle from '../ServiceSingle';
import Settings from '../Settings/Settings'

const  SettingsSection = (props) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }


    return (

        <section className="service-section" id="Services">
            
                <div className="row">
                  <Settings/>
                
                  
                </div>
           
           
        </section>
    );
}

export default SettingsSection;