import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import "./PlussStock.css";
import { MdEdit } from "react-icons/md";

export const PlusStock = () => {
  return (
    <>
      <Topbar />
      <Container className="mt-1">
        <form>
          <Row>
            <Col md={2} sm={2} className="text-start">
              <h3>Stock</h3>
            </Col>
            <Col className="text-end">
              <button type="button" className="black-btn me-3 stock-edit">
                <MdEdit /> Edit
              </button>
              <button type="button" className="blue-btn ms-3 ">
                Submit
              </button>
            </Col>
          </Row>
          <div className="border-container1">
            <h6 className="form-head text-white stock-head mb-0">Add Stock</h6>
          </div>
          <div className="border-container-stock">


            <div className="border-container-plus">
            <Row>
              <Col xs={12} md={3} className="text-start">
                <label>Date</label>
                <br></br>
                <input type="date" className="form-input mt-2" />
              </Col>
              <Col xs={12} md={3} className="text-start ">
                <lable className="">Category</lable>
                <br />

                <select class="form-input-select ">
                  <option value="">Select</option>
                  <option className="" value="Purchase">
                    Purchase
                  </option>
                  <option className="" value="Recovery">
                    Recovery
                  </option>
                  <option className="" value="Damage">
                    Damage
                  </option>
                  <option className=" " value="Instalation">
                    Instalation
                  </option>
                  <option className="" value="Complaint">
                    Complaint
                  </option>
                </select>
              </Col>

              <Col  xs={12} md={6} className="text-start">
                <label>Vendor Name</label>
                <br></br>
                <input type="text" className="form-input mt-2" />
              </Col>
            </Row>
            </div>
            <div  className="border-container-plus mt-5 ms-0">

              Fiber(2F-)20M

            </div>

          
            <Row className="mt-5">
              <Col xs={12} md={6} className="text-start">
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <label className=" ">Materials</label><br></br>
                    <select class="dropbtn form-input-select">
                      <option value="">Select</option>
                      <option className="" value="Purchase">
                        Fiber
                      </option>
                      <option className="" value="Recovery">
                        Splitter
                      </option>
                      <option className="" value="Damage">
                        2 way clouser box
                      </option>
                      <option className=" " value="Instalation">
                        mini clouser box
                      </option>
                      <option className="" value="Complaint">
                        Green patch chord
                      </option>
                      <option className="" value="Complaint">
                        Blue patch chord
                      </option>
                      <option className="" value="Complaint">
                        Router
                      </option>
                      <option className="" value="Complaint">
                        Cat 6 cable
                      </option>
                      <option className="" value="Complaint">
                        others
                      </option>
                    </select>
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable className="">Materials Types</lable>
                    <br />

                    <select class="dropbtn form-input-select">
                      <option value="">Select</option>
                      <option className="" value="Purchase">
                        2F
                      </option>
                      <option className="" value="Recovery">
                        4F
                      </option>
                      <option className="" value="Damage">
                        6F
                      </option>
                      <option className="" value="Complaint">
                        others
                      </option>
                    </select>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={6} className="text-start">
                <Row>
                  <Col xs={12} md={5} className="text-start">
                    <lable className="">Unit</lable>
                    <br />
                    <input className="form-input" type="text" />
                  </Col>

                  <Col xs={12} md={4} className="text-start">
                    <lable className="">Selling Cost</lable>
                    <br />
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Rs. 0"
                    />
                  </Col>

                  <Col xs={12} md={3} className="text-start mt-3">
                    <lable></lable>
                    <br />
                    <button type="submit" className="blue-btn plus-blue-btn">
                      +
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
};