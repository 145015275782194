import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Add_Customer.css";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaStarOfLife } from "react-icons/fa6";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";

import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Add_Customer() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  let [customerid, setcustomerid] = useState("");
  let [planname, setplanname] = useState("");
  let [customername, setcustomername] = useState("");
  let [emailid, setemailid] = useState("");
  let [area, setarea] = useState("");
  let [contactnum, setcontactnum] = useState("");
  let [alternatenum, setalternatenum] = useState("");
  let [installationadd, setinstallationadd] = useState("");
  let [accountmanager, setaccountmanager] = useState("");
  let [activationdate, setactivationdate] = useState("");
  let [expirydate, setexpirydate] = useState("");
  let [lastrenewaldate, setlastrenewaldate] = useState("");
  let [billingadd, setbillingadd] = useState("");
  let [lastlogoffdate, setlastlogoffdate] = useState("");
  let [installationcharge, setinstallationcharge] = useState("");
  let [refunddeposit, setrefunddeposit] = useState("");
  let [refunddes, setrefunddes] = useState("");
  let [accountstatus, setaccountstatus] = useState("");
  let [devicestatus, setdevicestatus] = useState("");

  let [error, setError] = useState("");
  // let [errorid, setErrorid] = useState("");

  let [error1, setError1] = useState("");
  // let [errorphone, seterrorPhone] = useState("");
  // let [id, setid] = useState("");

  const [value, setValue] = useState("");
   
  const [errorEmail, setErrorEmail] = useState("");
  const handleEmailBlur = () => {
    if (emailid.trim() !== "") {
      if (!validateEmail(emailid)) {
        setErrorEmail("Please enter a valid email address (e.g., example@domain.com).");
      } else {
        setErrorEmail("");
      }
    } else {
      setErrorEmail(""); // Clear the error if the field is empty
    }
  };
  
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu)$/i;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Ensures a 10-digit number starting with 6-9
    return phoneRegex.test(number);
  };
  const validatePhoneNumber1 = (number) => {
    const phoneRegex1 = /^[6-9]\d{9}$/; // Ensures a 10-digit number starting with 6-9
    return phoneRegex1.test(number);
  };

  const handledecimal = (e) => {
    const inputValue = e.target.value;
    // Regular expression to match numbers with up to two decimal places
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const [value1, setValue1] = useState("");

  const handledecimal1 = (e) => {
    const inputValue1 = e.target.value;
    // Regular expression to match numbers with up to two decimal places
    const regex1 = /^\d*\.?\d{0,2}$/;
    if (regex1.test(inputValue1)) {
      setValue1(inputValue1);
    }
  };

  const navigate = useNavigate();
  
  const add_data = async (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(contactnum)) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    } else {
      setError(""); // Clear error if validation passes
    }
    if (emailid && !validateEmail(emailid)) {
      setErrorEmail("Invalid email format. Must end with .com, .org, .net, or .edu");
     
      return; // Prevent submission if email is invalid
    } else {
      setErrorEmail(""); // Clear error if validation passes
    }
    // if (alternatenum && !validatePhoneNumber1(alternatenum)) {
    //   setError1("Please enter a valid 10-digit phone number.");
    //   return; // Prevent submission if email is invalid
    // } else {
    //   setError1(""); // Clear error if validation passes
    // }
    if (alternatenum.trim() && !validatePhoneNumber(alternatenum.trim())) {
      setError1("Please enter a valid 10-digit phone number.");
      return;
    } else {
      setError1("");
    }

    Swal.fire({
      title: "Do you want to save the Customer Details?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      // denyButtonText: `Don't save`
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(apiUrl+"add", {
            customerid,
            planname,
            customername,
            emailid,
            area,
            contactnum,
            alternatenum,
            installationadd,
            accountmanager,
            activationdate,
            expirydate,
            lastrenewaldate,
            billingadd,
            lastlogoffdate,
            installationcharge,
            refunddeposit,
            refunddes,
            accountstatus,
            devicestatus,
          });
          
          Swal.fire({
            title: "Customer Details Added Successfully!..",
            icon: "success",
            draggable: true,
          }).then(() => {
            navigate("/customersection");
          });
        } catch (error) {
          console.log("Error submitting data", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
};



  //______________________
  const [isUnique, setIsUnique] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (customerid) {
      checkCustomerId(customerid);
    }
  }, [customerid]);

  const checkCustomerId = async (id) => {
    try {
      const response = await axios.get(apiUrl+`check/${id}`);
      if (response.data.exists) {
        setIsUnique(false);
        setErrorMessage("Customer ID already exists!");
      } else {
        setIsUnique(true);
        setErrorMessage("");
      }
    } catch (error) {
      console.error("Error checking customer ID:", error);
    }
  };
  //_________________

  const handlePhoneNumberChange = (e) => {
    setcontactnum(e.target.value); // update phone number as user types
  };

  // Validate phone number when the input field loses focus (onBlur)
  const handlePhoneNumberBlur = () => {
    const regex = /^[0-9]{10}$/; // Regex for a 10-digit phone number
    if (!regex.test(contactnum)) {
      setError("Please enter a valid 10-digit phone number");
    } else {
      setError("");
    }
  };

  const handlerefunddeposit = (e) => {
    setrefunddeposit(e.target.value);
    handledecimal(e); // update phone number as user types
  };

  const handleintallationcharge = (e) => {
    setinstallationcharge(e.target.value);
    handledecimal1(e); // update phone number as user types
  };

  const handlePhoneNumberChange1 = (e) => {
    setalternatenum(e.target.value); // update phone number as user types
  };

  // Validate phone number when the input field loses focus (onBlur)
  // const handlePhoneNumberBlur1 = () => {
  //   const regex1 = /^[0-9]{10}$/; // Regex for a 10-digit phone number
  //   if (!regex1.test(alternatenum)) {
  //     setError1("Please enter a valid 10-digit phone number");
  //   } else {
  //     setError1("");
  //   }
  // };


  const handlePhoneNumberBlur1 = () => {
    const trimmedNumber = alternatenum.trim();
    setalternatenum(trimmedNumber); // Update state with trimmed value
    if (trimmedNumber && !validatePhoneNumber(trimmedNumber)) {
      setError1("Please enter a valid 10-digit phone number");
    } else {
      setError1("");
    }
  };

  return (
    <>
    <Topbar/>
      <Container className="mt-1    ">
        <form onSubmit={add_data}>
          <Row>
            <Col>
              <h2>Customers</h2>
            </Col>

            <Col className="mx-5 text-end">
              <button
                className=" blue-btn  px-4 text-white"
                value="submit"
                type="submit"
                disabled={!isUnique}
              >
                Submit
              </button>
            </Col>
          </Row>

          <div className="border-container1">
            <h5 className="form-head  m-0 text-white  ">Customer Details</h5>
          </div>

          <div className="border-container">
            <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12} md={6} className="text-start">
                    <lable sm={12} className="rowdown ">
                      {" "}
                      Customer Id{" "}
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      sm={12}
                      type="text"
                      required
                      onChange={(e) => setcustomerid(e.target.value)}
                      // onBlur={checkCustomerId}
                      className="form-input"
                      placeholder="Enter Customer ID"
                    />
                    {!isUnique && <p className="text-danger">{errorMessage}</p>}
                  </Col>

                  <Col sm={12} md={6} className="text-start">
                    <lable className="rowdown"> Plan Name</lable>
                    <br/>
                    <input
                      type="text"
                      onChange={(e) => setplanname(e.target.value)}
                      className="form-input"
                      placeholder="Enter Plan Name"
                      style={{ textTransform: "capitalize " }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Name</lable>
                <br />
                <input
                  type="text"
                  onChange={(e) => setcustomername(e.target.value)}
                  style={{ textTransform: "capitalize " }}
                  className="form-input  inputfield"
                  placeholder="Enter Custmer Name"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Mail Id</lable>
                    <br />
                    <input
                      type="email"
                      onChange={(e) => setemailid(e.target.value)}
                       pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      className="form-input"
                      placeholder="Enter Mail ID"
                      name="emailid"
                      onBlur={handleEmailBlur}
                      value={emailid}

                      
                    />
                     
                     {errorEmail && <p style={{ color: "red" }}>{errorEmail}</p>}
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Area</lable>
                    <br />
                    <input
                      type="text"
                      onChange={(e) => setarea(e.target.value)}
                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      placeholder="Enter Area"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>
                      Contact Number{" "}
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      type="tel"
                      maxlength="10"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      value={contactnum}
                      onBlur={handlePhoneNumberBlur}
                      onChange={handlePhoneNumberChange}
                      required
                      placeholder="Enter Phone Number"
                      className="form-input"
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Alternate Number  </lable>
                    <br />
                    <input
                      type="tel"
                      maxlength="10"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      value={alternatenum}
                      onBlur={handlePhoneNumberBlur1}
                      onChange={handlePhoneNumberChange1}
                      className="form-input"
                      placeholder="Enter Phone Number"
                      name="phoneNumber2"
                      
                    />
                    {error1 && <p style={{ color: "red" }}>{error1}</p>}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="text-start">
                <lable>Installation Address</lable>
                <br />
                <textarea
                  type="text"
                  onChange={(e) => setinstallationadd(e.target.value)}
                  style={{ textTransform: "capitalize", resize: "none" }}
                  className="box inputfield "
                  placeholder="Enter Installation Address"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Account Manager</lable>
                    <br />
                    <input
                      onChange={(e) => setaccountmanager(e.target.value)}
                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      type="text"
                      placeholder="Enter Account Manager"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Activation Date</lable>
                    <br />
                    <input
                      onChange={(e) => setactivationdate(e.target.value)}
                      className="field form-input"
                      type="date"
                      placeholder="Enter "
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Expiry Date</lable>
                    <br />
                    <input
                      className="form-input field"
                      onChange={(e) => setexpirydate(e.target.value)}
                      type="date"
                      placeholder="Enter"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Last Renewal Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      onChange={(e) => setlastrenewaldate(e.target.value)}
                      type="date"
                      placeholder="Enter"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="text-start">
                <lable>Billing Address</lable>
                <br />
                <textarea
                  style={{
                    
                    textTransform: "capitalize",
                    resize: "none",
                    
                  }}
                  onChange={(e) => setbillingadd(e.target.value)}
                  type="text"
                  className="box inputfield"
                  placeholder="Enter Billing Address"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Last Logoff Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      onChange={(e) => setlastlogoffdate(e.target.value)}
                      type="date"
                      placeholder="Enter"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Installation Charges</lable>
                    <br />
                    <input
                      value={value1}
                      onChange={handleintallationcharge}
                      className="form-input"
                      type="tel"
                    //   onKeyDown={(e) => {
                    //     if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                    //      e.preventDefault();
                    //    }
                    //  }}
                      placeholder="Enter Installation Charges"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Refund Deposit</lable>
                    <br />
                    <input
                      value={value}
                      onChange={handlerefunddeposit}
                      type="tel"
                    //   onKeyDown={(e) => {
                    //     if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                    //      e.preventDefault();
                    //    }
                    //  }}
                      className="form-input"
                      placeholder="Enter Refund Deposit"
                    />
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Refund Description</lable>
                    <br />
                    <input
                      style={{ textTransform: "capitalize" }}
                      onChange={(e) => setrefunddes(e.target.value)}
                      className="form-input"
                      type="text"
                      placeholder="Enter Refund Description"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Account Status</lable>
                    <br />

                    <select
                      onChange={(e) => setaccountstatus(e.target.value)}
                      class=" dropbtn form-input-select "
                    >
                      <option value="">Select</option>
                      <option className="activeback " value="Active">
                        Active
                      </option>
                      <option className="inactiveback" value="Inactive">
                        Inactive
                      </option>
                      <option className="suspendedback" value="Suspended">
                        Suspended
                      </option>

                      <option className="disableback" value="Disable">
                        Disable
                      </option>
                    </select>
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Device Status </lable>
                    <br />

                    <select
                      onChange={(e) => setdevicestatus(e.target.value)}
                      class="dropbtn form-input-select"
                    >
                      <option value="">Select</option>
                      <option
                        className="onlineback"
                        style={{ background: "#fee792" }}
                        value="Online"
                      >
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}