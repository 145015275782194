import React, { useState } from 'react';
// import Services from '../../api/service'
// import ServiceSingle from '../ServiceSingle';
import Customer from '../Customer/Customer'
import Staff from '../Staff/Staff'

const CustomerSection = (props) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }


    return (

        <section className="service-section" id="Services">
            <div >
                <div className="row">
                  
                
                   <Customer/>
                  
                </div>
            </div>
            {/* <ServiceSingle open={open} onClose={handleClose} title={state.sTitle} dImg={state.sImgS} sImg1={state.ssImg1} sImg2={state.ssImg2} /> */}
        </section>
    );
}

export default CustomerSection;