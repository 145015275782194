import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import { FaStarOfLife } from "react-icons/fa6";
import { Modal, Box, Button } from "@mui/material";
import { ImCross } from "react-icons/im";
import { FiX, FiUpload } from "react-icons/fi"; // Close & Upload icons
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./AddEnquiry.css"
import { useEffect } from "react";



export default function AddEnquiry() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;




  const [addEnquiry, setAddEnquiry] = useState({
   leadId: "",
    createdDate: "",
  
    enquirycustomerName: "",
    enquirycontactNum: "", 
    enquiryalternateNum: "",
    enquiryaddress: "",
    enquirylocation: "",
    enquiryleadSource: "",
    // enquiryaccountManager: "",
    enquiryemailId: "",
    enquirysalesName: "",
  
    enquiryplanDetails: {
      enquiryplanSpeed: "",
      enquiryplanMonth: "",
      enquiryplanAmount: "",
      enquirystaticIpCost: "",
      enquiryfiberCableMeter: "",
      enquiryinstalationCost: "",
      enquiryrouterModel: "",
      enquiryrouterCost: "",
      enquiryottPackDetails: "",
      enquiryothers: "",
      enquiryothersCost: "",
      enquiryadvanceAmount: {
        enquirycurrentDate: "",
        enquiryamount: "",  
        enquirypaymentType: "",
        enquiryreferenceNumber: "" 
      }
    },
    enquirynextFollowUpDate: "",

  
    enquiryleadStatus: {
      enquirynotes: "",
      enquiryLeadStatusnextFollowUpDate: "",

      
    }
  }
  );
  
 
  const [selectedFile, setSelectedFile] = useState(null);


  const[isOpenAdvancePayment,setIsOpenAdvancePayment]=useState(false)

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  

  const [isOpenplan, setIsOpenplan] = useState(false);
  const [isOpenConverted, setIsOpenConverted] = useState(false);

  const navigate=useNavigate();

    //  useEffect(() => {
    //    const fetchLastLeadId = async () => {
    //      try {
    //        const res = await axios.get("http://localhost:3001/api/last-lead-id");
    //        const lastLeadId = res.data.lastLeadId;
    //        const numericPart = parseInt(lastLeadId.substring(1)) + 1;
    //        const nextLeadId = `A${numericPart.toString().padStart(2, "0")}`;
    //        setAddEnquiry({
    //          ...addEnquiry,
             
    //          leadId: nextLeadId,
    //          createdDate: new Date().toISOString().split("T")[0]
    //          // Current date
    //        });
    //      } catch (error) {
    //        console.error("Error fetching last Lead ID", error);
    //      }
    //    };
   
    //    fetchLastLeadId();
    //  }, []);
    // useEffect(() => {
    //   const fetchLastLeadId = async () => {
    //     try {
    //       const res = await axios.get("http://localhost:3001/api/last-lead-id");
    //       const lastLeadId = res.data.lastLeadId;
    //       const numericPart = parseInt(lastLeadId.substring(1)) + 1;
    //       const nextLeadId = `E - ${numericPart.toString().padStart(2, "0")}`;
    //       setAddEnquiry({
    //         ...addEnquiry,
    //         leadId: nextLeadId,
    //         createdDate: new Date().toISOString().split("T")[0], // Current date
    //       });
    //     } catch (error) {
    //       console.error("Error fetching last Lead ID", error);
    //     }
    //   };
  
    //   fetchLastLeadId();
    // }, []);


    useEffect(() => {
      fetch("http://localhost:3001/api/generate-lead-id")
        .then((res) => res.json())
        .then((data) => {
          console.log("Fetched Lead ID:", data.leadId); // Debugging log
    
          setAddEnquiry((prev) => ({
            ...prev, // Spread previous state
            leadId: data.leadId, // Update only the leadId field
            createdDate: new Date().toISOString().split("T")[0], // Set today's date
          }));
        })
        .catch((error) => console.error("Error fetching Lead ID:", error));
    }, []);
    





     const handlePlanChange = (e) => {
      setAddEnquiry({
        ...addEnquiry,
        enquiryplanDetails: {
          ...addEnquiry.enquiryplanDetails,
          [e.target.name]: e.target.value
        }
      });
    };

    const handleAmountChange = (e) => {
      const { name, value } = e.target;
      setAddEnquiry(prevState => ({
          ...prevState,
          enquiryplanDetails: {
              ...prevState.enquiryplanDetails,
              enquiryadvanceAmount: {
                  ...prevState.enquiryplanDetails.enquiryadvanceAmount,
                  [name]: value
              }
          }
      }));
  };


  const handleLeadChange = (e) => {
    const { name, value } = e.target;
    setAddEnquiry(prevState => ({
        ...prevState,
        enquiryleadStatus: {
            ...prevState.enquiryleadStatus,
            [name]: value
        }
    }));
};


  
    
  
     const handleChange = (e) => {
       setAddEnquiry({ ...addEnquiry, [e.target.name]: e.target.value });
     };
     const [errors, setErrors] = useState({}); // Validation errors


     
     const handleSubmit = async (e) => {
      e.preventDefault();
      
        if (addEnquiry.enquirycontactNum === "") {
          alert("Phone number is required!");
      }
      

    

      
  
      Swal.fire({
          title: "Do you want to save the Customer Details?",
          showCancelButton: true,
          confirmButtonText: "Save",
      }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                  const response = await axios.post(
                      "http://localhost:3001/api/add-lead",
                      addEnquiry,
                      { headers: { "Content-Type": "application/json" } } // 👈 Ensure JSON format
                  );
  
                  Swal.fire({
                      title: "Customer Details Added Successfully!",
                      icon: "success",
                  }).then(() => {
                      navigate("/enquiry");
                  });
              } catch (error) {
                  console.log("Error submitting data:", error.response?.data || error.message);
                  Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.response?.data?.message || "Something went wrong!",
                  });
              }
          }
      });
  };
  

  return (
    <>
      <Topbar />
      <Container className="mt-1    ">
        <form>
          <Row>
            <Col>
              <h3>Enquiry</h3>
            </Col>

            <Col className="mx-5 text-end">
              <button
              onClick={handleSubmit}
                className=" blue-btn  px-4 text-white"
                value="submit"
                type="submit"
              >
                Submit
              </button>
            </Col>
          </Row>

          <div className="border-container1">
            <h6 className="form-head  m-0 text-white  ">Order Details</h6>
          </div>

          <div className="border-container ">
            <Row className="text-start lead-id-row  mb-4 mt-4 ms-4 ">
              <p className="ps-5 paragraph-lead-id">
                Lead ID - <span className="text-primary " value={addEnquiry.leadId} onChange={handleChange}>
                  {addEnquiry.leadId}</span>{" "}
                <span className="lead-start-date">
                  {" "}
                  Lead Started Date -{" "}
                  <span className="text-primary">{addEnquiry.createdDate}</span>
                </span>{" "}
              </p>
            </Row>
            <Row className="ms-3">
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Name</lable>
                <br />
                <input
                  type="text"
                  style={{ textTransform: "capitalize " }}
                  className="form-input "
                  placeholder="Enter Custmer Name"
                  value={addEnquiry.customerName} onChange={handleChange}
                  name="enquirycustomerName"
                />
              </Col>

              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>
                      Contact Number
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Enter Phone Number"
                      className="form-input"
                      value={addEnquiry.enquirycontactNum} onChange={handleChange}
                      name="enquirycontactNum"
                      maxLength={10}
                      required

                    />


                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Alternate Number </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      value={addEnquiry.enquiryalternateNum} onChange={handleChange}
                      name="enquiryalternateNum"
                      maxLength={10}

                      className="form-input"
                      placeholder="Enter Phone Number"
                      
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
                <lable> Address</lable>
                <br />
                <textarea
                  type="text"
                  value={addEnquiry.enquiryaddress} onChange={handleChange}

                  style={{ textTransform: "capitalize", resize: "none" }}
                  className=" box address-enquiry"
                  placeholder="Enter  Address"
                  name="enquiryaddress"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Location</lable>
                    <br />
                    <input
                     value={addEnquiry.enquirylocation} onChange={handleChange}

                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      type="text"
                      placeholder="Enter Location"
                      name="enquirylocation"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Lead Source</lable>
                    <br />

                    <select
                  value={addEnquiry.enquiryleadSource} onChange={handleChange}
                  class="dropbtn form-input-select"
                  name="enquiryleadSource"
                    >
                      <option value="">Select</option>
                      <option className="onlineback" value="Online">
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Sales Name</lable>
                    <br />

                    <select
                  value={addEnquiry.enquirysalesName} onChange={handleChange}
                  name="enquirysalesName"
                  class="dropbtn form-input-select"
                    >
                      <option value="">Select</option>
                      <option className="onlineback" value="Online">
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <label></label>
                    <input
                      type="button"
                      value="Plan details"
                      onClick={() => setIsOpenplan(true)}
                      className="form-input converted-form plandetails-form "
                         onChange={handleChange}
                      

                    
                       />
                     

                   

                    {isOpenplan && (
                      <div className="overlay1">
                        <div className="modal1">
                          <Container>
                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Plan Speed</lable>
                                    <br />
                                    <input
                                      type="text"
                                      value={addEnquiry.enquiryplanDetails.enquiryplanSpeed} onChange={handlePlanChange}
                                    name="enquiryplanSpeed"
                                      className="form-input overlay2"
                                      placeholder="Enter Plan Speed"
                                    />
                                  </Col>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Plan Month</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addEnquiry.enquiryplanDetails.enquiryplanMonth} onChange={handlePlanChange}
                                      name="enquiryplanMonth"

                                      className="form-input overlay2"
                                      placeholder="Enter Plan Month"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Plan Amount</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addEnquiry.enquiryplanDetails.enquiryplanAmount} onChange={handlePlanChange}
                                      name="enquiryplanAmount"

                                      placeholder="Rs"
                                      className="form-input overlay2"
                                    />
                                  </Col>

                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Static Ip Cost </lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addEnquiry.enquiryplanDetails.enquirystaticIpCost} onChange={handlePlanChange}
                                      name="enquirystaticIpCost"

                                      placeholder="Rs"
                                      className="form-input overlay2"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Fiber Cable Meter</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      
                                      value={addEnquiry.enquiryplanDetails.enquiryfiberCableMeter} onChange={handlePlanChange}
                                      name="enquiryfiberCableMeter"

                                      className="form-input overlay2"
                                      placeholder="Enter Meter"
                                    />
                                  </Col>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Installation Cost</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addEnquiry.enquiryplanDetails.enquiryinstalationCost} onChange={handlePlanChange}
                                      name="enquiryinstalationCost"

                                      className="form-input overlay2"
                                      placeholder="Enter installation Cost"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Router Model</lable>
                                    <br />
                                    <select
                                  value={addEnquiry.enquiryplanDetails.enquiryrouterModel} onChange={handlePlanChange}
                                  name="enquiryrouterModel"

                                      class="dropbtn form-input-select overlay2"
                                    >
                                      <option value="">Select</option>
                                      <option
                                        className="onlineback"
                                        value="Online"
                                      >
                                        Online
                                      </option>
                                      <option
                                        className="offlineback"
                                        value="Offline"
                                      >
                                        Offline
                                      </option>
                                    </select>
                                  </Col>

                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Router Cost </lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addEnquiry.enquiryplanDetails.enquiryrouterCost} onChange={handlePlanChange}
                                      name="enquiryrouterCost"

                                      className="form-input overlay2"
                                      placeholder="Enter installation Cost"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Ott Pack Meter</lable>
                                    <br />
                                    <select
                                   value={addEnquiry.enquiryplanDetails.enquiryottPackDetails} onChange={handlePlanChange}
                                   name="enquiryottPackDetails"

                                      class="dropbtn form-input-select overlay2"
                                    >
                                      <option value="">Select</option>
                                      <option
                                        className="onlineback"
                                        value="Online"
                                      > 
                                        Online
                                      </option>
                                      <option
                                        className="offlineback"
                                        value="Offline"
                                      >
                                        Offline
                                      </option>
                                    </select>
                                  </Col>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Others</lable>
                                    <br />
                                    <input
                                      type="text"
                                      value={addEnquiry.enquiryplanDetails.enquiryothers} onChange={handlePlanChange}
                                      name="enquiryothers"

                                      style={{ textTransform: "capitalize" }}
                                      className="form-input overlay2"
                                      placeholder="Enter "
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Others Cost</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addEnquiry.enquiryplanDetails.enquiryothersCost} onChange={handlePlanChange}
                                      name="enquiryothersCost"

                                      placeholder="Rs"
                                      className="form-input overlay2"
                                    />
                                  </Col>

                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Advance Amount </lable>
                                    <br />
                                    


                                      <select

    className="form-input-select"
    name="enquiryadvanceAmount"

    value={addEnquiry.enquiryplanDetails.enquiryadvanceAmount.enquirypay}
    onChange={(e) => {
        handleChange(e);
        if (e.target.value === "Pay") {
            setIsOpenAdvancePayment(true);
        }
    }}
>
    <option value="">Select Type</option>
    <option value="Unpay">Unpay</option> 
    <option value="Pay">Pay</option>
   
</select>

{ isOpenAdvancePayment && (
    <div className="overlay-advance-payment">
        <div className="modal-advance-payment">
            <Container>
                <Row>
                    <Col> <h6>Advance Pay</h6></Col>
                    <Col className="text-end">
                        <span 
                            style={{ fontSize: "23px", cursor: "pointer" }} 
                            onClick={() => setIsOpenAdvancePayment(false)}
                        >
                            <strong>&times;</strong>  
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <label>Current Date</label>
                            <br />
                            <input className="form-input" type="date"
                                value={addEnquiry.enquiryplanDetails.enquiryadvanceAmount.enquirycurrentDate} 
                                onChange={ handleAmountChange}
                                name="enquirycurrentDate"

                            />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <label>Payment Type</label>
                            <br />
                            <select className="form-input-select"
    value={addEnquiry.enquiryplanDetails.enquiryadvanceAmount.enquirypaymentType || ""}
    onChange={handleAmountChange}
    name="enquirypaymentType"
>
    <option value="">Select Type</option>
    <option value="Private">Private</option>
    <option value="Technology">Technology</option>
    <option value="Cash">Cash</option>
    <option value="Helpdesk">Helpdesk</option>
    <option value="Others">Others</option>
</select>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <label>Amount</label>
                            <br />
                            <input className="form-input" placeholder="Rs"
                                value={addEnquiry.enquiryplanDetails.enquiryadvanceAmount.enquiryamount} 
                                
                                onChange={ handleAmountChange}
                                name="enquiryamount"

                            />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <label>Reference Number</label>
                            <br />
                            <input className="form-input" placeholder="Enter Reference Number"
                                value={addEnquiry.enquiryplanDetails.enquiryadvanceAmount.enquiryreferenceNumber} 
                                onChange={ handleAmountChange}
                                name="enquiryreferenceNumber"

                            />
                        </div>
                    </Col>
                </Row>

                <Row className="text-end mt-5">
                  <Col className="text-start">
                  <button className="black-btn " onClick={() => setIsOpenAdvancePayment(false)}>Cancel</button>
                  </Col>
                  <Col className="text-end">
                  <button className="blue-btn ms-5" onClick={() => setIsOpenAdvancePayment(false)}>Submit</button>
                  </Col>



                </Row>
            </Container>
        </div>
    </div>
)}




                                    {/* </select> */}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div className="button-group1">
                              <button
                                type="button"
                                className="black-btn"
                                onClick={() => setIsOpenplan(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="ok-btn1"
                                onClick={() => setIsOpenplan(false)}
                              >
                                OK
                              </button>
                            </div>
                          </Container>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
                <lable>Mail ID</lable>
                <br />
                <input
                 value={addEnquiry.enquiryemailId} onChange={handleChange}

                  type="email"
                  placeholder="Enter Email ID"
                  className="form-input "
                  name="enquiryemailId"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Next Follow Up Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      value={addEnquiry.enquirynextFollowUpDate} onChange={handleChange}

                      type="date"
                      name="enquirynextFollowUpDate"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Lead Status</lable>
                    <br />
                    <input style={{backgroundColor:"#fbdc6e",color:"rgb(94, 94, 14)"}}
                      type="button"
                      onClick={() => setIsOpenConverted(true)}
                      className="form-input converted-form"
                      name="enquiryleadStatus"
                      value="Open"

                    />
                      
             

                    {isOpenConverted && (
                      <div className="overlay1-converted">
                        <div className="modal1-converted">
                          <Container>
                            <button
                              className="close-btn1"
                              onClick={() => setIsOpenConverted(false)}
                            >
                              <span style={{ fontSize: "20px" }}>
                                {" "}
                                &times;{" "}
                              </span>
                            </button>

                            <div className="open-button border">
                              <h5>Open</h5>
                            </div>

                            <textarea
                              style={{
                                textTransform: "capitalize",
                                resize: "none",
                              }}
                              placeholder="Enter Notes"
                              className="converted-textbox"
                              name="enquirynotes"
                              onChange={handleLeadChange}

                              value={addEnquiry.enquiryleadStatus.enquirynotes}
                            />
                            <div className="upload-section">
                              <label className="upload-btn">
                                <FiUpload className="upload-icon" />
                                Upload Image
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}


                                  hidden
                                />
                              </label>
                              {selectedFile && (
                                <p className="file-name">{selectedFile.name}</p>
                              )}
                            </div>

                            <Row>
                              <Col className="date-update-textbox">
                                <label>Next Follow Up Date</label>
                                <br />
                                <input
                                  type="date"
                                  className="field form-input"
                                  value={addEnquiry.enquiryleadStatus.enquiryLeadStatusnextFollowUpDate} 
                                  onChange={handleLeadChange}
                                  name="enquiryLeadStatusnextFollowUpDate"

                                />
                              </Col>
                              <Col className="uploadbtn-install">
                                <button type="button" className="blue-btn"
                                 onClick={() => setIsOpenConverted(false)}>
                                  Upload
                                </button>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}