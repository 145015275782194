import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import { FaStarOfLife } from "react-icons/fa6";
import { Modal, Box, Button } from "@mui/material";
import { ImCross } from "react-icons/im";
import { FiX, FiUpload } from "react-icons/fi"; // Close & Upload icons
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect } from "react";

export default function AddDues() {
  const [selectedFile, setSelectedFile] = useState(null);

  const [isOpenAdvancePayment, setIsOpenAdvancePayment] = useState(false);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [isOpenplan, setIsOpenplan] = useState(false);
  const [isOpenConverted, setIsOpenConverted] = useState(false);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({}); // Validation errors

  return (
    <>
      <Topbar />
      <Container className="mt-1    ">
        <form>
          <Row>
            <Col>
              <h3>Dues</h3>
            </Col>

            <Col className="mx-5 text-end">
              <button
                className=" blue-btn  px-4 text-white"
                value="submit"
                type="submit"
              >
                Submit
              </button>
            </Col>
          </Row>

          <div className="border-container1">
            <h6 className="form-head  m-0 text-white  ">Dues Details</h6>
          </div>

          <div className="border-container ">
            <Row className="text-start lead-id-row  mb-4 mt-4 ms-4 ">
              <p className="ps-5 paragraph-lead-id">
                Due ID - <span className="text-primary "></span>{" "}
                <span className="lead-start-date">
                  {" "}
                  Due Open Last - <span className="text-primary"> </span>
                </span>{" "}
              </p>
            </Row>
            <Row className="ms-3">
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Name</lable>
                <br />
                <input
                  type="text"
                  style={{ textTransform: "capitalize " }}
                  className="form-input "
                  placeholder="Enter Custmer Name"
                  name="enquirycustomerName"
                />
              </Col>

              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>
                      Contact Number
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Enter Phone Number"
                      className="form-input"
                      name="enquirycontactNum"
                      maxLength={10}
                      required
                    />
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Alternate Number </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      name="enquiryalternateNum"
                      maxLength={10}
                      className="form-input"
                      placeholder="Enter Phone Number"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
              <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>
                      Contact Number
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Enter Phone Number"
                      className="form-input"
                      name="enquirycontactNum"
                      maxLength={10}
                      required
                    />
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Alternate Number </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      name="enquiryalternateNum"
                      maxLength={10}
                      className="form-input"
                      placeholder="Enter Phone Number"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Location</lable>
                    <br />
                    <input
                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      type="text"
                      placeholder="Enter Location"
                      name="enquirylocation"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Lead Source</lable>
                    <br />

                    <select
                      class="dropbtn form-input-select"
                      name="enquiryleadSource"
                    >
                      <option value="">Select</option>
                      <option className="onlineback" value="Online">
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>
                </Row>
               
              </Col>
            </Row>
            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
             
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Next Follow Up Date</lable>
                    <br />
                    <input
                      className="field form-input"
                     
                      type="date"
                      name="enquirynextFollowUpDate"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Lead Status</lable>
                    <br />
                    <input
                      style={{
                        backgroundColor: "#fbdc6e",
                        color: "rgb(94, 94, 14)",
                      }}
                      type="button"
                      onClick={() => setIsOpenConverted(true)}
                      className="form-input converted-form"
                      name="enquiryleadStatus"
                      value="Open"
                    />

                    {isOpenConverted && (
                      <div className="overlay1-converted">
                        <div className="modal1-converted">
                          <Container>
                            <button
                              className="close-btn1"
                              onClick={() => setIsOpenConverted(false)}
                            >
                              <span style={{ fontSize: "20px" }}>
                                {" "}
                                &times;{" "}
                              </span>
                            </button>

                            <div className="open-button border">
                              <h5>Open</h5>
                            </div>

                            <textarea
                              style={{
                                textTransform: "capitalize",
                                resize: "none",
                              }}
                              placeholder="Enter Notes"
                              className="converted-textbox"
                              name="enquirynotes"
                           
                            />
                            <div className="upload-section">
                              <label className="upload-btn">
                                <FiUpload className="upload-icon" />
                                Upload Image
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                  hidden
                                />
                              </label>
                              {selectedFile && (
                                <p className="file-name">{selectedFile.name}</p>
                              )}
                            </div>

                            <Row>
                              <Col className="date-update-textbox">
                                <label>Next Follow Up Date</label>
                                <br />
                                <input
                                  type="date"
                                  className="field form-input"
                                  
                                  name="enquiryLeadStatusnextFollowUpDate"
                                />
                              </Col>
                              <Col className="uploadbtn-install">
                                <button
                                  type="button"
                                  className="blue-btn"
                                  onClick={() => setIsOpenConverted(false)}
                                >
                                  Upload
                                </button>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}
