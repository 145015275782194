import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Add_Customer.css";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaStarOfLife } from "react-icons/fa6";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import * as Yup from "yup";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";

export default function Edit_Customer() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const locate = useLocation();
  const [customerDetails, setCustomerDetails] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [errorrefund, setErrorRefund] = useState("");
  const [errorinstalltioncharge, setErrorinstallationcharge] = useState("");


  const [errorphone, setErrorphone] = useState("");

  useEffect(() => {
    axios
      .post(apiUrl+"getdata", {
        customerid: locate.state?.customerid,
      })
      .then((res) => {
        setCustomerDetails(res.data.message);
        setOriginalData(res.data.message); // Store original data for comparison
      });
  }, [locate.state?.customerid]);

  const handleChange = (e) => {
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: e.target.value,
    });
  };


  //__________________________________________________________

  
  

  // const validatePhone = (value) => {
  //   const phoneRegex = /^[6-9]\d{9}$/; // Ensures it starts with 6-9 and is 10 digits long
  //   if (!phoneRegex.test(value)) {
  //     setErrorphone("Invalid phone number. Must be 10 digits and start with 6-9.");
  //   } else {
  //     setErrorphone("");
  //   }
  // };

  const [errorEmail, setErrorEmail] = useState("");
  const handleEmailBlur = () => {
    if (customerDetails.emailid.trim() !== "") {
      if (!validateEmail(customerDetails.emailid)) {
        setErrorEmail("Please enter a valid email address (e.g., example@domain.com).");
      } else {
        setErrorEmail("");
      }
    } else {
      setErrorEmail(""); // Clear the error if the field is empty
    }
  };
  
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu)$/i;
    return emailRegex.test(email);
  };


  const handlePhoneNumberBlur = () => {
    const regex = /^[0-9]{10}$/;
    if (!regex.test(customerDetails.contactnum || "")) {
      setError("Please enter a valid 10-digit phone number");
    } else {
      setError("");
    }
  };

  const handlePhoneNumberBlur1 = () => {
    const regex1 = /^[0-9]{10}$/;
    if (!regex1.test(customerDetails.alternatenum || "")) {
      setError1("Please enter a valid 10-digit phone number");
    } else {
      setError1("");
    }
  };

  const [value, setValue] = useState("");

  const handledecimal = (e) => {
    const inputValue = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const [value1, setValue1] = useState("");

  const handledecimal1 = (e) => {
    const inputValue1 = e.target.value;
    const regex1 = /^\d*\.?\d{0,2}$/;
    if (regex1.test(inputValue1)) {
      setValue1(inputValue1);
    }
  };

  const handlerefunddeposit = (e) => {
    setCustomerDetails({ ...customerDetails, refunddeposit: e.target.value });
    handledecimal(e);
  };

  const handleintallationcharge = (e) => {
    setCustomerDetails({
      ...customerDetails,
      installationcharge: e.target.value,
    });
    handledecimal1(e);
  };

  const navigate = useNavigate();

  const updateCustomerData = async (e) => {
    e.preventDefault();
  
    // Validate phone numbers
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(customerDetails.contactnum || "")) {
      setError("Please enter a valid 10-digit phone number");
      return;
    }
  
    // Validate decimal inputs (installation charge and refund deposit)
    const decimalRegex = /^\d*\.?\d{0,2}$/;
  
    if (!decimalRegex.test(customerDetails.installationcharge || "")) {
      setErrorinstallationcharge(
        "Please enter a valid installation charge (up to 2 decimal places)"
      );
      return;
    }
  
    if (!decimalRegex.test(customerDetails.refunddeposit || "")) {
      setErrorRefund(
        "Please enter a valid refund deposit (up to 2 decimal places)"
      );
      return;
    }
  
    // Validate email
    if (customerDetails.emailid && !validateEmail(customerDetails.emailid)) {
      setErrorEmail("Invalid email format. Must end with .com, .org, .net, or .edu");
      return;
    } else {
      setErrorEmail(""); // Clear error if validation passes
    }
    if (customerDetails.emailid && !validateEmail(customerDetails.emailid)) {
      setErrorEmail("Invalid email format. Must end with .com, .org, .net, or .edu");
     
      return; // Prevent submission if email is invalid
    } else {
      setErrorEmail(""); // Clear error if validation passes
    }
    // Check if any field has changed
    const isDataChanged = Object.keys(originalData).some(
      (key) => customerDetails[key] !== originalData[key]
    );
  
    if (!isDataChanged) {
      Swal.fire({
        title: "<strong>No Changes Made </strong>",
        icon: "info",
        showCloseButton: true,
        focusConfirm: false,
      });
      return;
    }
  
    // Show confirmation popup before saving
    const result = await Swal.fire({
      title: "Do you want to save the changes?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Update",
      // denyButtonText: `Don't save`,
    });
  
    if (result.isConfirmed) {
      try {
        const response = await axios.post(apiUrl + "update", customerDetails);
  
        Swal.fire({
          title: "Customer Details Updated Successfully!..",
          icon: "success",
          draggable: true,
        }).then(() => {
          navigate("/customersection");
        });
  
        console.log(response.data);
      } catch (error) {
        console.error("Error submitting data:", error);
        Swal.fire({
          title: "Error updating customer details",
          text: "Please try again later",
          icon: "error",
        });
      }
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  };
  

  return (
    <>

    <Topbar/>
      <Container className="mt-1  margin-right ">
        <form onSubmit={updateCustomerData}>
          <Row>
            <Col>
              <h3>Customer</h3>
            </Col>

            <Col className="mx-5 text-end">
              <button
                className=" blue-btn  px-4 text-white"
                value="submit"
                type="submit"
              >
                Update
              </button>
            </Col>
          </Row>

          <div className="border-container1">
            <h6 className="form-head  m-0 text-white  ">Customer Details</h6>
          </div>

          <div className="border-container">
            <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12} md={6} className="text-start">
                    <lable sm={12} className="rowdown ">
                      Customer Id
                    </lable>
                    <br />
                    <input
                      sm={12}
                      type="text"
                      name="customerid"
                      value={customerDetails.customerid || ""}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Enter Customer Id"
                      readOnly
                    />
                  </Col>

                  <Col sm={12} md={6} className="text-start">
                    <lable className="rowdown"> Plan Name</lable>
                    <br/>
                    <input
                      type="text"
                      name="planname"
                      value={customerDetails.planname || ""}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Enter Plan Name"
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Name</lable>
                <br />
                <input
                  type="text"
                  name="customername"
                  value={customerDetails.customername || ""}
                  onChange={handleChange}
                  style={{ textTransform: "capitalize " }}
                  className="form-input inputfield "
                  placeholder="Enter Customer Name"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Mail Id</lable>
                    <br />
                    <input
                      type="email"
                      name="emailid"
                      value={customerDetails.emailid || ""}
                      onChange={handleChange}
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      className="form-input"
                      placeholder="Enter Mail Id"
                      // onBlur={validateEmail}
                      onBlur={handleEmailBlur}
                    />
                    {errorEmail && <p style={{ color: "red" }}>{errorEmail}</p>}
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Area</lable>
                    <br />
                    <input
                      type="text"
                      name="area"
                      value={customerDetails.area || ""}
                      onChange={handleChange}
                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      placeholder="Enter Area"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>
                      Contact Number{" "}
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      type="tel"
                      maxlength="10"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      onBlur={handlePhoneNumberBlur}
                      name="contactnum"
                      value={customerDetails.contactnum || ""}
                      onChange={handleChange}
                      required
                      placeholder="Enter Phone Number"
                      className="form-input"
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Alternate Number </lable>
                    <br />
                    <input
                      type="tel"
                      maxlength="10"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      onBlur={handlePhoneNumberBlur1}
                      name="alternatenum"
                      value={customerDetails.alternatenum || ""}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Enter Phone Number"
                    />
                    {error1 && <p style={{ color: "red" }}>{error1}</p>}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="text-start">
                <lable>Installation Address</lable>
                <br />
                <textarea
                  type="text"
                  name="installationadd"
                  value={customerDetails.installationadd || ""}
                  onChange={handleChange}
                  style={{ textTransform: "capitalize", resize: "none" }}
                  className=" box inputfield"
                  placeholder="Enter Installation Address"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Account Manager</lable>
                    <br />
                    <input
                      name="accountmanager"
                      value={customerDetails.accountmanager || ""}
                      onChange={handleChange}
                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      type="text"
                      placeholder="Enter Account Manager"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Activation Date</lable>
                    <br />
                    <input
                      name="activationdate"
                      value={customerDetails.activationdate || ""}
                      onChange={handleChange}
                      className="field form-input"
                      type="date"
                      placeholder="Enter Activation Date"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Expiry Date</lable>
                    <br />

                    <input
                      className="field form-input"
                      name="expirydate"
                      value={customerDetails.expirydate || ""}
                      onChange={handleChange}
                      type="date"
                      placeholder="Enter"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Last Renewal Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      name="lastrenewaldate"
                      value={customerDetails.lastrenewaldate || ""}
                      onChange={handleChange}
                      type="date"
                      placeholder="Enter"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="text-start">
                <lable>Billing Address</lable>
                <br />
                <textarea
                  style={{ textTransform: "capitalize", resize: "none" }}
                  name="billingadd"
                  value={customerDetails.billingadd || ""}
                  onChange={handleChange}
                  type="text"
                  className="box inputfield"
                  placeholder="Enter Billing Address"
                  
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Last Logoff Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      name="lastlogoffdate"
                      value={customerDetails.lastlogoffdate || ""}
                      onChange={handleChange}
                      type="date"
                      placeholder="Enter "
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Installation Charges</lable>
                    <br />
                    <input
                      name="installationcharge"
                      onBlur={handleintallationcharge}
                      value={customerDetails.installationcharge || ""}
                      onChange={handleChange}
                      className="form-input"
                      type="tel"
                      placeholder="Enter Installation Charge"
                    
                    //   onKeyDown={(e) => {
                    //     if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                    //      e.preventDefault();
                    //    }
                    //  }}
                      
                      
                    />
                    {errorinstalltioncharge && (
                      <p style={{ color: "red" }}>{errorinstalltioncharge}</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Refund Deposit</lable>
                    <br />
                    <input
                      name="refunddeposit"
                      onBlur={handlerefunddeposit}
                      value={customerDetails.refunddeposit || ""}
                      onChange={handleChange}
                      type="tel"
                    //   onKeyDown={(e) => {
                    //     if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                    //      e.preventDefault();
                    //    }
                    //  }}
                      className="form-input"
                      placeholder="Enter Refund deposit"
                     
                    />
                    {errorrefund && (
                      <p style={{ color: "red" }}>{errorrefund}</p>
                    )}
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Refund Description</lable>
                    <br />
                    <input
                      style={{ textTransform: "capitalize" }}
                      name="refunddes"
                      value={customerDetails.refunddes || ""}
                      onChange={handleChange}
                      className="form-input"
                      type="text"
                      placeholder="Enter Refund description"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Account Status</lable>
                    <br />

                    <select
                      name="accountstatus"
                      value={customerDetails.accountstatus || ""}
                      onChange={handleChange}
                      class=" dropbtn form-input-select "
                    >
                      <option value="">Select</option>
                      <option className="activeback " value="Active">
                        Active
                      </option>
                      <option className="inactiveback" value="Inactive">
                        Inactive
                      </option>
                      <option className="suspendedback" value="Suspended">
                        Suspended
                      </option>

                      <option className="disableback" value="Disable">
                        Disable
                      </option>
                    </select>
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Device Status </lable>
                    <br />

                    <select
                      name="devicestatus"
                      value={customerDetails.devicestatus || ""}
                      onChange={handleChange}
                      class="dropbtn form-input-select"
                    >
                      <option value="">Select</option>
                      <option
                        className="onlineback"
                        style={{ background: "#fee792" }}
                        value="Online"
                      >
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}