import React, { useState } from "react";
import "./Newpassword.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import Swal from "sweetalert2";
import axios from "axios";

const Newpassword = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const location = new useLocation();
  const [email, setemail] = useState(location.state?.email);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleShow1 = () => setShow1(!show1);
  const handleShow2 = () => setShow2(!show2);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (newPassword.length < 6) {
      setMessage("Password must be at least 6 characters long.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      await axios.post(apiUrl + "reset-password", {
        email: email,
        newPassword: newPassword,
      });

      Swal.fire({
        title: "Password Reset Successful!",
        text: "You can now log in with your new password.",
        icon: "success",
        confirmButtonText: "Okay",
      });

      navigate("/");
    } catch (error) {
      setMessage(error.response?.data?.message || "Failed to reset password");

      Swal.fire({
        title: "Error",
        text: error.response?.data?.message || "Failed to reset password",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  return (
    <div className="container-fluid n-password">
      <div className="n-password-col">
        <div className="row d-flex">
          <div>
            <IoArrowBackCircleSharp
              onClick={() => navigate("/password")}
              className="backward"
            />
          </div>

          <div>
            <h1 className="n-password-txt  ">Create New Password</h1>
          </div>
        </div>
        <Row>
          <form onSubmit={handleSubmit} className="n-password-form">
            <Col sm={12}>
              <label>
                <b>New Password</b>
              </label>
            </Col>
            <Col sm={12}>
              <input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={show1 ? "text" : "password"}
                placeholder="Enter your New-Password"
              />
              <label onClick={handleShow1} className="show-hide-1">
                {show1 ? <BiSolidHide /> : <BiSolidShow />}
              </label>
            </Col>

            <Col sm={12}>
              <label>
                <b>Confirm Password</b>
              </label>
            </Col>
            <Col sm={12}>
              <input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={show2 ? "text" : "password"}
                placeholder="Enter your Confirm-Password"
              />
              <label onClick={handleShow2} className="show-hide-2">
                {show2 ? <BiSolidHide /> : <BiSolidShow />}
              </label>
            </Col>

            <button className="continue-btn" type="submit">
              Save
            </button>
          </form>
          <p>{message}</p>
        </Row>
      </div>
    </div>
  );
};

export default Newpassword;
