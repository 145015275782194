import React, { useEffect, useState } from "react";
import "./Staff.css";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";
import { CiEdit } from "react-icons/ci";

const Staff = (staffId, onStaffDeleteSuccess) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const apiUrlImage = process.env.REACT_APP_IMAGE_API_BASE_URL;
  const [staffData, setstaffData] = useState([]);
  const [staffdelete, setStaffDelete] = useState(false);
  const [filterRole, setfilterrole] = useState([]);
  const [filterName, setfilterName] = useState([]);

  const navigate = useNavigate();
  const addNew = () => {
    navigate("/addstaff");
  };

  const [setting, setSetting] = useState(false);
  const settingPopup = (e) => {
    e.preventDefault();
    setSetting(!setting);
  };

  const [filtersStaff, setFiltersStaff] = useState({
    staffID: "",
    doj: "",
    designation: "",
    staffName: "",
    contactNumber: "",
    alternativeNumber1: "",
    alternativeNumber2: "",
    email: "",
    address: "",
    password: "",
    bankAccountNumber: "",
    ifscCode: "",
  });
  const [columnVisibilityStaff, setColumnVisibilityStaff] = useState({
    staffID: true,
    doj: true,
    designation: true,
    staffName: true,
    contactNumber: true,
    alternativeNumber1: true,
    alternativeNumber2: true,
    email: true,
    address: true,
    password: true,
    bankAccountNumber: true,
    ifscCode: true,
    educationCertificate: true,
    actionStatus: true,
    drivingLicence: true,
    aadhaarPhoto: true,
    staffPhoto: true,
    delete: true,
  });

  const handleColumnVisibilityChangeStaff = (e) => {
    const { name, checked } = e.target;
    setColumnVisibilityStaff((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  useEffect(() => {
    axios
      .post(
        apiUrl + "viewstaff",

        {}
      )
      .then((res) => {
        setstaffData(res.data.message);

        console.log(res.data.message);
        setfilterName(res.data.message);
      });
  }, [staffdelete]);

  const staffHandleDelete = async (id) => {
    // Show confirmation popup
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        // API Call to delete user
        await axios.delete(apiUrl + `staffdelete?staffId=${id}`);

        // Success message
        await Swal.fire("Deleted!", "Deleted Successfully.", "success");
        setStaffDelete(!staffdelete);
      } catch (error) {
        // Error handling
        Swal.fire("Error!", "Something went wrong while deleting.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  const gotoStaffedit = (staffId) => {
    navigate(`/addstaff?staffId=${staffId}`);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFiltersStaff((prevFilters) => ({
      ...prevFilters,
      [name]: value.toLowerCase(), // Normalize input for case-insensitive search
    }));
  };

  const filteredData = staffData.filter((staff) => {
    return Object.keys(filtersStaff).every((key) => {
      if (!filtersStaff[key]) return true; // Skip empty filters
      return String(staff[key]).toLowerCase().includes(filtersStaff[key]);
    });
  });

  //destination dropdown ===map from roles

  useEffect(() => {
    axios
      .post(apiUrl + "viewrole", {})
      .then((res) => {
        setfilterrole(res.data.message);
        console.log(res.data.message);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  let changedataStaff = (data) => {
    if (data == "staffID") {
      return "  Staff Id";
    }
    if (data == "doj") {
      return " Date Of Joining";
    }
    if (data == "designation") {
      return " Designation";
    }
    if (data == "staffName") {
      return " Staff Name";
    }
    if (data == "contactNumber") {
      return " Contact Number";
    }

    if (data == "alternativeNumber1") {
      return " Alternativ Number 1";
    }
    if (data == "alternativeNumber2") {
      return " Alternate Number 2";
    }
    if (data == "email") {
      return " Email ID ";
    }
    if (data == "address") {
      return " Address";
    }
    if (data == "password") {
      return " Password";
    }

    if (data == "bankAccountNumber") {
      return " Bank Account Number";
    }
    if (data == "ifscCode") {
      return " IFSC Code";
    }
    if (data == "educationCertificate") {
      return " Education Certificate";
    }
    if (data == "drivingLicence") {
      return " Driving Licence";
    }

    if (data == "aadhaarPhoto") {
      return " Aadhaar Photo";
    }
    if (data == "actionStatus") {
      return " Action Status";
    }
    if (data == "staffPhoto") {
      return " Staff Photo";
    }

    if (data == "delete") {
      return " Delete";
    } else {
      return data;
    }
  };

  
  return (
    <div>
      <Topbar />

      <Container fluid className="staff-margin-left ">
        <Row>
          <Col>
            <h2 className="staff mt-2">Staff</h2>
            <Row className="row-btn mt-5">
              <Col className="row-btn1" sm={12} md={3}>
                <button onClick={addNew} className="black-btn">
                  Add new
                </button>

                <button
                  onClick={() => window.location.reload()}
                  className="black-btn"
                >
                  Refresh{" "}
                </button>
              </Col>

              <Col sm={12} md={3}></Col>
              <Col sm={12} md={3}></Col>

              <Col className="row-btn2 " sm={12} md={3}>
                <button className="black-btn">Export</button>

                <button onClick={settingPopup} className="black-btn">
                  Settings
                </button>
                {setting && (
                  <div className="setting-popup-overlay">
                    <div className="setting-popup-container">
                      <button
                        className="setting-close-btn"
                        onClick={settingPopup}
                      >
                        &times;
                      </button>
                      <div className="setting-popup-content">
                        <div className="setting-checkbox-row">
                          <label>
                            <input
                              className="checkbox-customer"
                              type="checkbox"
                              checked={Object.values(
                                columnVisibilityStaff
                              ).every((v) => v)}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setColumnVisibilityStaff(
                                  Object.keys(columnVisibilityStaff).reduce(
                                    (acc, key) => {
                                      acc[key] = isChecked;
                                      return acc;
                                    },
                                    {}
                                  )
                                );
                              }}
                            />
                            <span
                              className="checkbox-down ms-1"
                              style={{ fontSize: "17px" }}
                            >
                              {Object.values(columnVisibilityStaff).every(
                                (v) => v
                              )
                                ? "Unselect All"
                                : "Select All"}
                            </span>
                          </label>
                        </div>
                        <div className="setting-checkbox-grid">
                          {Object.keys(columnVisibilityStaff).map((key) => (
                            <label key={key} className="label-setting-popup">
                              <input
                                type="checkbox"
                                name={key}
                                checked={columnVisibilityStaff[key]}
                                onChange={handleColumnVisibilityChangeStaff}
                                className="checbox-popup"
                              />
                              <span className="checkbox-down1">
                                {changedataStaff(key)}
                              </span>
                            </label>
                          ))}
                        </div>
                        <button
                          className="setting-submit-btn"
                          onClick={settingPopup}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <br></br>

            <div class="table-container ">
              <table className="tabel  table-td">
                <thead className="table-td">
                  <tr className="table-th text-center ">
                    {columnVisibilityStaff.staffPhoto && (
                      <th className=" table-th table-long-head">Photos</th>
                    )}
                    {columnVisibilityStaff.staffID && (
                      <th className=" table-th table-long-head ">Staff ID</th>
                    )}
                    {columnVisibilityStaff.doj && (
                      <th className=" table-th table-long-head">
                        Date Of Joining
                      </th>
                    )}
                    {columnVisibilityStaff.designation && (
                      <th className=" table-th table-long-head">Designation</th>
                    )}
                    {columnVisibilityStaff.staffName && (
                      <th className=" table-th table-long-head">Staff Name</th>
                    )}
                    {columnVisibilityStaff.contactNumber && (
                      <th className=" table-th table-long-head">
                        Contact Number
                      </th>
                    )}
                    {columnVisibilityStaff.alternativeNumber1 && (
                      <th className=" table-th table-long-head">
                        Alternate Number 1
                      </th>
                    )}
                    {columnVisibilityStaff.alternativeNumber2 && (
                      <th className=" table-th table-long-head">
                        Alternate Number 2
                      </th>
                    )}
                    {columnVisibilityStaff.email && (
                      <th className=" table-th table-long-head">Mail ID</th>
                    )}
                    {columnVisibilityStaff.password && (
                      <th className=" table-th table-long-head">Password</th>
                    )}
                    {columnVisibilityStaff.address && (
                      <th className=" table-th table-long-head">Address</th>
                    )}
                    {columnVisibilityStaff.aadhaarPhoto && (
                      <th className=" table-th table-long-head ">
                        Aadhaar Photo
                      </th>
                    )}
                    {columnVisibilityStaff.educationCertificate && (
                      <th className=" table-th table-long-head">
                        Education Certificate
                      </th>
                    )}
                    {columnVisibilityStaff.drivingLicence && (
                      <th className=" table-th table-long-head">
                        Driving Licence
                      </th>
                    )}
                    {columnVisibilityStaff.bankAccountNumber && (
                      <th className=" table-th table-long-head">
                        Bank Account Number
                      </th>
                    )}
                    {columnVisibilityStaff.ifscCode && (
                      <th className=" table-th table-long-head">IFSC Code</th>
                    )}
 
                      {columnVisibilityStaff.actionStatus && (
                      <th className=" table-th table-long-head">Action Status</th>
 
                    )}
                    {columnVisibilityStaff.delete && (
                      <th className=" table-th table-long-head">Delete</th>
                    )}
                  </tr>
                </thead>
                <tbody className="tr:nth-child(even)">
                  <tr className="search-adjust">
                    {columnVisibilityStaff.staffPhoto && <td></td>}
                    {columnVisibilityStaff.staffID && (
                      <td>
                        <input
                          type="text"
                          className="bg-white table-search table-input-search text-center"
                          placeholder="Search ID"
                          name="staffId"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.doj && (
                      <td>
                        <input
                          type="date"
                          class="filter-input bg-white table-search table-input-search"
                          name="doj"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.designation && (
                      <td>
                        <select
                          class="filter-select bg-white table-search table-input-search "
                          name="designation"
                          onChange={handleFilterChange}
                        >
                          <option value="">Select</option>
 
                          {filterRole.map((role, i) => {
                            return (
                              <option value={role.role}>{role.role}</option>
                            );
                          })}
 
                        </select>
                      </td>
                    )}
                    {columnVisibilityStaff.staffName && (
                      <td>
                        <select
                          class="filter-select bg-white table-search table-input-search"
                          name="staffName"
                          onChange={handleFilterChange}
                        >
                          <option value="">Select</option>
                          {filterName.map((filtername, i) => {
                            return (
                              <option value="Fiber Lead">
                                {filtername.staffName}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    )}
                    {columnVisibilityStaff.contactNumber && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search td-input-spc"
                          placeholder="Search"
                          name="contactNumber"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.alternativeNumber1 && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search"
                          placeholder="Search"
                          name="alternativeNumber1"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.alternativeNumber2 && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search"
                          placeholder="Search"
                          name="alternativeNumber2"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.email && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search"
                          placeholder="Search"
                          name="email"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.password && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search"
                          placeholder="Search"
                          name="password"
                          onChange={handleFilterChange}
                        />
                      </td>
                       )}
                    {columnVisibilityStaff.address && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search"
                          placeholder="Search"
                          name="address"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.aadhaarPhoto && <td></td>}
                    {columnVisibilityStaff.educationCertificate && <td></td>}

                    {columnVisibilityStaff.drivingLicence && <td></td>}
                    {columnVisibilityStaff.bankAccountNumber && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search"
                          placeholder="Search"
                          name="bankAccountNumber"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.ifscCode && (
                      <td>
                        <input
                          type="text"
                          class="filter-input bg-white table-search table-input-search"
                          placeholder="Search"
                          name="ifsc"
                          onChange={handleFilterChange}
                        />
                      </td>
                    )}
                    {columnVisibilityStaff.delete && <td></td>}
                  </tr>
                  {filteredData.length === 0 ? (
    <tr>
      <td colSpan="100%" className="text-center text-muted p-3">
        No data found
      </td>
    </tr>
  ) : (
                  filteredData.map((staff, i) => (
                    <tr>
                      {columnVisibilityStaff.staffPhoto && (
                        <td >
                          <img
                            alt="logo"
                            // src={apiUrlImage+`${staff?.staffPhoto?.fileName}`}
                            src={
                              staff?.staffPhoto?.fileName
                                ? apiUrlImage + staff?.staffPhoto?.fileName
                                : apiUrlImage + "default.jpg"
                            }
                            width={50}
                            height={50}
                            style={{ borderRadius: "50%", padding: "5px" }}
                          />
                        </td>
                      )}
                      {columnVisibilityStaff.staffID && (
                        <td className="p-3"
                          onClick={() => gotoStaffedit(staff._id)}
                          
                        >
                          {staff.staffId} <CiEdit />
                        </td>
                      )}
                      {columnVisibilityStaff.doj && (
                        <td  className="p-3">{staff.doj}</td>
                      )}
                      {columnVisibilityStaff.designation && (
                        <td  className="p-3" >{staff.designation}</td>
                      )}
                      {columnVisibilityStaff.staffName && (
                        <td  className="p-3" >{staff.staffName}</td>
                      )}
                      {columnVisibilityStaff.contactNumber && (
                        <td  className="p-3">{staff.contactNumber}</td>
                      )}
                      {columnVisibilityStaff.alternativeNumber1 && (
                        <td  className="p-3">{staff.alternativeNumber1}</td>
                      )}
                      {columnVisibilityStaff.alternativeNumber2 && (
                        <td  className="p-3">{staff.alternativeNumber2}</td>
                      )}
                      {columnVisibilityStaff.email && (
                        <td className="p-3" >{staff.email}</td>
                      )}
                      {columnVisibilityStaff.password && (
                        <td  className="p-3" >{staff.password}</td>
                      )}
                      {columnVisibilityStaff.address && (
                        <td  className="p-3" >{staff.address}</td>
                      )}
                      {columnVisibilityStaff.aadhaarPhoto && (
                        <td className="text">
                          <span
                            className={
                              staff?.aadhaarPhoto ? "uploaded" : "pending"
                            }
                          >
                            {staff?.aadhaarPhoto ? "Uploaded" : "Pending"}
                          </span>
                        </td>
                      )}
                      {columnVisibilityStaff.educationCertificate && (
                        <td className="text" >
                          <span
                            className={
                              staff?.educationCertificate
                                ? "uploaded"
                                : "pending"
                            }
                          >
                            {staff?.educationCertificate
                              ? "Uploaded"
                              : "Pending"}
                          </span>
                        </td>
                      )}
                      {columnVisibilityStaff.drivingLicence && (
                        <td className="text" >
                          <span
                            className={
                              staff?.drivingLicence ? "uploaded" : "pending"
                            }
                          >
                            {staff?.drivingLicence ? "Uploaded" : "Pending"}
                          </span>
                        </td>
                      )}
                      {columnVisibilityStaff.bankAccountNumber && (
                        <td  className="p-3" >{staff.bankAccountNumber}</td>
                      )}
                      {columnVisibilityStaff.ifscCode && (
                        <td  className="p-3" >{staff.ifsc}</td>
                      )}
                      {columnVisibilityStaff.actionStatus && (
                        <td  className="p-3" >{staff.actionStatus}</td>
                      )}
                      {columnVisibilityStaff.delete && (
                        <td  >
                          <button
                            onClick={() => staffHandleDelete(staff._id)}
                            className="delete-btn"
                          >
                            <MdDelete size={20} />
                          </button>{" "}
                        </td>
                      )}
                    </tr>
                  ))
                )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Staff;
