export const SET_STAFFID = 'SET_STAFFID';
export const SET_STAFFNAME = 'SET_STAFFNAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_DESIGNATION = 'SET_DESIGNATION';
// export const SET_AGE = 'SET_AGE';
// export const SET_PHONE = 'SET_PHONE';


export const setStaffid = (staffid) => ({
  type: SET_STAFFID,
  payload: staffid,
});
export const setStaffname = (staffname) => ({
  type: SET_STAFFNAME,
  payload: staffname,
});
export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email,
});
export const setDesignation =  (designation) => ({
  type: SET_DESIGNATION,
  payload: designation,
});
// export const setage = (age) => ({
//     type: SET_AGE,
//     payload: age,
//   });
  
//   export const setphone = (phone) => ({
//     type: SET_PHONE,
//     payload: phone,
//   });
  