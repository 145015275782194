import React, { useState } from "react";
import RolesList from "./RolesList";
import AccessTable from "./AccessTable";
import axios from 'axios'
import Topbar from "../Topbar/Topbar";
import"./AdminPanel.css"
import { Container,Row,Col } from "react-bootstrap";

const AdminPanel = () => {
  
  const [selectedRole, setSelectedRole] = useState(null);
  const handleRoleSelect =async  (role) => {
    if (selectedRole === role) return; // Prevent unnecessary re-renders
    setSelectedRole(role);
    
   
    
    // Update the selected role
  };

  return (
    <Container>
    <Topbar/>
    <Row>
    <div >
    
      <Col md={3} sm={3} xs={12} className="admin-container"  >
      
      {/* Sidebar for Roles */}
      <RolesList onSelectRole={handleRoleSelect} />
      </Col>
<Col md={9} sm={9} xs={12} className="table-container-accesstable">
      {/* Table Component - Only One Instance (Dynamic Updates) */}
      <div >
        {selectedRole &&
          <AccessTable key={selectedRole} roles={selectedRole} />
         
}
      </div>
      </Col>
    </div>
    </Row>
    </Container>
  );
};


export default AdminPanel;
