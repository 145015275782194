import React from "react";
import Topbar from "../Topbar/Topbar";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import './Complaint.css'
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from 'dayjs';

export default function Complaint() {
  const [complaintdata, setcomplaintdata] = useState([]);
  const [complaintDelete,setComplaintDelete]=useState("")

  const navigate=useNavigate();

  const [filters, setFilters] = useState({
    complaintId: "",
    complaintStatus: "",
    complaintStartDate: "",
    complaincustomerAffected: "",
    complaincontactNumber: "",
    complainAddress: "",
    complainLocation: "",
    complainNotes: "",
    complainVerificationStatus: "",
    complainWorkAssign: "",
    complainWorkStatus: "",
    complainFeedback: "",
    complainAdditionalCharge: "",
    complainPayment: "",
  });

  const gotoviewcomplaints=(e)=>
    {
      e.preventDefault();
      navigate('/view_complaint')
    }
  

  const gotoAddComplaints=(e)=>
  {
    e.preventDefault();
    navigate('/add_complaint')
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value.toLowerCase(), // Normalize input for case-insensitive search
    }));
  };

  const filteredData = complaintdata.filter((customer) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true; // Skip empty filters
      return String(customer[key]).toLowerCase().includes(filters[key]);
    });
  });


  const formatDate = (date) => {
    return dayjs(date).format('DD-MM-YYYY');
  };
  

const handleDelete = async (leadId) => {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (result.isConfirmed) {
        try {
          // API Call to delete user
          await axios.post("http://localhost:3001/api/delete-complaint", {
            leadId:leadId
            
          });
  
          // Success message
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setComplaintDelete(true);
  
          // if (onDeleteSuccess) {
          //   onDeleteSuccess();
          // }
        } catch (error) {
          // Error handling
          Swal.fire("Error!", "Something went wrong while deleting.", "error");
          console.error("Delete Error:", error);
        }
      }
    };



    useEffect(() => {
          axios.post("http://localhost:3001/api/view-complaint", {}).then((res) => {
            const add_installation = res.data.message || [];
            setcomplaintdata(add_installation);
      
            // const counts = {
            //   active: customers.filter((c) => c.accountstatus === "Active").length,
            //   inactive: customers.filter((c) => c.accountstatus === "Inactive")
            //     .length,
            //   suspended: customers.filter((c) => c.accountstatus === "Suspended")
            //     .length,
            //   disabled: customers.filter((c) => c.accountstatus === "Disable").length,
            //   online: customers.filter((c) => c.devicestatus === "Online").length,
            //   offline: customers.filter((c) => c.devicestatus === "Offline").length,
            // };
            // setStatusCounts(counts);
          });
        }, [complaintDelete]);

  return (
    <div>
      <Topbar />
      <Container fluid className=" pt-1 customer-row ">
        <h3 className=" px-2  ">Complaint</h3>

        <Row className=" row-width">
          <Col className="dashborder mt-4 mb-5 mx-2">
            <Row className="yellow-card">
              <p className="text-center box-txt " style={{ marginTop: "8px" }}>
                Open
              </p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">05</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="purple-card">
              <p className="text-center pt-3 box-txt">Field Team Working</p>
            </Row>
            <Row className="number">
              <h5 className="text-center">0</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5  mx-1">
            <Row className="orange-card ">
              <p className="text-center pt-1 box-txt">Network Team Working</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">05</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="blue-card row-margin">
              <p className="text-center pt-1 box-txt">Pending</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">05</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="pink-card">
              <p className="text-center pt-1 box-txt">Completed</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">05</h5>
            </Row>
          </Col>
        </Row>

        <Row className=" button-bottom-custmer" xs={1} sm={2}>
          <Col className="mt-2 mb-2">
            <button onClick={gotoAddComplaints} className="black-btn btnadd  black-btn1">Add</button>{" "}
            &emsp;&emsp;
            <button
              onClick={() => window.location.reload()}
              className="black-btn black-btn1"
            >
              Refresh
            </button>
          </Col>

          <Col className="mt-2 text-end">
            <button className="black-btn black-btn1 btnadd">Export</button>{" "}
            &emsp;&emsp;
            <button className="black-btn black-btn1 ">Settings</button>
            {/* {setting && (
              <div className="setting-popup-overlay">
                <div className="setting-popup-container">
                  <button className="setting-close-btn" onClick={settingPopup}>
                    &times;
                  </button>
                  <div className="setting-popup-content">
                    <div className="setting-checkbox-row">
                      <label>
                        <input type="checkbox"  />
                        <span  className="checkbox-down"style={{ fontSize: "25" }}>Select All</span>
                      
                      </label>
                    </div>
                    <div className="setting-checkbox-grid">
                      {[
                        "Customer ID",
                        "Expiry Date",
                        "Customer Name",
                        "Device Status",
                        " Installation Address",
                        "Contact Number",
                        "Alternate Number",
                        "Mail ID",
                        "Account Manager",
                        "Refund Deposit",
                        "Activation Date",
                        "Last Renewal Date",
                        "Last Logoff Date",
                        "Billing Address",
                        "Plan Name",
                        "Account Status",
                        "Installation charges",
                        "Refund Description",
                        "Area",
                      ].map((label, index) => (
                        <label key={index}>
                          <input type="checkbox" /><span className="checkbox-down">{label}</span> 
                        </label>
                      ))}
                    </div>
                    <button className="setting-submit-btn">Submit</button>
                  </div>
                </div>
              </div>
            )} */}
          </Col>
        </Row>

        <br />

        <div className="scrolltable">
          <table className="table table-bordered">
            <thead className="table-th text-center">
              <th className="table-th-content ">Complaint ID</th>
              <th className="table-th-content">Complaint Status</th>
              <th className="table-th-content">Complaint Start Date</th>
              <th className="table-th-content">Customer Affected</th>
              <th className="table-th-content">Contact Number</th>
              <th className="table-th-content">Address</th>
              <th className="table-th-content">Location</th>
              <th className="table-th-content">Notes</th>
              <th className="table-th-content">Verification Status</th>
              <th className="table-th-content">Work Assign</th>
              <th className="table-th-content">Work Status</th>
              <th className="table-th-content">Feedback</th>
              <th className="table-th-content">Additional Charge</th>
              <th className="table-th-content">Payment</th>
              <th className="table-th-content ">Delete</th>
            </thead>

            <tbody className="tr:nth-child(even)">
              <tr>
                <td>
                  <input
                    className="table-search background-change"
                    name="complaintId"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <select
                    className="table-search  background-change"
                    name="complaintStatus"
                    placeholder="Search "
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>

                    <option value="Open">Open</option>
                    <option value="Update">Update</option>
                    <option value="Hold">Hold</option>
                    <option value="Not Interested">Not Interested</option>
                    <option value="Converted">Converted</option>
                    <option value="Not Feasible">Not Feasible</option>
                    <option value="Not Feasible">Not Feasible</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </td>
                <td>
                  <input
                    className="table-search  background-change"
                    type="date"
                    name="complaintStartDate"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>
                <td>
                  <input
                    className="table-search  background-change"
                    type="text"
                    style={{ textTransform: "capitalize" }}
                    name="complaincustomerAffected"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    className="table-search  background-change"
                    name="complaincontactNumber"
                    type="tel"
                    onChange={handleFilterChange}
                    onKeyDown={(e) => {
                      if (
                        ["e", "E", "+", "-"].includes(e.key) ||
                        (e.key &&
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight")
                      ) {
                        e.preventDefault();
                      }
                    }}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    className="table-search  background-change"
                    name="complainAddress"
                    type="text"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    className="table-search  background-change"
                    name="complainLocation"
                    onChange={handleFilterChange}
                    placeholder="Search "
                    type="text"
                  />
                </td>
                <td>
                  <input
                    className=" table-search  background-change"
                    name="complainNotes"
                    onChange={handleFilterChange}
                    placeholder="Search "
                    style={{ textTransform: "capitalize" }}
                    type="text"
                  />
                </td>
                <td>
                  <select
                    className="table-search  background-change"
                    name="complainVerificationStatus"
                    placeholder="Search "
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>
                    <option value="verified">Verified</option>
                    <option value="pending">Pending</option>
                  </select>
                </td>
                <td>
                  <select
                    className="table-search  background-change"
                    name="complainWorkAssign"
                    placeholder="Search "
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>
                    <option value="Pending">Pending</option>
                    <option value="Progress">Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </td>
                <td>
                  <select
                    className="table-search  background-change"
                    name="complainWorkStatus"
                    placeholder="Search "
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>
                    <option value="Pending">Pending</option>
                    <option value="Progress">Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </td>
                <td>
                  <select
                    className="table-search  background-change"
                    name="complainFeedback"
                    placeholder="Search "
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                  </select>
                </td>
                <td>
                  <input
                    className="table-search background-change "
                    style={{ textTransform: "capitalize" }}
                    name="complainAdditionalCharge"
                    onChange={handleFilterChange}
                    placeholder="Search "
                    type="text"
                  />
                </td>
                <td>
                  <select
                    className="table-search  background-change"
                    name="complainPayment"
                    placeholder="Search "
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>
                    <option value="Paid">Paid</option>
                    <option value="Open">Open</option>
                  </select>
                </td>
              </tr>

              {filteredData.map((d, i) => (
                <tr  key={i}>
                  <td onClick={gotoviewcomplaints} >{d.leadId}</td>
                  <td> </td>

                  <td>{formatDate(d.createdDate)}</td>
                  <td>{d.complaintcustomerAffected}</td>
                  <td>{d.complaintcontactNum}</td>
                  <td>{d.complaintaddress}</td>
                  <td>{d.complaintlocation}</td>
                  <td>{d.complaintleadStatus.complaintnotes}</td>

                  <td>{d.complaintlocation}</td>

                  <td>{d.complaintlocation}</td>

                  <td>{d.complaintlocation}</td>

                  <td>{d.complaintlocation}</td>
                  <td>{d.complaintadditionalCharges}</td>

                  <td>{d.complaintlocation}</td>

                 
                
                  <td>
                    <button className="deletebtn text-center" onClick={() => handleDelete(d.leadId)}>
                      <MdDelete size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
}