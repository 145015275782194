import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import "./Enquiry.css";
import axios from "axios";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";
import AddEnquiry from "./AddEnquiry";

export default function Enquiry({ leadId, onDeleteSuccess }) {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  let [data, setData] = useState([]);
  const [customerdelete, setcustomerdelete] = useState(false);
  const [filters, setFilters] = useState({
    leadId: "",
    leadStartedDate: "",
    accountManager: "",
    leadStatus: "",
    customerName: "",
    emailId: "",
    contactNum: "",
    alternateNum: "",
    address: "",
    Location: "",
    leadSource: "",
    createdBy: "",
    updatedBy: "",
    updatedAt: "",
    notes: "",
    followUpDate: "",
  });

  const [statusCounts, setStatusCounts] = useState({
    open: 0,
    Progress: 0,
    Hold: 0,
    NoResponse: 0,
    NotInterested: 0,
    NotFeasable: 0,
    Converted: 0,
    Cancelled: 0,
  });


  const handleDelete = async (leadId) => {
    // Show confirmation popup
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        // API Call to delete user
        await axios.post("http://localhost:3001/api/deleteenquiry", {
          leadId: leadId

        });

        // Success message
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        setcustomerdelete(true);

        // if (onDeleteSuccess) {
        //   onDeleteSuccess();
        // }
      } catch (error) {
        // Error handling
        Swal.fire("Error!", "Something went wrong while deleting.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  const navi = useNavigate();

  const add = () => {
    navi("/addenquiry");
  };

  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [email, setEmail] = useState("");
  //   const [errors, setErrors] = useState({});

  const phoneRegex = /^[7-9]{1}[0-9]{9}$/;

  // Regex for validating email
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber1") {
      setPhoneNumber1(value);
    } else if (name === "phoneNumber2") {
      setPhoneNumber2(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate Phone Number 1
    if (!phoneRegex.test(phoneNumber1)) {
      newErrors.phoneNumber1 =
        "Please enter a valid 10-digit phone number (starting with 7-9)";
    }

    // Validate Phone Number 2 (Optional)
    if (phoneNumber2 && !phoneRegex.test(phoneNumber2)) {
      newErrors.phoneNumber2 =
        "Please enter a valid 10-digit phone number (starting with 7-9)";
    }

    // Validate Email
    if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    return newErrors;
  };

  const [setting, setSetting] = useState(false);
  const settingPopup = (e) => {
    e.preventDefault();
    setSetting(!setting);
  };

  const gotocustomeredit = (customerid) => {
    navi("/edit_enquiry", {
      state: { customerid: customerid },
    });
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value.toLowerCase(), // Normalize input for case-insensitive search
    }));
  };

  const filteredData = data.filter((customer) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true; // Skip empty filters
      return String(customer[key]).toLowerCase().includes(filters[key]);
    });
  });

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted

    axios
      .post("http://localhost:3001/api/viewenquiry", {})
      .then((res) => {
        if (isMounted) {
          const add_installation = res.data.message || [];
          setData(add_installation);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    return () => {
      isMounted = false; // Prevent setting state after unmount
    };
  }, [customerdelete]);
  //
  return (
    <div className="customer-scroll-stop">
      <Topbar />
      <Container fluid className=" pt-1 customer-row ">
        <h3 className=" px-2  ">
          <b>Enquiry</b>
        </h3>


        <Row className=" row-width">
          <Col className="dashborder mt-4   mb-5 mx-2 ">
            <Row className="yellow-card">
              <p className="text-center box-txt " style={{ marginTop: "6px" }}>
                Open
              </p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">03</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1  ">
            <Row className="grey-card">
              <p className="text-center pt-1 box-txt">Progress</p>
            </Row>
            <Row className="number">
              <h5 className="text-center">06</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5  mx-1">
            <Row className="orange-card ">
              <p className="text-center pt-1 box-txt">Hold</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">10</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="pink-card row-margin">
              <p className="text-center pt-1 box-txt">No Response</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">05</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="purple-card">
              <p className="text-center pt-1 box-txt " style={{ marginTop: "8px" }}>Not Interested</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">08</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="blue-card">
              <p className="text-center pt-1 px-1 box-txt">Not Feasible</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">04</h5>
            </Row>{" "}
          </Col>
          <Col className="dashborder mx-1  mt-4 mb-5">
            <Row className="green-card">
              <p className="text-center pt-1 box-txt">Converted</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">02</h5>
            </Row>
          </Col>
          <Col className="dashborder mx-1  mt-4 mb-5">
            <Row className="red-card">
              <p className="text-center pt-1 box-txt">Cancelled</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">02</h5>
            </Row>
          </Col>
        </Row>



        <Row className=" button-bottom-custmer" xs={1} sm={2}>
          <Col className="mt-2 mb-2">
            <button onClick={add} className="black-btn btnadd  black-btn1">
              Add
            </button>{" "}
            &emsp;&emsp;
            <button
              onClick={() => window.location.reload()}
              className="black-btn black-btn1"
            >
              Refresh
            </button>
          </Col>

          <Col className="btn-Ccolumn2 mt-2">
            <button
              className="black-btn black-btn1 btnadd"
            >
              Export
            </button>
            &emsp;&emsp;
            <button onClick={settingPopup} className="black-btn black-btn1 ">
              Settings
            </button>
            {setting && (
              <div className="setting-popup-overlay">
                <div className="setting-popup-container">
                  <button className="setting-close-btn" onClick={settingPopup}>
                    &times;
                  </button>
                  <div className="setting-popup-content">
                    <div className="setting-checkbox-row">
                      <label>
                        <input type="checkbox" />
                        <span
                          className="checkbox-down"
                          style={{ fontSize: "25" }}
                        >
                          Select All
                        </span>
                      </label>
                    </div>
                    <div className="setting-checkbox-grid">
                      {[
                        "Customer ID",
                        "Expiry Date",
                        "Customer Name",
                        "Device Status",
                        " Installation Address",
                        "Contact Number",
                        "Alternate Number",
                        "Mail ID",
                        "Account Manager",
                        "Refund Deposit",
                        "Activation Date",
                        "Last Renewal Date",
                        "Last Logoff Date",
                        "Billing Address",
                        "Plan Name",
                        "Account Status",
                        "Installation charges",
                        "Refund Description",
                        "Area",
                      ].map((label, index) => (
                        <label key={index}>
                          <input type="checkbox" />
                          <span className="checkbox-down">{label}</span>
                        </label>
                      ))}
                    </div>
                    <button
                      className="setting-submit-btn"
                      onClick={settingPopup}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <br />

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            <thead className="table-th text-center">
              <tr>
                <th className="table-th-content">Lead ID</th>
                <th className="table-th-content">Lead Started Date</th>
                <th className="table-th-content">Account Manager</th>
                <th className="table-th-content">Lead Status</th>
                <th className="table-th-content">Customer Name</th>
                <th className="table-th-content">Mail ID</th>
                <th className="table-th-content">Contact Number</th>
                <th className="table-th-content">Alternate Number</th>
                <th className="table-th-content">Address</th>
                <th className="table-th-content">Location</th>
                <th className="table-th-content">Lead Source</th>
                <th className="table-th-content">Created BY</th>
                <th className="table-th-content">Updated By</th>
                <th className="table-th-content">Updated At</th>
                <th className="table-th-content">Notes</th>
                <th className="table-th-content">Follow Up Date</th>
                <th className="table-th-content">Delete</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="table-search background-change"
                    name="enquiryleadId"
                    onChange={handleFilterChange}
                    placeholder="Search.."
                  />
                </td>
                <td>
                  <input
                    className="table-search background-change"
                    type="date"
                    name="enquiryleadStartedDate"
                    onChange={handleFilterChange}
                  />
                </td>
                <td>
                  <input
                    className="table-search background-change"
                    type="text"
                    style={{ textTransform: "capitalize" }}
                    name="enquiryaccountManager"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>
                <td>
                  <select
                    className="table-search background-change"
                    name="enquiryleadStatus"
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>
                    <option value="Saravanan">Saravanan</option>
                    <option value="Ravikumar">Ravikumar</option>
                    <option value="Santhosh">Santhosh</option>
                    <option value="Kalai">Kalai</option>
                    <option value="Kathir">Kathir</option>
                  </select>
                </td>

                <td>
                  <input
                    className="table-search background-change"
                    type="text"
                    style={{ textTransform: "capitalize" }}
                    name="enquirycustomerName"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    type="email"
                    className="table-search background-change"
                    name="enquiryemailId"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    type="tel"
                    className="table-search background-change"
                    name="enquirycontactNum"
                    onChange={handleFilterChange}
                    placeholder="Search "
                    onKeyDown={(e) => {
                      if (
                        ["e", "E", "+", "-"].includes(e.key) ||
                        (!/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight")
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </td>

                <td>
                  <input
                    type="tel"
                    className="table-search background-change"
                    name="enquiryalternateNum"
                    onChange={handleFilterChange}
                    placeholder="Search "
                    onKeyDown={(e) => {
                      if (
                        ["e", "E", "+", "-"].includes(e.key) ||
                        (!/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight")
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    className="table-search background-change"
                    style={{ textTransform: "capitalize" }}
                    name="enquiryaddress"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    type="text"
                    className="table-search background-change"
                    style={{ textTransform: "capitalize" }}
                    name="enquirylocation"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <select
                    className="table-search background-change"
                    name="enquiryleadSource"
                    onChange={handleFilterChange}
                  >
                    <option>Select</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Insta">Insta</option>
                    <option value="Google Ads">Google Ads</option>
                  </select>
                </td>

                <td>
                  <input
                    className="table-search background-change"
                    type="text"
                    name="enquirycreatedBy"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    className="table-search background-change"
                    type="text"
                    name="enquiryupdatedBy"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    type="date"
                    className="table-search background-change"
                    name="enquiryupdatedAt"
                    onChange={handleFilterChange}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    className="table-search background-change"
                    style={{ textTransform: "capitalize" }}
                    name="enquirynotes"
                    onChange={handleFilterChange}
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    type="date"
                    className="table-search background-change"
                    name="enquiryFollowUpDate"
                    onChange={handleFilterChange}
                  />
                </td>
              </tr>

              {filteredData.map((d, i) => (
                <tr key={i}>
                  <td>{d.leadId}</td>
                  <td>{d.createdDate}</td>
                  <td>{d.enquiryaccountManager}</td>
                  <td>{d.enquiryleadStatus}</td>
                  <td>{d.enquirycustomerName}</td>
                  <td>{d.enquiryemailId}</td>
                  <td>{d.enquirycontactNum}</td>
                  <td>{d.enquiryalternateNum}</td>
                  <td>{d.enquiryaddress}</td>
                  <td>{d.enquirylocation}</td>
                  <td>{d.enquiryleadSource}</td>
                  <td>{d.enquirycreatedBy}</td>
                  <td>{d.enquiryupdatedBy}</td>
                  <td>{d.enquiryupdatedAt}</td>
                  <td>{d.enquirynotes}</td>
                  <td>{d.enquiryFollowUpDate}</td>

                  <td>
                    <button
                      className="deletebtn text-center"
                      onClick={() => handleDelete(d.leadId)}
                    >
                      <MdDelete size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </Container>
    </div>
  );
}