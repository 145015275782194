import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaUserEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import "./Add_Complaint.css"

import FiberTeam_Complaint from './FiberTeam_Complaint';
import NetworkTeam_Complaint from './NetworkTeam_Complaint';

export default function WorkAssign_Complaint() {
        const [activeTabWorkAssign, setActiveTabWorkAssign] = useState("fiberTeam");

         const renderContentWorkAssign = () => {
                switch (activeTabWorkAssign) {
                    case "fiberTeam":
                        return <div>
        
                     <FiberTeam_Complaint/>
                        </div>;
                    case "networkTeam":
                        return <div>
                          <NetworkTeam_Complaint/>
                         
                        </div>;
                
                    default:
                        return <div>Select a tab</div>;
                }
            };
    
  return (
    <div className="tabs-container-workassign mt-3">
    {/* Tabs */}
    <div className="tabs-wokassign">
        <Row>
         
                <button className='fiber-button-workassign' type="button" onClick={() => setActiveTabWorkAssign("fiberTeam")}>Fiber Team</button>

            
                <button className='fiber-button-workassign ms-5'  type="button" onClick={() => setActiveTabWorkAssign("networkTeam")}>Network Team</button>
</Row>
            
    </div>

    {/* Dynamic Content */}
    <div className='fiber-workassign mt-3'>

        {renderContentWorkAssign()}






    </div>



</div>

  )
}
