import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ManageStock.css";

import { MdModeEdit, MdDelete } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";

const ManageStock = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [stockMaterials, setStockMaterials] = useState([]);
  const [materialAdding, setMaterialAdding] = useState(false);
  const [materials, setMaterials] = useState({ material: "", materialType: "" });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editValue, setEditValue] = useState({ material: "", materialType: "" });

  // Handle input changes
  const handleMaterialChange = (e) => {
    const { name, value } = e.target;
    setMaterials((prev) => ({ ...prev, [name]: value }));
  };

  // Handle edit input changes
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValue((prev) => ({ ...prev, [name]: value }));
  };

  // Fetch stock materials on component load
  useEffect(() => {
    const fetchStockMaterials = async () => {
      try {
        const response = await axios.get(`${apiUrl}manage-stock/get-all`);
        setStockMaterials(Array.isArray(response.data) ? response.data : []);
      } catch (error) {

        if (error.response.data.message === "No materials found") {
          Swal.fire("Add Material", "Oops! It looks like there are no materials. Add one to proceed", "info");
        } else {
          Swal.fire("Error!", "Failed to fetch stock materials", "error");
        }
      }
    };

    fetchStockMaterials();
  }, [apiUrl]);

  // Add stock material
  const handleStockSubmit = async (e) => {
    e.preventDefault();
    if (!materials.material.trim() || !materials.materialType.trim()) {
      Swal.fire("Error!", "Both fields are required", "error");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}manage-stock/add`,
        {
          material: materials.material.trim(),
          materialType: materials.materialType.trim()
        }
      );

      Swal.fire("Success!", response.data.message, "success");

      setStockMaterials((prev) => [...prev, { ...materials, _id: response.data.data._id }]);
      setMaterials({ material: "", materialType: "" });
      setMaterialAdding(false);
    } catch (error) {
      if(error.response.data.message === "Material with this type already exists")
      {
        Swal.fire("Oops!", "This Material Type is Already Added ", "info");
      }
        else{
          Swal.fire("Error!", "Failed to add material", "error");
        }
     
    }
  };

  // Edit stock material
  const handleEdit = (row) => {
    setEditingRowId(row._id);
    setEditValue({ material: row.material, materialType: row.materialType });
  };

  const handleEditSave = async () => {
    // Check if no changes are made
    const currentRow = stockMaterials.find((row) => row._id === editingRowId);
    if (
      currentRow.material === editValue.material &&
      currentRow.materialType === editValue.materialType
    ) {
      Swal.fire("No Changes", "No modifications found!", "info");
      setEditingRowId(null); // Exit editing mode
      return;
    }

    // Validation to prevent blank fields
    if (!editValue.material.trim() || !editValue.materialType.trim()) {
      Swal.fire("Error!", "Both fields are required to save changes.", "error");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/manage-stock/update-stock`, {
        materialId: editingRowId,
        ...editValue,
      });

      Swal.fire("Success!", response.data.message, "success");

      const updatedMaterial = response.data.data;

      setStockMaterials((prev) =>
        prev.map((row) => (row._id === editingRowId ? updatedMaterial : row))
      );
      setEditingRowId(null); // Exit editing mode
    } catch (error) {
      console.error("Error updating material:", error);
      Swal.fire("Error!", "Failed to update material", "error");
    }
  };



  //handleDelet
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        console.log(id);
        const response = await axios.delete(
          apiUrl + "manage-stock/delete-stock",
          {
            data: { _id: id },
          }
        );

        console.log(response);
        Swal.fire("Deleted!", response.data.Message, "success");

        setStockMaterials((prev) => {
          console.log(
            "Updated Stock Material:",
            prev.filter((row) => row._id !== id)
          );
          return prev.filter((row) => row._id !== id);
        });
      } catch (error) {
        Swal.fire("Error!", "Failed to delete Material.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  const handleCancel = () => {
    setMaterialAdding(false);
    setMaterials({ material: "", materialType: "" });
  };

  return (
    <Container fluid>
      <Row className="mt-4">
        <Col md={6} sm={6}>
          <h3 className="head ">Stock Management</h3>
        </Col>
        <Col md={6} sm={6}>
          {!materialAdding && (
            <button className="black-btn add-btn " onClick={() => setMaterialAdding(true)}>
              Add Material
            </button>
          )}
        </Col>
      </Row>
      <Row className="">
        <table className="table-border-manage-stock">
          <thead className="table-th">
            <tr className="material-head">
              <th className="table-th-content text-center">Material</th>
              <th className="table-th-content text-center">Material Type</th>
              <th className="table-th-content text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {materialAdding && (
              <tr className="stock-td">
                <td>
                  <input
                    type="text"
                    name="material"
                    value={materials.material}
                    onChange={handleMaterialChange}
                    autoComplete="off"
                    className="form-field centered"
                    placeholder="Enter Material"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="materialType"
                    value={materials.materialType}
                    onChange={handleMaterialChange}
                    placeholder="Enter Type"
                    autoComplete="off"
                    className="form-field centered"
                  />
                </td>
                <td className="text-center d-flex">

                  <button className="cancel-btn outlined-btn mx-2" onClick={handleCancel}>
                    Cancel
                  </button>
                  <button className="primary-btn filled-btn mx-2" onClick={handleStockSubmit}>
                    Submit
                  </button>
                </td>
              </tr>
            )}

            {stockMaterials.map((row) => (
              <tr key={row._id} className="stock-td">
                <td className="stock-td">
                  {editingRowId === row._id ? (
                    <input
                      type="text"
                      name="material"
                      value={editValue.material}
                       placeholder="Enter Material"
                      onChange={handleEditChange}
                      autoComplete="off"
                      className="form-field centered"
                    />
                  ) : (
                    row.material
                  )}
                </td>
                <td className="stock-td">
                  {editingRowId === row._id ? (
                    <input
                      type="text"
                      name="materialType"
                      value={editValue.materialType}
                      onChange={handleEditChange}
                      placeholder="Enter Type"
                      autoComplete="off"
                      className="form-field centered"
                    />
                  ) : (
                    row.materialType
                  )}
                </td>
                <td className="text-center">
                  {editingRowId === row._id ? (
                    <button
                      className="blue-btn mx-2"
                      onClick={handleEditSave}
                      type="button"
                    >
                      Save
                    </button>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <MdModeEdit className="edit-icon hover-scale" onClick={() => handleEdit(row)} />
                      <MdDelete className="delete-icon hover-scale" onClick={() => handleDelete(row._id)} />
                    </div>
                  )}
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </Row>
    </Container>
  );
};

export default ManageStock;
