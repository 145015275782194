import React, { useState,useEffect } from "react";
import { Container, Row, Col, Form,InputGroup } from "react-bootstrap";
import "./AddStaff.css";
import axios from "axios";
import { useLocation } from 'react-router-dom';

export const Edit_Staff = () => {
  const [staffId, setStaffId] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [designation, setDesignation] = useState("");
  const [staffName, setStaffName] = useState("");
  const [mail, setMail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternativeNumber1, setAlternativeNumber1] = useState("");
  const [alternativeNumber2, setAlternativeNumber2] = useState("");
  const [address, setAddress] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [educationCertificate, setEducationCertificate] = useState("");
  const [drivingLicence, setDrivingLicence] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [staffPhoto, setStaffPhoto] = useState("");
  const [actionStatus, setActionStatus] = useState("");

  const [error, setError] = useState(''); // for validation error
  const [error1, setError1] = useState(''); // for validation error
  const [error2, setError2] = useState(''); 
   let [editStaffdetails, seteditStaffdetails] = useState([])


  
  

  // Validate phone number when the input field loses focus (onBlur)
  const handlePhoneNumberBlurStaff = () => {
    const regex = /^[0-9]{10}$/; // Regex for a 10-digit phone number
    if (!regex.test(contactNumber)) {
      setError('Please enter a valid 10-digit phone number');
    } else {
      setError('');
    }
  };




  const handlePhoneNumberBlurStaffalt1 = () => {
    const regex1 = /^[0-9]{10}$/; // Regex for a 10-digit phone number
    if (!regex1.test(alternativeNumber1)) {
      setError1('Please enter a valid 10-digit phone number');
    } else {
      setError1('');
    }
  };

  const handlePhoneNumberBlurStaffalt2 = () => {
    const regex2 = /^[0-9]{10}$/; // Regex for a 10-digit phone number
    if (!regex2.test(alternativeNumber2)) {
      setError2('Please enter a valid 10-digit phone number');
    } else {
      setError2('');
    }
  };

  const locate = useLocation();
  const staff_fetch = locate.state?.staffId;

useEffect(  ()   =>
{
axios.post('http://localhost:3001/staff/getdata', 
        {
            staffId:staff_fetch
        }
     )

     .then((res) =>
    {
        seteditStaffdetails(res.data.message);
       

    })
},[])

useEffect(()=>{
    setStaffId(editStaffdetails.staffId)
    setStaffName(editStaffdetails.staffName)
    setDob(editStaffdetails.dob)
    setPassword(editStaffdetails.password)
    setDesignation(editStaffdetails.designation)
    setMail(editStaffdetails.mail )
    setContactNumber(editStaffdetails.contactNumber )
    setAlternativeNumber1(editStaffdetails.alternativeNumber1)
    setAlternativeNumber2(editStaffdetails.alternativeNumber2)
     setAddress(editStaffdetails.address)
     setAadhar(editStaffdetails.aadhar)
     setEducationCertificate(editStaffdetails.educationCertificate)
     setDrivingLicence(editStaffdetails.drivingLicence)
     setBankAccountNumber(editStaffdetails.bankAccountNumber)
     setIfsc(editStaffdetails.ifsc)
     setStaffPhoto(editStaffdetails.staffPhoto)
     setActionStatus(editStaffdetails.actionStatus)
      
},[editStaffdetails])
 
   
const updatestaff_data =  (e) => {
  e.preventDefault();
 
    
      axios.put('http://localhost:3001/staff/update/:id', {
      staffId,
        password,
        dob,
        designation,
        staffName,
        mail,
        contactNumber,
        alternativeNumber1,
        alternativeNumber2,
        address,
        aadhar, // Adjust if files need to be uploaded
        educationCertificate,
        drivingLicence,
        bankAccountNumber,
        ifsc,
        staffPhoto,
        actionStatus,
    })



    .then((res)=>{
alert(res.data.message)
    })
}
  
    
 

  return (
    <Container fluid className="mt-3 margin-right ">

<form onSubmit={updatestaff_data}>
      <Row>
        <Col>
          <h3 style={{ marginRight: "37vw" }}>Staffs</h3>
        </Col>
        <Col className="submit text-end">
          <button type="submit" value="submit" className="blue-btn  px-4 text-white" >
            Update
          </button>
        </Col>
      </Row>

      <h3 className="form-head add-staff mt-2 mb-0">Add Staff</h3>
      <div clasName="form-addstaff-contianer" >
        {/* First Row */}
        <Row className=" ">
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">Staff Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter ID"
                className="form-input"
                Value={staffId}
                onChange={(e) => setStaffId(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                className="form-input"
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">Date of Join</Form.Label>
              <InputGroup>
                <Form.Control
                  type="date"
                  className="form-input"
                  defaultValue={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="custom-dropdown">
              <Form.Label className="label-margin-1">Designation</Form.Label>
              <Form.Select
                className="form-input form-input-select"
                onChange={(e) => setDesignation(e.target.value)}
                defaultValue={designation}
              >
                <option>Select</option>
                <option>Manager</option>
                <option>Admin</option>
                <option>Engineer</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {/* Second Row */}
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label className="label-margin-2">Staff Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Staff name"
                className="form-input"
                defaultValue={staffName}
                style={{ textTransform: "capitalize" }}
                onChange={(e) => setStaffName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="label-margin-2">Mail Id</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Mail ID"
                className="form-input"
                defaultValue={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Third Row */}
        <Row className="mb-3">
          {/* Contact Numbers */}
          <Col md={3}>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label className="label-margin-1">
                    Contact Number*
                  </Form.Label>
                  <InputGroup>
                    {/* <InputGroup.Text>+91</InputGroup.Text> */}
                    <Form.Control
                      type="tele"
                      required
                      placeholder="+91"
                      className="form-input"
                      value={contactNumber}
                      onBlur={handlePhoneNumberBlurStaff}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </InputGroup>
                  {error && <p style={{ color: 'red' }}>{error}</p>}

                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label className="label-margin-1">
                    Alternate Number 2
                  </Form.Label>
                  <InputGroup>
                    {/* <InputGroup.Text>+91</InputGroup.Text> */}
                    <Form.Control
                      type="tele"
                      placeholder="+91"
                      className="form-input"
                      onBlur={handlePhoneNumberBlurStaffalt2}
                      value={alternativeNumber2}
                      onChange={(e) => setAlternativeNumber2(e.target.value)}
                    />
                  </InputGroup>
                  {error2 && <p style={{ color: 'red' }}>{error2}</p>}
                </Form.Group>
              </Col>
            </Row>
          </Col>

          {/* Alternate Number 2 and Aadhaar */}
          <Col md={3}>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label className="label-margin-1">
                    Alternate Number 1
                  </Form.Label>
                  <InputGroup>
                    {/* <InputGroup.Text>+91</InputGroup.Text> */}
                    <Form.Control
                      type="tele"
                      onBlur={ handlePhoneNumberBlurStaffalt1}
                      value={alternativeNumber1}
                      placeholder="+91 "
                      className="form-input"
                      onChange={(e) => setAlternativeNumber1(e.target.value)}
                    />
                  </InputGroup>
                  {error1 && <p style={{ color: 'red' }}>{error1}</p>}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label className="label-margin-1">Aadhaar</Form.Label>

                  <input
                    type="file"
                    variant="light"
                    className="btn-upload w-100  file-upload form-input"
                    style={{ height: "45px" }}
                    defaultValue={aadhar}
                    onChange={(e) => setAadhar(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>

          {/* Address Field */}
          <Col md={6}>
            <Form.Group>
              <Form.Label className="label-margin-2">Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter Address"
                className="form-input"
                style={{ textTransform: "capitalize" }}
                defaultValue={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Fourth Row */}

        <Row className="mb-3">
          {/* Education Certificate */}
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">
                Education Certificate
              </Form.Label>
              <input
                type="file"
                variant="light"
                className="btn-upload w-100  file-upload form-input"
                style={{ height: "45px" }}
                defaultValue={educationCertificate}
                onChange={(e) => setEducationCertificate(e.target.value)}
              />
            </Form.Group>
          </Col>

          {/* Driving Licence */}
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">
                Driving Licence
              </Form.Label>
              <input
                type="file"
                variant="light"
                className="btn-upload w-100  file-upload form-input"
                style={{ height: "45px" }}
                defaultValue={drivingLicence}
                onChange={(e) => setDrivingLicence(e.target.value)}
              />
            </Form.Group>
          </Col>

          {/* Bank Account Number */}
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">
                Bank Account Number
              </Form.Label>
              <InputGroup>
                {/* <InputGroup.Text>+91</InputGroup.Text> */}
                <Form.Control
                  type="text"
                  placeholder="Enter Bank Account Number"
                  className="form-input"
                  defaultValue={bankAccountNumber}
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          {/* IFSC Code */}
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">IFSC Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IFSC Code"
                className="form-input"
                defaultValue={ifsc}
                onChange={(e) => setIfsc(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* Fifth Row */}

        <Row className="mb-3">
          {/* Staff Photo */}
          <Col md={3}></Col>
          <Col md={3}></Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="label-margin-1">Staff Photo</Form.Label>
              <input
                type="file"
                variant="light"
                className="btn-upload w-100  file-upload form-input"
                style={{ height: "45px" }}
                defaultValue={staffPhoto}
                onChange={(e) => setStaffPhoto(e.target.value)}
              />
            </Form.Group>
          </Col>

          {/* Activity Status */}

          <Col md={3}>
            <Form.Group className="custom-dropdown">
              <Form.Label className="label-margin-1">
                Activity Status
              </Form.Label>
              <Form.Select
                className=" form-input-select dropdown-option-color"
                onChange={(e) => setActionStatus(e.target.value)}
                defaultValue={actionStatus}
              >
                <option className="activity-status-active" value="active">
                  Active
                </option>
                <option className="activity-status-inactive" value="inactive">
                  Inactive
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          {/* <Col md={3}>
    <Form.Group>
      <Form.Label>Activity Status</Form.Label>
      <Form.Select className="form-input form-input-select">
        <option>Select</option>
        <option>Active</option>
        <option>Inactive</option>
      </Form.Select>
    </Form.Group>
  </Col> */}
        </Row>
       </div>
      </form>
    </Container>
  );
};