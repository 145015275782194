import React, { useState } from "react";
import "./Password.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import axios from "axios";

const Password = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const backward = () => {
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation check
    if (!email || !email.includes("@")) {
      setMessage("Please enter a valid email");
      return;
    }

    try {
      setMessage("Sending OTP...");
      await axios.post(apiUrl + "forgot-password", { email });

      Swal.fire({
        title: "OTP Sent Successfully!",
        text: "Check your email for the OTP.",
        icon: "success",
        confirmButtonText: "Okay",
      });

      setShowOtpSection(true);
    } catch (error) {
      setMessage(error.response?.data?.message || "Failed to send OTP");

      Swal.fire({
        title: "Error",
        text: error.response?.data?.message || "Failed to send OTP",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  const handleOtpChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear the current box
      setOtp(newOtp);

      // Move to the previous input on backspace if not the first box
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const handleOtpSubmit = async () => {
    if (otp.join("").length === 4) {
      try {
        const response = await axios.post(apiUrl + "verify-otp", {
          email,
          otp: otp.join(""),
        });

        if (response.data.message === "OTP verified") {
          Swal.fire({
            title: "OTP Verified",
            text: "Proceed to reset your password.",
            icon: "success",
            confirmButtonText: "Continue",
          });

          navigate(`/newpassword`, {
            state: {
              email: email,
            },
          });
        } else {
          Swal.fire({
            title: "Invalid OTP",
            text: "Please enter a valid OTP.",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Error",
          text: error.response?.data?.message || "Something went wrong!",
          icon: "error",
          confirmButtonText: "Try Again",
        });
      }
    } else {
      Swal.fire({
        title: "Invalid OTP",
        text: "Please enter a valid 4-digit OTP.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  return (
    <div className="container-fluid password">
      <div className="password-col">
        <div className="row d-flex">
          <div>
            <IoArrowBackCircleSharp onClick={backward} className="backward-p" />
          </div>
          <div>
            <h1 className="password-txt">ForgotPassword</h1>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit} className="password-form">
            <div sm={12}>
              <label>
                <b>Email Id</b>
              </label>
            </div>
            <div sm={12}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="password-ph"
                placeholder="Enter Your Email ID"
              />
            </div>
            <div sm={12}>
              <button className="confirm-btn" type="submit">
                Confirm
              </button>
            </div>
          </form>

          {showOtpSection && (
            <>
              <div className="otp-box">
                <div sm={12}>
                  <label>
                    <b>Enter OTP</b>
                  </label>
                </div>
                <div style={{ display: "flex", marginLeft: "16%" }}>
                  {otp.map((value, index) => (
                    <div key={index} md={1} sm={4} xs={6}>
                      <input
                        id={`otp-${index}`}
                        type="tel"
                        className="otp"
                        style={{ marginLeft: "10px" }}
                        maxLength={1}
                        value={value}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)} // Add this line
                      />
                    </div>
                  ))}
                </div>
                <p
                  style={{
                    fontSize: "13px",
                    marginTop: "5px",
                    marginLeft: "16%",
                  }}
                >
                  If you didn't receive an OTP,{" "}
                  <span
                    style={{ color: "#257ABF", cursor: "pointer" }}
                    onClick={handleSubmit}
                  >
                    Resend
                  </span>
                </p>{" "}
              </div>
              <button className="continue-btn" onClick={handleOtpSubmit}>
                Continue
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Password;
