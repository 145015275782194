import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import { FaStarOfLife } from "react-icons/fa6";
import { Modal, Box, Button } from "@mui/material";
import { ImCross } from "react-icons/im";
import { FiX, FiUpload } from "react-icons/fi"; // Close & Upload icons
import { MdPadding } from "react-icons/md";
import "./Add_Complaint.css"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect } from "react";
import dayjs from 'dayjs';

export default function Add_Complaint() {
  const navigate=useNavigate();
  const currentTime = dayjs().format('hh:mm A'); // Example: "11:42:00 AM"


const formatDate = (date) => {
    return dayjs(date).format('DD-MM-YYYY');
  };
  
    const [addComplaint, setAddComplaint] = useState({
     leadId: "",
      createdDate: "",
      complaintcustomerAffected: "",
      complaintType:"",
      complaintcustomerName: "",
      complaintcontactNum: "", 
      complaintaddress: "",
      complaintlocaation:"",
      complaintnetwork:false,
      complaintfiber:false,
      complaintadditionalCharges:"",

     
  
    
      complaintleadStatus: {
        complaintnotes: "",
        complaintLeadStatusnextFollowUpDate: "",
  
        
      }
    }
    );
 

  const [selectedFile, setSelectedFile] = useState(null);


  const[isOpenAdvancePayment,setIsOpenAdvancePayment]=useState(false)

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleLeadChange = (e) => {
    const { name, value } = e.target;
    setAddComplaint(prevState => ({
        ...prevState,
        complaintleadStatus: {
            ...prevState.complaintleadStatus,
            [name]: value
        }
    }));
};

  const [isOpenplan, setIsOpenplan] = useState(false);
  const [isOpenComplaintOpen, setIsOpenComplaintOpen] = useState(false);

  const handleChange = (e) => {
    setAddComplaint({ ...addComplaint, [e.target.name]: e.target.value });
  };

  const handleChangecheckbox = (e) => {
    setAddComplaint((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));

    console.log(e.target.name, e.target.checked);
  };
  
  useEffect(() => {
        fetch("http://localhost:3001/api/generate-lead-id-complaint")
          .then((res) => res.json())
          .then((data) => {
            console.log("Fetched Lead ID:", data.leadId); // Debugging log
      
            setAddComplaint((prev) => ({
              ...prev, // Spread previous state
              leadId: data.leadId, // Update only the leadId field
              createdDate: new Date().toISOString().split("T")[0], // Set today's date
            }));
          })
          .catch((error) => console.error("Error fetching Lead ID:", error));
      }, []);
      

  const handleSubmit = async (e) => {
    e.preventDefault();
    
      if (addComplaint.complaintcontactNum === "") {
        alert("Phone number is required!");
    }
    

  

    

    Swal.fire({
        title: "Do you want to save the Customer Details?",
        showCancelButton: true,
        confirmButtonText: "Save",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await axios.post(
                    "http://localhost:3001/api/add-lead-complaint",
                    addComplaint,
                    { headers: { "Content-Type": "application/json" } } // 👈 Ensure JSON format
                );

                Swal.fire({
                    title: "Customer Details Added Successfully!",
                    icon: "success",
                }).then(() => {
                    navigate("/complaint");
                });
            } catch (error) {
                console.log("Error submitting data:", error.response?.data || error.message);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.response?.data?.message || "Something went wrong!",
                });
            }
        }
    });
};


  return (
    <>
      <Topbar />
      <Container className="mt-1    ">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <h3>Complaints</h3>
            </Col>

            <Col className="mx-5 text-end">
              <button
                className=" blue-btn  px-4 text-white"
                value="submit"
                type="submit"
              >
                Submit
              </button>
            </Col>
          </Row>

          <div className="border-container1">
            <h6 className="form-head  m-0 text-white  ">Complaint Details</h6>
          </div>

          <div className="border-container ">
            <Row className="text-start lead-id-row  mb-4 mt-4 ms-4 ">
            <p className="ps-5 paragraph-lead-id">
                Complaint ID - <span className="text-primary " value={addComplaint.leadId} onChange={handleChange}>
                  {addComplaint.leadId}</span>{" "}
                <span className="lead-start-date">
                  {" "}
                  Started Date & Time -{" "}
                  <span className="text-primary">{formatDate(addComplaint.createdDate)} - {currentTime}</span>
                </span>{" "}
              </p>
            </Row>
            <Row className="ms-3">
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Affected</lable>
                <br />
                <input
                  type="text"
                  style={{ textTransform: "capitalize " }}
                  className="form-input "
                  placeholder="Enter Custmer Name"
                  value={addComplaint.complaintcustomerAffected} onChange={handleChange}
                  name="complaintcustomerAffected"
                />
              </Col>

              <Col sm={12} md={6} className="text-start ">
                <lable>Complaint Type</lable>
                <br />
                <input
                  type="text"
                  style={{ textTransform: "capitalize " }}
                  className="form-input complaint-type "
                  placeholder="Enter Complaint type"
                   value={addComplaint.complaintType} onChange={handleChange}
                  name="complaintType"
                  autoComplete="off"
                />
              </Col>
              </Row>

              

            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
                <lable> Address</lable>
                <br />
                <textarea
                  type="text"
                  style={{ textTransform: "capitalize", resize: "none" }}
                  className=" box address-enquiry"
                  placeholder="Enter Installation Address"
                   value={addComplaint.complaintaddress} onChange={handleChange}
                  name="complaintaddress"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Dependent</lable>
                    <br />
                    {/* <input
                      
                      style={{ textTransform: "capitalize"}}
                      className="form-input complaint-checkbox "
                      type="checkbox"
                      placeholder="Enter Account Manager"
                    /> Network Team */}

<label class="custom-checkbox">
  <input type="checkbox"
   value={addComplaint.complaintnetwork} onChange={handleChangecheckbox}
   name="complaintnetwork"/>
  <span className="checkbox-complaint-margin">Network Team</span> 
</label>

                  </Col>
                  <Col xs={12} md={6} className="text-start">
                  <Row className="checkbox-complaint-top">
                  <label class="custom-checkbox ">
  <input type="checkbox"
  value={addComplaint.complaintfiber} onChange={handleChangecheckbox}
  name="complaintfiber"
  />
  <span className="checkbox-complaint-margin">Fiber Team</span> 
</label>    
</Row>     
                  </Col>
                </Row>
                <Row>
                  <Row>
                                   <Col xs={12} md={6} className="text-start">
                                     <lable>
                                       Contact Number
                                       <sup>
                                         <FaStarOfLife size={7} />
                                       </sup>
                                     </lable>
                                     <br />
                                     <input
                                       type="tel"
                                       onKeyDown={(e) => {
                                         if (
                                           ["e", "E", "+", "-"].includes(e.key) ||
                                           (e.key &&
                                             !/[0-9]/.test(e.key) &&
                                             e.key !== "Backspace" &&
                                             e.key !== "ArrowLeft" &&
                                             e.key !== "ArrowRight")
                                         ) {
                                           e.preventDefault();
                                         }
                                       }}
                                      //  value={installContactNum}
                                       
                                       required
                                       placeholder="+91"
                                       className="form-input"
                                       value={addComplaint.complaintcontactNum} onChange={handleChange}
                                        name="complaintcontactNum"
                                     />
                                   </Col>
                 
                                   <Col xs={12} md={6} className="text-start">
                                     <lable>Location </lable>
                                     <br />
                                     <input
                                       type="text"
                                       
                                       className="form-input"
                                       placeholder="+91"
                                       
                                       value={addComplaint.complaintlocation} onChange={handleChange}
                                        name="complaintlocation"
                                     />
                                   </Col>
                                 </Row>
                                 <Row>
                                 
                                                    <Col xs={12} md={6} className="text-start">
                                                      <lable>Additional Charge</lable>
                                                      <br />
                                                      <input
                                                        className="field form-input"
                                                        value={addComplaint.complaintadditionalCharges} onChange={handleChange}
                                        name="complaintadditionalCharges"
                                                        
                                                        type="text"
                                                      />
                                                    </Col>
                                                    <Col xs={12} md={6} className="text-start">
                                                                        <lable>Lead Status</lable>
                                                                        <br />
                                                                        <input style={{backgroundColor:"#fbdc6e",color:"rgb(94, 94, 14)"}}
                                                                          type="button"
                                                                          onClick={() => setIsOpenComplaintOpen(true)}
                                                                          className="form-input converted-form"
                                                                          name="installleadStatus"
                                                                          value="Open"
                                                    
                                                                        />
                                                                          
                                                                 
                                                    
                                                                        {isOpenComplaintOpen && (
                                                                          <div className="overlay1-converted">
                                                                            <div className="modal1-converted">
                                                                              <Container>
                                                                                <button
                                                                                  className="close-btn1"
                                                                                  onClick={() => setIsOpenComplaintOpen(false)}
                                                                                >
                                                                                  <span style={{ fontSize: "20px" }}>
                                                                                    {" "}
                                                                                    &times;{" "}
                                                                                  </span>
                                                                                </button>
                                                    
                                                                                <div className="open-button border">
                                                                                  <h5>Open</h5>
                                                                                </div>
                                                    
                                                                                <textarea
                                                                                  style={{
                                                                                    textTransform: "capitalize",
                                                                                    resize: "none",
                                                                                  }}
                                                                                  placeholder="Enter Notes"
                                                                                  className="converted-textbox"
                                                                                  name="complaintnotes"
                                                                                  onChange={handleLeadChange}
                                                    
                                                                                  value={addComplaint.complaintleadStatus.complaintnotes}
                                                                                />
                                                                                <div className="upload-section">
                                                                                  <label className="upload-btn">
                                                                                    <FiUpload className="upload-icon" />
                                                                                    Upload Image
                                                                                    <input
                                                                                      type="file"
                                                                                      accept="image/*"
                                                                                      onChange={handleFileChange}
                                                    
                                                    
                                                                                      hidden
                                                                                    />
                                                                                  </label>
                                                                                  {selectedFile && (
                                                                                    <p className="file-name">{selectedFile.name}</p>
                                                                                  )}
                                                                                </div>
                                                    
                                                                                <Row>
                                                                                  <Col className="date-update-textbox">
                                                                                    <label>Next Follow Up Date</label>
                                                                                    <br />
                                                                                    <input
                                                                                      type="date"
                                                                                      className="field form-input"
                                                                                      value={addComplaint.complaintleadStatus.complaintLeadStatusnextFollowUpDate} 
                                                                                      onChange={handleLeadChange}
                                                                                      name="complaintLeadStatusnextFollowUpDate"
                                                    
                                                                                    />
                                                                                  </Col>
                                                                                  <Col className="uploadbtn-install">
                                                                                    <button type="button" className="blue-btn"
                                                                                     onClick={() => setIsOpenComplaintOpen(false)}>
                                                                                      Upload
                                                                                    </button>
                                                                                  </Col>
                                                                                </Row>
                                                                              </Container>
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                      </Col>
                                                  </Row>
                                
                </Row>
              </Col>
            </Row>
           
          </div>
        </form>
        
      </Container>


    </>
  );
}
