import React, { useState, useEffect } from "react";
import "./Ottdetails.css";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";

const Ottdetails = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [ottRows, setOttRows] = useState([]);
  const [ottDetail, setOttDetail] = useState("");
  const [ottIsAdding, setOttIsAdding] = useState(false);
  const [editingOttRowId, setEditingOttRowId] = useState(null);
  const [editOttValue, setEditOttValue] = useState("");
  const [addButtonEnableOtt, setAddButtonEnableOtt] = useState(true);
  const [ottEditError, setOttEditError] = useState(""); // State to store edit error
  const [ottInputValue, setOttInputValue] = useState("");

  const handleOttAddRow = () => {
    setOttIsAdding(true);
  };

  const handleOttSubmit = async (e) => {
    e.preventDefault();
    if (!ottDetail.trim()) {
      Swal.fire("Warning!", "Please enter OTT Details data.", "warning");
      return;
    }
    try {
      const response = await axios.post(apiUrl + "addott", {
        ottDetail,
      });

      Swal.fire("Success!", response.data.message, "success");

      setOttRows((prev) => [...prev, { _id: response.data.id, ottDetail }]);
      setOttIsAdding(false);
      setOttDetail("");
    } catch (error) {
          console.error("Error submitting data", error);
    
          // Handle errors properly
          const errorMessage =
            error.response?.data?.message || "Failed to submit data.";
          Swal.fire("Error!", errorMessage, "error");
        }
  };

  useEffect(() => {
    axios
      .post(apiUrl + "viewott", {})
      .then((res) => {
        setOttRows(res.data.message);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  const handleOttDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.post(apiUrl + "deleteott", {
          _id: id,
        });

        Swal.fire("Deleted!", response.data.Message, "success");
        setOttRows((prev) => prev.filter((row) => row._id !== id));
      } catch (error) {
        Swal.fire("Error!", "Failed to delete OTT Details.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  const handleOttEdit = (row) => {
    setEditingOttRowId(row._id);
    setEditOttValue(row.ottDetail);
    setAddButtonEnableOtt(false); //
  };

  const handleOttEditChange = (e) => {
    setEditOttValue(e.target.value);
  };
  // const handleOttEditSave = async () => {
  //   setAddButtonEnableOtt(true);
  //   // Check if the edited role already exists
  //   const isDuplicate = ottRows.some(
  //     (row) =>
  //       row.ottDetail.toLowerCase() === editOttValue.trim().toLowerCase() &&
  //       row._id !== editingOttRowId
  //   );

  //   if (isDuplicate) {
  //     Swal.fire("Error!", "OTT Detail already exists!", "error");
  //     return;
  //   }
  //   Swal.fire({
  //     title: "Do you want to save the changes?",
  //     showDenyButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: "Save",
  //     denyButtonText: `Don't save`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       // Check if the value is unchanged
  //       const currentRow = ottRows.find((row) => row._id === editingOttRowId);
  //       if (currentRow && currentRow.ottDetail === editOttValue.trim()) {
  //         Swal.fire(
  //           "No Update Found",
  //           "No changes were made to the OTT details.",
  //           "info"
  //         );
  //         setOttRows((prev) =>
  //           prev.map((row) =>
  //             row._id === editingOttRowId
  //               ? { ...row, leadSource: editOttValue }
  //               : row
  //           )
  //         );

  //         setEditingOttRowId(null);
  //         return; // Exit without calling the API
  //       }

  //       try {
  //         const response = await axios.post(apiUrl + "updateott", {
  //           ottId: editingOttRowId,
  //           ottDetail: editOttValue,
  //         });

  //         Swal.fire("Saved!", response.data.Message, "success");

  //         // Update the specific row in the table
  //         setOttRows((prev) =>
  //           prev.map((row) =>
  //             row._id === editingOttRowId
  //               ? { ...row, ottDetail: editOttValue }
  //               : row
  //           )
  //         );

  //         setEditingOttRowId(null); // Exit edit mode
  //       } catch (error) {
  //         Swal.fire("Error!", "Failed to update OTT Details.", "error");
  //         console.error("Edit Error:", error);
  //       }
  //     } else if (result.isDenied) {
  //       Swal.fire("Changes are not saved", "", "info");
  //       setOttRows((prev) =>
  //         prev.map((row) =>
  //           row._id === editingOttRowId
  //             ? { ...row, ottDetail: editOttValue }
  //             : row
  //         )
  //       );

  //       setEditingOttRowId(null); // Exit edit mode
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       // Handle cancel button click
  //       setEditingOttRowId(null); // Exit edit mode
  //     }
  //   });
  // };

  const handleOttEditSave = async () => {
    setAddButtonEnableOtt(true);
  
    // Check if the edited OTT detail already exists
    const isDuplicate = ottRows.some(
      (row) =>
        row.ottDetail.toLowerCase() === editOttValue.trim().toLowerCase() &&
        row._id !== editingOttRowId
    );
  
    if (isDuplicate) {
      Swal.fire("Error!", "OTT Detail already exists!", "error");
      return;
    }
  
    // Check if the value is unchanged before showing the confirmation popup
    const currentRow = ottRows.find((row) => row._id === editingOttRowId);
    if (currentRow && currentRow.ottDetail === editOttValue.trim()) {
      Swal.fire(
        "No Update Found",
        "No changes were made to the OTT details.",
        "info"
      );
      setEditingOttRowId(null); // Exit edit mode
      return; // Exit without showing confirmation popup
    }
  
    // Show confirmation dialog for saving changes
    Swal.fire({
      title: "Do you want to save the changes?",
   
      showCancelButton: true,
      confirmButtonText: "Save",
 
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(apiUrl + "updateott", {
            ottId: editingOttRowId,
            ottDetail: editOttValue,
          });
  
          Swal.fire("Saved!", response.data.Message, "success");
  
          // Update the specific row in the table
          setOttRows((prev) =>
            prev.map((row) =>
              row._id === editingOttRowId
                ? { ...row, ottDetail: editOttValue }
                : row
            )
          );
  
          setEditingOttRowId(null); // Exit edit mode
        } catch (error) {
          Swal.fire("Error!", "Failed to update OTT Details.", "error");
          console.error("Edit Error:", error);
        }
      }  else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle cancel button click
        setEditingOttRowId(null); // Exit edit mode
      }
    });
  };
  

  //______________________
  const [isUnique, setIsUnique] = useState(true);
  const [uniqueError, setUniqueError] = useState("");
  useEffect(() => {
    if (ottDetail) {
      checkRole(ottDetail);
    }
  }, [ottDetail]);


  const checkRole = async (ottID) => {
    if (typeof ottID !== "string" && typeof ottID !== "number") {
      return;
    }
  
    try {
      const response = await axios.get(`${apiUrl}checkott/${ottID}`);
      if (response.data.exists) {
        setIsUnique(false);
        setUniqueError("OTT Name already exists!");
      } else {
        setIsUnique(true);
        setUniqueError("");
      }
    } catch (error) {
      console.error("Error checking OTT Id:", error);
    }
  };
  
  // Handle Cancel Action
 
  

  const handleOttInputChange = (e) => {
    const value = e.target.value; // Extract the input value

    if (ottDetail?.actualValue !== value && value) {
      checkRole(value); // Pass the raw value to `checkRole`
    } else {
      setUniqueError("");
      setIsUnique(true); // Reset state
    }

    setOttDetail(value); // Update the state
  };

  //_________________

  const handleOttCancel = () => {
    // Reset input values if necessary
    setOttInputValue(""); // Assuming `inputValue` holds the input state
    setOttIsAdding(false); // Exit the addition mode
    setIsUnique(null); // Reset uniqueness check state
    setUniqueError(""); // Clear any existing error message
    setOttDetail("");

  };

  return (
    <>
      <Topbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h3 className="head mt-4">Settings</h3>
          </div>
          <div className="col-md-6 col-sm-12">
            {!ottIsAdding && addButtonEnableOtt && (
              <button
                className="black-btn add-btn mt-4"
                onClick={handleOttAddRow}
              >
                Add
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <table className="table-border">
            <thead className="table-th">
              <tr className="ott-head">
                <th className="table-th-content table-head">OTT Details</th>
                <th className="table-th-content table-head text-center">
                  Operations
                </th>
              </tr>
            </thead>
            <tbody>
              {ottIsAdding && (
                <tr>
                  <td className="ott-td">
                    <input
                      required
                      type="text"
                      value={ottDetail}
                      onChange={handleOttInputChange}
                      placeholder="Enter OTT Details"
                      className="adding-input"
                      style={{ textTransform: "capitalize" }}
                    />
                  </td>

                  <td className="ott-td d-flex">
                    <button
                      className="blue-btn-new mx-3"
                      onClick={handleOttSubmit}
                      value="submit"
                    >
                      Submit
                    </button>
                    <button
                      className="cancle-btn mx-3"
                      onClick={handleOttCancel}
                      value="cancel"
                      // disabled={!isUnique}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              )}
              {!isUnique && <p className="text-danger">{uniqueError}</p>}

              {ottRows.length === 0 &&!ottIsAdding? (
    <tr>
      <td colSpan="2" className="text-center text-muted p-3">
        No data found
      </td>
    </tr>
  ) : (
              ottRows.map((row) => (
                <tr key={row._id}>
                  <td className="ott-td">
                    {editingOttRowId === row._id ? (
                      <input
                        required
                        type="text"
                        value={editOttValue}
                        onChange={handleOttEditChange}
                        className="editing-input"
                        style={{ textTransform: "capitalize" }}
                      />
                    ) : (
                      row.ottDetail
                    )}
                  </td>
                  <td className="ott-td">
                    {editingOttRowId === row._id ? (
                      <button
                        className="blue-btn mx-3"
                        onClick={handleOttEditSave}
                      >
                        Save
                      </button>
                    ) : (
                      <>
                        <div className="d-flex icon-div">
                          <div className="mr-3">
                            <MdModeEdit
                              className="edit-icon"
                              onClick={() => handleOttEdit(row)}
                            />
                            Edit
                          </div>
                          <div>
                            <MdDelete
                              className="delete-icon"
                              onClick={() => handleOttDelete(row._id)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </td>
                  {ottEditError && (
                    <p className="text-danger">{ottEditError}</p>
                  )}{" "}
                  {/* Show error in red */}
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Ottdetails;
