import React, { useState,useEffect  } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FaSearch, FaRegBell } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./Topbar.css";
import { FaArrowLeft } from "react-icons/fa6";
import { memo } from "react";
import Swal from "sweetalert2";
import { useSelector } from 'react-redux';


const Header = () => {
  const staffId = useSelector((state) => state.staffid);
  const staffName = useSelector((state) => state.staffname);
  const email = useSelector((state) => state.email);
  const designation = useSelector((state) => state.designation);

  const navigate = useNavigate();
  useEffect(() => {
    if (staffId ==="") {
      navigate("/"); // If logged out, stay on the login page
    }
  }, [staffId, navigate]);

  const [showPopUp, setShowPopUp] = useState(false);

  const handlePopUp = () => {
    setShowPopUp(!showPopUp);
  };

 
  const goback = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleClose = () => {
    setShowPopUp(false);
  };
  const handleButtonClick = () => {
    setShowPopUp(true);
  }; 
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Logged Out!",
          text: "You have been successfully logged out.",
          icon: "success",
          timer: 2000, // Auto close after 2 seconds
          showConfirmButton: false
        });

        // Navigate to logout page after 2 seconds
        setTimeout(() => {
          navigate("/logout");
        }, 2000);
      }
    });
  }
  return (
 
 
    <Container fluid className="mt-3 header-container">
      <Row className="abc" xs={12}>
        <Col className="" sm="auto" xs={12}>
          <h2>
            Hi, <span style={{ color: "#2A7BBA" }}>{staffName}</span>{" "}
            <span>&#128075;</span>
          </h2>
        </Col>

        <Col className="xyz" sm="auto" xs={12}>
          <button onClick={goback} className="arrow">
            <FaArrowLeft className="iconarr " />
          </button>

          <div className="position-relative">
            <input type="text" placeholder="Search" className="form-control p-2 border-2" />
            <FaSearch className="search-icon" />
          </div>

          <span className="rowdwn">
            <select className="inputsearch">
              <option>Select</option>
              <option>Customer Id</option>
              <option>Customer Name</option>
              <option>Customer Number</option>
            </select>
          </span>

          <div className="headLast">
           <div className="profile-circle" onClick={handleButtonClick}>
           <span className="initial">{email.charAt(0).toUpperCase()}</span>

          </div>
          <div className="notification">
            <FaRegBell size={25} />
            <span className="notification-dot"></span>
          </div></div>
          

         
        </Col>
      </Row>

      {showPopUp && (
        <div className="popup-header ">
          <button className="setting-close-btn" onClick={handleClose}>
            &times;
          </button>
          <div className="popup-content">
            <p className="text-center">
              <button className=" btn btn-primary  logopop text-white  ">
 
                {" "}
                {email.charAt(0).toUpperCase()}

              </button>{" "}
              {email}

            </p>
            <div>
              <hr className="hori1" />
            </div>
            <br />

            <container className="fontsize ">
              <Row className="rowsize">
                <Row>
                  <Col className="user">Username</Col>
                  <Col className="colon">:</Col>
                  <Col className="userid">{staffName}</Col>
                </Row>

                <Row>
                  <Col className="  user">Designation</Col>
                  <Col className="colon1">:</Col>
                  <Col className="userid">{designation}</Col>
                </Row>
              </Row>
            </container>

            <hr className="hori1 mt-4" />

            <h6 className="text-center">Logout</h6>

            <button
              onClick={handleLogout }
              className="text-center text-primary pt-1 logoutbtn"
            >
              <BiLogOut size={50} />
            </button>
          </div>
        </div>
      )}

      <hr />
    </Container>
  );
};

export default memo(Header);

 