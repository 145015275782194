import React, { useState, useEffect } from "react";
import "./Roles.css";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";

const Roles = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [rows, setRows] = useState([]);
  const [role, setRole] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [addButtonEnable, setAddButtonEnable] = useState(true);
 
  const [inputValue,setInputValue]=useState("")
  const handleAddRow = () => {
    setIsAdding(true);
  };

  //______________________
  const [isUnique, setIsUnique] = useState(true);
  const [uniqueError, setUniqueError] = useState("");
  useEffect(() => {
    if (role) {
      checkRole(role);
    }
  }, [role]);

  const checkRole = async (roleId) => {
    // Check for invalid roleId input
    if (typeof roleId !== "string" && typeof roleId !== "number") {
      return;
    }
    try {
      const response = await axios.get(`${apiUrl}checkrole/${roleId}`);
      if (response.data.exists) {
        setIsUnique(false);
        setUniqueError("Role Name already exists!");
      } else {
        setIsUnique(true);
        setUniqueError("");
      }
    } catch (error) {
      console.error("Error checking Role ID:", error);
    }
  };
  
  
  

  const handleInputChange = (e) => {
    const value = e.target.value; // Extract the input value

    if (role?.actualValue !== value && value) {
      checkRole(value); // Pass the raw value to `checkRole`
    } else {
      setUniqueError("");
      setIsUnique(true); // Reset state
    }

    setRole(value); // Update the state
  };

  //_________________

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!role.trim()) {
      Swal.fire("Warning!", "Please enter Role data.", "warning");
      return;
    }
    try {
      const response = await axios.post(apiUrl + "addrole", {
        role,
      });

      Swal.fire("Success!", response.data.message, "success");

      setRows((prev) => [...prev, { _id: response.data.id, role }]);
      setIsAdding(false);
      setRole("");
    } catch (error) {
     console.error("Error submitting data", error);

     // Handle errors properly
     const errorMessage =
       error.response?.data?.message || "Failed to submit data.";
     Swal.fire("Error!", errorMessage, "error");
   }
  };

  useEffect(() => {
    axios
      .post(apiUrl + "viewrole", {})
      .then((res) => {
        setRows(res.data.message);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.post(apiUrl + "deleterole", {
          _id: id,
        });

        Swal.fire("Deleted!", response.data.Message, "success");
        setRows((prev) => prev.filter((row) => row._id !== id));
      } catch (error) {
        Swal.fire("Error!", "Failed to delete Roles.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  const handleEdit = (row) => {
    setEditingRowId(row._id);
    setEditValue(row.role);
    setAddButtonEnable(false);
  };

  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  // const handleEditSave = async () => {
  //   setAddButtonEnable(true);
  //     // Check if the edited role already exists
  // const isDuplicate = rows.some(
  //   (row) => row.role.toLowerCase() === editValue.trim().toLowerCase() && row._id !== editingRowId
  // );

  // if (isDuplicate) {
  //   Swal.fire("Error!", "Role Name already exists!", "error");
  //   return;
  // }
  //   Swal.fire({
  //     title: "Do you want to save the changes?",
  //     showDenyButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: "Save",
  //     denyButtonText: `Don't save`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       // Check if the value is unchanged
  //       const currentRow = rows.find((row) => row._id === editingRowId);
  //       if (currentRow && currentRow.role === editValue.trim()) {
  //         Swal.fire(
  //           "No Update Found",
  //           "No changes were made to the Roles.",
  //           "info"
  //         );
  //         setRows((prev) =>
  //           prev.map((row) =>
  //             row._id === editingRowId ? { ...row, role: editValue } : row
  //           )
  //         );

  //         setEditingRowId(null);
  //         return; // Exit without calling the API
  //       }

  //       try {
  //         const response = await axios.post(apiUrl + "updaterole", {
  //           roleId: editingRowId,
  //           role: editValue,
  //         });

  //         Swal.fire("Saved!", response.data.Message, "success");

  //         // Update the specific row in the table
  //         setRows((prev) =>
  //           prev.map((row) =>
  //             row._id === editingRowId ? { ...row, role: editValue } : row
  //           )
  //         );

  //         setEditingRowId(null); // Exit edit mode
  //       } catch (error) {
  //         Swal.fire("Error!", "Failed to update Roles.", "error");
  //         console.error("Edit Error:", error);
  //       }
  //     } else if (result.isDenied) {
  //       Swal.fire("Changes are not saved", "", "info");
  //       setRows((prev) =>
  //         prev.map((row) =>
  //           row._id === editingRowId ? { ...row, role: editValue } : row
  //         )
  //       );

  //       setEditingRowId(null);
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       // Handle cancel button click
  //       setEditingRowId(null); // Exit edit mode
  //     }
  //   });
  // };
  const handleEditSave = async () => {
    setAddButtonEnable(true);
  
    // Check if the edited role already exists
    const isDuplicate = rows.some(
      (row) =>
        row.role.toLowerCase() === editValue.trim().toLowerCase() &&
        row._id !== editingRowId
    );
  
    if (isDuplicate) {
      Swal.fire("Error!", "Role already exists!", "error");
      return;
    }
    const currentRow = rows.find((row) => row._id === editingRowId);
    if (currentRow && currentRow.role === editValue.trim()) {
      Swal.fire(
        "No Update Found",
        "No changes were made to the Roles.",
        "info"
      );
      setEditingRowId(null); // Exit edit mode
      return; // Exit without showing confirmation popup
    }
  
    // Show confirmation dialog for saving changes
    Swal.fire({
      title: "Do you want to save the changes?",
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(apiUrl + "updaterole", {
            roleId: editingRowId,
            role: editValue,
          });
    
          Swal.fire("Saved!", response.data.Message, "success");
    
          // Update the specific row in the table
          setRows((prev) =>
            prev.map((row) =>
              row._id === editingRowId ? { ...row, role: editValue } : row
            )
          );
    
          setEditingRowId(null); // Exit edit mode
        } catch (error) {
          Swal.fire("Error!", "Failed to update Roles.", "error");
          console.error("Edit Error:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle cancel button click
        setEditingRowId(null); // Exit edit mode
      }
    });
    
  };
  
  const handleCancel = () => {
    // Reset input values if necessary
    setInputValue(''); // Assuming `inputValue` holds the input state
    setIsAdding(false); // Exit the addition mode
    setRole(""); // Clear the role ID input field
    setIsUnique(null); // Reset uniqueness validation state
    setUniqueError(""); // Clear any error messages
  };

  return (

    <>
      <Topbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h3 className="head mt-4">Roles</h3>
          </div>
          <div className="col-md-6 col-sm-12">
            {!isAdding && addButtonEnable && (
              <button className="black-btn add-btn mt-4" onClick={handleAddRow}>
                Add
              </button>

            )}
          </div>
        </div>
        <div className="row">
          <table className="table-border">
            <thead className="table-th">
              <tr className="role-head">
                <th className="table-th-content table-head">Roles</th>
                <th className="table-th-content table-head text-center">
                  Operations
                </th>
              </tr>
            </thead>
            <tbody>
  {isAdding && (
    <tr>
      <td className="role-td">
        <input
          type="text"
          required
          onChange={handleInputChange}
          placeholder="Enter Role"
          className="adding-input"
          style={{ textTransform: "capitalize" }}
        />
      </td>
      <td className="role-td d-flex">
        <button
          className="blue-btn-new mx-3"
          onClick={handleSubmit}
          value="submit"
        >
          Submit
        </button>
        <button
          className="cancle-btn mx-3"
          onClick={handleCancel}
          value="cancel"
        >
          Cancel
        </button>
      </td>
    </tr>
  )}

  {!isUnique && <p className="text-danger">{uniqueError}</p>}

  {rows.length === 0 &&!isAdding ? (
    <tr>
      <td colSpan="2" className="text-center text-muted p-3">
        No data found
      </td>
    </tr>
  ) : (
    rows.map((row) => (
      <tr key={row._id}>
        <td className="role-td">
          {editingRowId === row._id ? (
            <input
              required
              type="text"
              value={editValue}
              onChange={handleEditChange}
              className="editing-input"
              style={{ textTransform: "capitalize" }}
            />
          ) : (
            row.role
          )}
        </td>
        <td className="role-td">
          {editingRowId === row._id ? (
            <button className="blue-btn mx-3" onClick={handleEditSave}>
              Save
            </button>
          ) : (
            <div className="d-flex icon-div">
              <div>
                <MdModeEdit
                  className="edit-icon"
                  onClick={() => handleEdit(row)}
                />
                Edit
              </div>
              <div>
                <MdDelete
                  className="delete-icon"
                  onClick={() => handleDelete(row._id)}
                />
              </div>
            </div>
          )}
        </td>
      </tr>
    ))
  )}
</tbody>

          </table>
        </div>
      </div>
    </>
  );
};

export default Roles;
