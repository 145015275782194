import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PiGreaterThanBold } from "react-icons/pi";
import "./Settings.css";
import { useNavigate } from "react-router-dom";
import Topbar from "../Topbar/Topbar";

const Settings = () => {
  const navigate = useNavigate();
  const ottLesser = () => {
    navigate("/ottdetails");
  };
  const leadLesser = () => {
    navigate("/leadsource");
  };

  return (
    <Container>
      <Topbar />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h3 className="head mt-3">Settings</h3>
          </div>
        </div>
        <div className="row">
          <table className="table-border ">
            <thead className="table-th">
              <tr>
                <th className="table-th-content table-head edit-head">
                  {" "}
                  Edit Settings
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                
                <td className="setting-td">
                  OTT Details 
                  <PiGreaterThanBold
                    className="lesser-icon1"
                    onClick={ottLesser}
                  />
                </td>

              </tr>
              <tr>
                <td className="setting-td ">
                  Lead Sources 
                  <PiGreaterThanBold
                    className="lesser-icon2"
                    onClick={leadLesser}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default Settings;
