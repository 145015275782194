import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState,   } from "react";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import Topbar from "../Topbar/Topbar";

export default function Dues() {
  const navi = useNavigate();

  const add = () => {
    navi("/adddues");
  };

  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [email, setEmail] = useState("");
  //   const [errors, setErrors] = useState({});

  const phoneRegex = /^[7-9]{1}[0-9]{9}$/;

  // Regex for validating email
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber1") {
      setPhoneNumber1(value);
    } else if (name === "phoneNumber2") {
      setPhoneNumber2(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate Phone Number 1
    if (!phoneRegex.test(phoneNumber1)) {
      newErrors.phoneNumber1 =
        "Please enter a valid 10-digit phone number (starting with 7-9)";
    }

    // Validate Phone Number 2 (Optional)
    if (phoneNumber2 && !phoneRegex.test(phoneNumber2)) {
      newErrors.phoneNumber2 =
        "Please enter a valid 10-digit phone number (starting with 7-9)";
    }

    // Validate Email
    if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    return newErrors;
  };

  const [setting, setSetting] = useState(false);
  const settingPopup = (e) => {
    e.preventDefault();
    setSetting(!setting);
  };

  const gotocustomeredit = (customerid) => {
    navi("/edit_enquiry", {
      state: { customerid: customerid },
    });
  };

  return (
    <div className="customer-scroll-stop">
      <Topbar />
      <Container fluid className=" pt-1 customer-row ">
        <h3 className=" px-2  ">Dues</h3>

        <Row className=" row-width">
          <Col className="dashborder mt-4   mb-5 mx-2 ">
            <Row className="yellow-card">
              <p className="text-center box-txt " style={{ marginTop: "6px" }}>
                Open
              </p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">03</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1  ">
            <Row className="grey-card">
              <p className="text-center pt-1 box-txt">Paid</p>
            </Row>
            <Row className="number">
              <h5 className="text-center">06</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5  mx-1">
            <Row className="orange-card ">
              <p className="text-center pt-1 box-txt">Reminder 1</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">10</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="pink-card row-margin">
              <p className="text-center pt-1 box-txt">Reminder 2</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">05</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="purple-card">
              <p
                className="text-center pt-1 box-txt "
                style={{ marginTop: "8px" }}
              >
                Reminder 3
              </p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">08</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="blue-card">
              <p className="text-center pt-1 px-1 box-txt"> > 3 Reminder </p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">04</h5>
            </Row>{" "}
          </Col>
          <Col className="dashborder mx-1  mt-4 mb-5">
            <Row className="green-card">
              <p className="text-center pt-1 box-txt">Suspended</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">02</h5>
            </Row>
          </Col>
          <Col className="dashborder mx-1  mt-4 mb-5">
            <Row className="red-card">
              <p className="text-center pt-1 box-txt">Disable</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">02</h5>
            </Row>
          </Col>
        </Row>

        <Row className=" button-bottom-custmer" xs={1} sm={2}>
          <Col className="mt-2 mb-2">
            <button onClick={add} className="black-btn btnadd  black-btn1">
              Add
            </button>{" "}
            &emsp;&emsp;
            <button
              onClick={() => window.location.reload()}
              className="black-btn black-btn1"
            >
              Refresh
            </button>
          </Col>

          <Col className="btn-Ccolumn2 mt-2">
            <button className="black-btn black-btn1 btnadd">Export</button>
            &emsp;&emsp;
            <button onClick={settingPopup} className="black-btn black-btn1 ">
              Settings
            </button>
            {setting && (
              <div className="setting-popup-overlay">
                <div className="setting-popup-container">
                  <button className="setting-close-btn" onClick={settingPopup}>
                    &times;
                  </button>
                  <div className="setting-popup-content">
                    <div className="setting-checkbox-row">
                      <label>
                        <input type="checkbox" />
                        <span
                          className="checkbox-down"
                          style={{ fontSize: "25" }}
                        >
                          Select All
                        </span>
                      </label>
                    </div>
                    <div className="setting-checkbox-grid">
                      {[
                        "Customer ID",
                        "Expiry Date",
                        "Customer Name",
                        "Device Status",
                        " Installation Address",
                        "Contact Number",
                        "Alternate Number",
                        "Mail ID",
                        "Account Manager",
                        "Refund Deposit",
                        "Activation Date",
                        "Last Renewal Date",
                        "Last Logoff Date",
                        "Billing Address",
                        "Plan Name",
                        "Account Status",
                        "Installation charges",
                        "Refund Description",
                        "Area",
                      ].map((label, index) => (
                        <label key={index}>
                          <input type="checkbox" />
                          <span className="checkbox-down">{label}</span>
                        </label>
                      ))}
                    </div>
                    <button
                      className="setting-submit-btn"
                      onClick={settingPopup}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
             <button className="mapImage ms-4"  ></button>
          </Col>
        </Row>

        <br />

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            <thead className="table-th text-center">
              <tr>
                <th className="table-th-content">Due ID</th>
                <th className="table-th-content">Due Status</th>
                <th className="table-th-content">Invoice Date</th>
                <th className="table-th-content">Customer Name</th>
                <th className="table-th-content">Contact Number</th>
                <th className="table-th-content">Alternate Number</th>
                <th className="table-th-content">Address</th>
                <th className="table-th-content">Notes</th>
                <th className="table-th-content">Follow Up Person</th>
                <th className="table-th-content">Total Bill Amount</th>
                <th className="table-th-content">Due Amount</th>
                <th className="table-th-content">Expected Payment Date</th>

                <th className="table-th-content">Delete</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="table-search background-change"
                    name="enquiryleadId"
                    placeholder="Search.."
                  />
                </td>
                <td>
                  <select
                    className="table-search background-change"
                    name="enquiryleadStatus"
                  >
                    <option>Select</option>
                    <option value="Open">Open</option>
                    <option value="Paid">Paid</option>
                    <option value="Disabled">Disabled</option>
                    <option value="Reminder1">Reminder 1</option>
                    <option value="Reminder2">Reminder 2</option>
                    <option value="Reminder3">Reminder 3</option>
                    <option value=">3Reminder"> >3 Reminder </option>
                    <option value="Suspended">Suspended</option>
                  </select>
                </td>
                <td>
                  <input
                    className="table-search background-change"
                    type="date"
                    name="enquiryleadStartedDate"
                  />
                </td>
                <td>
                  <input
                    className="table-search background-change"
                    type="text"
                    style={{ textTransform: "capitalize" }}
                    name="enquiryaccountManager"
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    className="table-search background-change"
                    type="tel"
                    onKeyDown={(e) => {
                      if (
                        ["e", "E", "+", "-"].includes(e.key) ||
                        (e.key &&
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight")
                      ) {
                        e.preventDefault();
                      }
                    }}
                    style={{ textTransform: "capitalize" }}
                    name="enquirycustomerName"
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    type="tel"
                    onKeyDown={(e) => {
                      if (
                        ["e", "E", "+", "-"].includes(e.key) ||
                        (e.key &&
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight")
                      ) {
                        e.preventDefault();
                      }
                    }}
                    className="table-search background-change"
                    name="enquiryemailId"
                    placeholder="Search "
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    className="table-search background-change"
                    style={{ textTransform: "capitalize" }}
                    name="enquiryaddress"
                    placeholder="Search "
                  />
                </td>
                <td></td>
              
                <td>
                  <select
                    className="table-search background-change"
                    name="enquiryleadSource"
                  >
                    <option>Select</option>
                    <option value="Saravanan">Saravanan</option>
                    <option value="Ravikumar">Ravikumar</option>
                    <option value="Santhosh">Santhosh</option>
                  </select>
                </td>

                <td>
                  <input
                    type="tel"
                    onKeyDown={(e) => {
                      if (
                        ["e", "E", "+", "-"].includes(e.key) ||
                        (e.key &&
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight")
                      ) {
                        e.preventDefault();
                      }
                    }}
                    className="table-search background-change"
                    name="enquiryemailId"
                    placeholder="Search "
                  />
                </td>

                <td>
                  <input
                    type="tel"
                    onKeyDown={(e) => {
                      if (
                        ["e", "E", "+", "-"].includes(e.key) ||
                        (e.key &&
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight")
                      ) {
                        e.preventDefault();
                      }
                    }}
                    className="table-search background-change"
                    name="enquiryemailId"
                    placeholder="Search "
                  />
                </td>

            
                <td>
                  <input
                    type="date"
                    className="table-search background-change"
                    name="enquiryupdatedAt"
                  />
                </td>

              
 
                <td></td>
              </tr>

              <tr>
                <td> 1</td>
                <td>2 </td>
                <td>3 </td>
                <td> 4</td>
                <td> 5</td>
                <td>6 </td>
                <td>7 </td>
                <td>8 </td>
                <td> 9</td>
                <td> 0</td>
                <td> 1</td>
                <td>2 </td>
                 

                <td>
                  <button className="deletebtn text-center">
                    <MdDelete size={20} />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
}
