import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import { FaStarOfLife } from "react-icons/fa6";
import { Modal, Box, Button } from "@mui/material";
import { ImCross } from "react-icons/im";
import { FiX, FiUpload } from "react-icons/fi"; // Close & Upload icons
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./Add_installation.css"
import { useEffect } from "react";



export default function Add_Installation() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;


  // const[addInstall, setAddInstall]=useState({
  //   leadId:"",
  //   createdDate:"",

  //   installcustomerName:"",
  //   installcontactNum:"",
  //   enquiryalternateNum:"",
  //   enquiryaddress:"",
  //   enquirylocation:"",
  //   enquiryleadSource:"",
  //   enquiryaccountManager:"",
  //   enquiryemailId:"",
  //   enquirysalesName:"",

  //     enquiryplanDetails: {
  //       enquiryplanSpeed: "",
  //       enquiryplanMonth: "",
  //       enquiryplanAmount: "",
  //       enquirystaticIpCost: "",
  //       enquiryfiberCableMeter: "",
  //       enquiryinstalationCost: "",
  //       enquiryrouterModel: "",
  //       enquiryrouterCost: "",
  //       enquiryottPackDetails: "",
  //       enquiryothers: "",
  //       enquiryothersCost: "",
  //       enquiryadvanceAmount:{
  //         enquirypay:{
  //           enquirycurrentDate:"",
  //         enquiryammount:"",
  //         enquirypaymentType:"",
          
  //         enquiryreference:"",
  //         },
          
          
  //       }
  //   },
   
  //   enquirynextFollowUpDate:"",
  //   enquiryleadStatus:{
  //     enquirynextFollowUpDate:"",
  // enquirynotes:""
  //   },
   
   


  // })


  const [addInstall, setAddInstall] = useState({
   leadId: "",
    createdDate: "",
  
    installcustomerName: "",
installcontactNum: "", 
    installalternateNum: "",
    installaddress: "",
    installlocation: "",
    installleadSource: "",
    installemailId: "",
    installsalesName: "",
    installplanDetails: {
      installplanSpeed: "",
      installplanMonth: "",
      installplanAmount: "",
      installstaticIpCost: "",
      installfiberCableMeter: "",
      installinstalationCost: "",
      installrouterModel: "",
      installrouterCost: "",
      installottPackDetails: "",
      installothers: "",
      installothersCost: "",
      installadvanceAmount: {
        installcurrentDate: "",
        installamount: "",  
        installpaymentType: "",
        installreferenceNumber: "" 
      }
    },
    installnextFollowUpDate: "",

  
    installleadStatus: {
      installnotes: "",
      installLeadStatusnextFollowUpDate: "",

      
    }
  }
  );
  
 
  const [selectedFile, setSelectedFile] = useState(null);


  const[isOpenAdvancePayment,setIsOpenAdvancePayment]=useState(false)

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  

  const [isOpenplan, setIsOpenplan] = useState(false);
  const [isOpenConverted, setIsOpenConverted] = useState(false);

  const navigate=useNavigate();

    //  useEffect(() => {
    //    const fetchLastLeadId = async () => {
    //      try {
    //        const res = await axios.get("http://localhost:3001/api/last-lead-id");
    //        const lastLeadId = res.data.lastLeadId;
    //        const numericPart = parseInt(lastLeadId.substring(1)) + 1;
    //        const nextLeadId = `A${numericPart.toString().padStart(2, "0")}`;
    //        setAddEnquiry({
    //          ...addEnquiry,
             
    //          leadId: nextLeadId,
    //          createdDate: new Date().toISOString().split("T")[0]
    //          // Current date
    //        });
    //      } catch (error) {
    //        console.error("Error fetching last Lead ID", error);
    //      }
    //    };
   
    //    fetchLastLeadId();
    //  }, []);
    // useEffect(() => {
    //   const fetchLastLeadId = async () => {
    //     try {
    //       const res = await axios.get("http://localhost:3001/api/last-lead-id");
    //       const lastLeadId = res.data.lastLeadId;
    //       const numericPart = parseInt(lastLeadId.substring(1)) + 1;
    //       const nextLeadId = `E - ${numericPart.toString().padStart(2, "0")}`;
    //       setAddEnquiry({
    //         ...addEnquiry,
    //         leadId: nextLeadId,
    //         createdDate: new Date().toISOString().split("T")[0], // Current date
    //       });
    //     } catch (error) {
    //       console.error("Error fetching last Lead ID", error);
    //     }
    //   };
  
    //   fetchLastLeadId();
    // }, []);


    useEffect(() => {
      fetch("http://localhost:3001/api/generate-lead-id")
        .then((res) => res.json())
        .then((data) => {
          console.log("Fetched Lead ID:", data.leadId); // Debugging log
    
          setAddInstall((prev) => ({
            ...prev, // Spread previous state
            leadId: data.leadId, // Update only the leadId field
            createdDate: new Date().toISOString().split("T")[0], // Set today's date
          }));
        })
        .catch((error) => console.error("Error fetching Lead ID:", error));
    }, []);
    





     const handlePlanChange = (e) => {
      setAddInstall({
        ...addInstall,
        installplanDetails: {
          ...addInstall.installplanDetails,
          [e.target.name]: e.target.value
        }
      });
    };

    const handleAmountChange = (e) => {
      const { name, value } = e.target;
      setAddInstall(prevState => ({
          ...prevState,
          installplanDetails: {
              ...prevState.installplanDetails,
              installadvanceAmount: {
                  ...prevState.installplanDetails.installadvanceAmount,
                  [name]: value
              }
          }
      }));
  };


  const handleLeadChange = (e) => {
    const { name, value } = e.target;
    setAddInstall(prevState => ({
        ...prevState,
        installleadStatus: {
            ...prevState.installleadStatus,
            [name]: value
        }
    }));
};


  
    
  
     const handleChange = (e) => {
       setAddInstall({ ...addInstall, [e.target.name]: e.target.value });
     };
     const [errors, setErrors] = useState({}); // Validation errors


     
     const handleSubmit = async (e) => {
      e.preventDefault();
      
        if (addInstall.installcontactNum === "") {
          alert("Phone number is required!");
      }
      

    

      
  
      Swal.fire({
          title: "Do you want to save the Customer Details?",
          showCancelButton: true,
          confirmButtonText: "Save",
      }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                  const response = await axios.post(
                      "http://localhost:3001/api/add-lead-install",
                      addInstall,
                      { headers: { "Content-Type": "application/json" } } // 👈 Ensure JSON format
                  );
  
                  Swal.fire({
                      title: "Customer Details Added Successfully!",
                      icon: "success",
                  }).then(() => {
                      navigate("/installation");
                  });
              } catch (error) {
                  console.log("Error submitting data:", error.response?.data || error.message);
                  Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.response?.data?.message || "Something went wrong!",
                  });
              }
          }
      });
  };
  

  return (
    <>
      <Topbar />
      <Container className="mt-1    ">
        <form>
          <Row>
            <Col>
              <h3>Installation</h3>
            </Col>

            <Col className="mx-5 text-end">
              <button
              onClick={handleSubmit}
                className=" blue-btn  px-4 text-white"
                value="submit"
                type="submit"
              >
                Submit
              </button>
            </Col>
          </Row>

          <div className="border-container1">
            <h6 className="form-head  m-0 text-white  ">Order Details</h6>
          </div>

          <div className="border-container ">
            <Row className="text-start lead-id-row  mb-4 mt-4 ms-4 ">
              <p className="ps-5 paragraph-lead-id">
                Lead ID - <span className="text-primary " value={addInstall.leadId} onChange={handleChange}>
                  {addInstall.leadId}</span>{" "}
                <span className="lead-start-date">
                  {" "}
                  Lead Started Date -{" "}
                  <span className="text-primary">{addInstall.createdDate}</span>
                </span>{" "}
              </p>
            </Row>
            <Row className="ms-3">
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Name</lable>
                <br />
                <input
                  type="text"
                  style={{ textTransform: "capitalize " }}
                  className="form-input "
                  placeholder="Enter Custmer Name"
                  value={addInstall.customerName} onChange={handleChange}
                  name="installcustomerName"
                />
              </Col>

              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>
                      Contact Number
                      <sup>
                        <FaStarOfLife size={7} />
                      </sup>
                    </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Enter Phone Number"
                      className="form-input"
                      value={addInstall.installcontactNum} onChange={handleChange}
                      name="installcontactNum"
                      maxLength={10}
                      required

                    />


                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <lable>Alternate Number </lable>
                    <br />
                    <input
                      type="tel"
                      onKeyDown={(e) => {
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (e.key &&
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      value={addInstall.installalternateNum} onChange={handleChange}
                      name="installalternateNum"
                      maxLength={10}

                      className="form-input"
                      placeholder="Enter Phone Number"
                      
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
                <lable> Address</lable>
                <br />
                <textarea
                  type="text"
                  value={addInstall.installaddress} onChange={handleChange}

                  style={{ textTransform: "capitalize", resize: "none" }}
                  className=" box address-install"
                  placeholder="Enter  Address"
                  name="installaddress"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Location</lable>
                    <br />
                    <input
                     value={addInstall.installlocation} onChange={handleChange}

                      style={{ textTransform: "capitalize" }}
                      className="form-input"
                      type="text"
                      placeholder="Enter Location"
                      name="installlocation"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Lead Source</lable>
                    <br />

                    <select
                  value={addInstall.installleadSource} onChange={handleChange}
                  class="dropbtn form-input-select"
                  name="installleadSource"
                    >
                      <option value="">Select</option>
                      <option className="onlineback" value="Online">
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Sales Name</lable>
                    <br />

                    <select
                  value={addInstall.installsalesName} onChange={handleChange}
                  name="installsalesName"
                  class="dropbtn form-input-select"
                    >
                      <option value="">Select</option>
                      <option className="onlineback" value="Online">
                        Online
                      </option>
                      <option className="offlineback" value="Offline">
                        Offline
                      </option>
                    </select>
                  </Col>

                  <Col xs={12} md={6} className="text-start">
                    <label></label>
                    <input
                      type="button"
                      value="Plan details"
                      onClick={() => setIsOpenplan(true)}
                      className="form-input converted-form plandetails-form "
                         onChange={handleChange}
                      

                    
                       />
                     

                   

                    {isOpenplan && (
                      <div className="overlay1">
                        <div className="modal1">
                          <Container>
                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Plan Speed</lable>
                                    <br />
                                    <input
                                      type="text"
                                      value={addInstall.installplanDetails.installplanSpeed} onChange={handlePlanChange}
                                    name="installplanSpeed"
                                      className="form-input overlay2"
                                      placeholder="Enter Plan Speed"
                                    />
                                  </Col>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Plan Month</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addInstall.installplanDetails.installplanMonth} onChange={handlePlanChange}
                                      name="installplanMonth"

                                      className="form-input overlay2"
                                      placeholder="Enter Plan Month"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Plan Amount</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addInstall.installplanDetails.installplanAmount} onChange={handlePlanChange}
                                      name="installplanAmount"

                                      placeholder="Rs"
                                      className="form-input overlay2"
                                    />
                                  </Col>

                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Static Ip Cost </lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addInstall.installplanDetails.installstaticIpCost} onChange={handlePlanChange}
                                      name="installstaticIpCost"

                                      placeholder="Rs"
                                      className="form-input overlay2"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Fiber Cable Meter</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      
                                      value={addInstall.installplanDetails.installfiberCableMeter} onChange={handlePlanChange}
                                      name="installfiberCableMeter"

                                      className="form-input overlay2"
                                      placeholder="Enter Meter"
                                    />
                                  </Col>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Installation Cost</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addInstall.installplanDetails.installinstalationCost} onChange={handlePlanChange}
                                      name="installinstalationCost"

                                      className="form-input overlay2"
                                      placeholder="Enter installation Cost"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Router Model</lable>
                                    <br />
                                    <select
                                  value={addInstall.installplanDetails.installrouterModel} onChange={handlePlanChange}
                                  name="installrouterModel"

                                      class="dropbtn form-input-select overlay2"
                                    >
                                      <option value="">Select</option>
                                      <option
                                        className="onlineback"
                                        value="Online"
                                      >
                                        Online
                                      </option>
                                      <option
                                        className="offlineback"
                                        value="Offline"
                                      >
                                        Offline
                                      </option>
                                    </select>
                                  </Col>

                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Router Cost </lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addInstall.installplanDetails.installrouterCost} onChange={handlePlanChange}
                                      name="installrouterCost"

                                      className="form-input overlay2"
                                      placeholder="Enter installation Cost"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Ott Pack Meter</lable>
                                    <br />
                                    <select
                                   value={addInstall.installplanDetails.installottPackDetails} onChange={handlePlanChange}
                                   name="installottPackDetails"

                                      class="dropbtn form-input-select overlay2"
                                    >
                                      <option value="">Select</option>
                                      <option
                                        className="onlineback"
                                        value="Online"
                                      > 
                                        Online
                                      </option>
                                      <option
                                        className="offlineback"
                                        value="Offline"
                                      >
                                        Offline
                                      </option>
                                    </select>
                                  </Col>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Others</lable>
                                    <br />
                                    <input
                                      type="text"
                                      value={addInstall.installplanDetails.installothers} onChange={handlePlanChange}
                                      name="installothers"

                                      style={{ textTransform: "capitalize" }}
                                      className="form-input overlay2"
                                      placeholder="Enter "
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Others Cost</lable>
                                    <br />
                                    <input
                                      type="tel"
                                      value={addInstall.installplanDetails.installothersCost} onChange={handlePlanChange}
                                      name="installothersCost"

                                      placeholder="Rs"
                                      className="form-input overlay2"
                                    />
                                  </Col>

                                  <Col xs={12} md={6} className="text-start">
                                    <lable>Advance Amount </lable>
                                    <br />
                                    


                                      <select

    className="form-input-select"
    name="installadvanceAmount"

    value={addInstall.installplanDetails.installadvanceAmount.installpay}
    onChange={(e) => {
        handleChange(e);
        if (e.target.value === "Pay") {
            setIsOpenAdvancePayment(true);
        }
    }}
>
    <option value="">Select Type</option>
    <option value="Unpay">Unpay</option> 
    <option value="Pay">Pay</option>
   
</select>

{ isOpenAdvancePayment && (
    <div className="overlay-advance-payment">
        <div className="modal-advance-payment">
            <Container>
                <Row>
                    <Col> <h6>Advance Pay</h6></Col>
                    <Col className="text-end">
                        <span 
                            style={{ fontSize: "23px", cursor: "pointer" }} 
                            onClick={() => setIsOpenAdvancePayment(false)}
                        >
                            <strong>&times;</strong>  
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <label>Current Date</label>
                            <br />
                            <input className="form-input" type="date"
                                value={addInstall.installplanDetails.installadvanceAmount.installcurrentDate} 
                                onChange={ handleAmountChange}
                                name="installcurrentDate"

                            />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <label>Payment Type</label>
                            <br />
                            <select className="form-input-select"
    value={addInstall.installplanDetails.installadvanceAmount.installpaymentType || ""}
    onChange={handleAmountChange}
    name="installpaymentType"
>
    <option value="">Select Type</option>
    <option value="Private">Private</option>
    <option value="Technology">Technology</option>
    <option value="Cash">Cash</option>
    <option value="Helpdesk">Helpdesk</option>
    <option value="Others">Others</option>
</select>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <label>Amount</label>
                            <br />
                            <input className="form-input" placeholder="Rs"
                                value={addInstall.installplanDetails.installadvanceAmount.installamount} 
                                
                                onChange={ handleAmountChange}
                                name="installamount"

                            />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <label>Reference Number</label>
                            <br />
                            <input className="form-input" placeholder="Enter Reference Number"
                                value={addInstall.installplanDetails.installadvanceAmount.installreferenceNumber} 
                                onChange={ handleAmountChange}
                                name="installreferenceNumber"

                            />
                        </div>
                    </Col>
                </Row>

                <Row className="text-end mt-5">
                  <Col className="text-start">
                  <button className="black-btn " onClick={() => setIsOpenAdvancePayment(false)}>Cancel</button>
                  </Col>
                  <Col className="text-end">
                  <button className="blue-btn ms-5" onClick={() => setIsOpenAdvancePayment(false)}>Submit</button>
                  </Col>



                </Row>
            </Container>
        </div>
    </div>
)}




                                    {/* </select> */}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div className="button-group1">
                              <button
                                type="button"
                                className="black-btn"
                                onClick={() => setIsOpenplan(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="ok-btn1"
                                onClick={() => setIsOpenplan(false)}
                              >
                                OK
                              </button>
                            </div>
                          </Container>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
                <lable>Mail ID</lable>
                <br />
                <input
                 value={addInstall.installemailId} onChange={handleChange}

                  type="email"
                  placeholder="Enter Email ID"
                  className="form-input "
                  name="installemailId"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Next Follow Up Date</lable>
                    <br />
                    <input
                      className="field form-input"
                      value={addInstall.installnextFollowUpDate} onChange={handleChange}

                      type="date"
                      name="installnextFollowUpDate"
                    />
                  </Col>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Lead Status</lable>
                    <br />
                    <input style={{backgroundColor:"#fbdc6e",color:"rgb(94, 94, 14)"}}
                      type="button"
                      onClick={() => setIsOpenConverted(true)}
                      className="form-input converted-form"
                      name="installleadStatus"
                      value="Open"

                    />
                      
             

                    {isOpenConverted && (
                      <div className="overlay1-converted">
                        <div className="modal1-converted">
                          <Container>
                            <button
                              className="close-btn1"
                              onClick={() => setIsOpenConverted(false)}
                            >
                              <span style={{ fontSize: "20px" }}>
                                {" "}
                                &times;{" "}
                              </span>
                            </button>

                            <div className="open-button border">
                              <h5>Open</h5>
                            </div>

                            <textarea
                              style={{
                                textTransform: "capitalize",
                                resize: "none",
                              }}
                              placeholder="Enter Notes"
                              className="converted-textbox"
                              name="installnotes"
                              onChange={handleLeadChange}

                              value={addInstall.installleadStatus.installnotes}
                            />
                            <div className="upload-section">
                              <label className="upload-btn">
                                <FiUpload className="upload-icon" />
                                Upload Image
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}


                                  hidden
                                />
                              </label>
                              {selectedFile && (
                                <p className="file-name">{selectedFile.name}</p>
                              )}
                            </div>

                            <Row>
                              <Col className="date-update-textbox">
                                <label>Next Follow Up Date</label>
                                <br />
                                <input
                                  type="date"
                                  className="field form-input"
                                  value={addInstall.installleadStatus.installLeadStatusnextFollowUpDate} 
                                  onChange={handleLeadChange}
                                  name="installLeadStatusnextFollowUpDate"

                                />
                              </Col>
                              <Col className="uploadbtn-install">
                                <button type="button" className="blue-btn"
                                 onClick={() => setIsOpenConverted(false)}>
                                  Upload
                                </button>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}