import React from "react";

const NoPage = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light text-center">
      <h1 className="display-1 text-danger fw-bold">404</h1>
      <h2 className="text-dark">Oops! Page Not Found</h2>
      <p className="text-muted">
        The page you are looking for might have been removed or is temporarily unavailable.
      </p>
      <a href="/" className="btn btn-primary mt-3">
        Go Back Home
      </a>
    </div>
  );
};

export default NoPage;
