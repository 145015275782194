import React from "react";
import Topbar from "../Topbar/Topbar";
import './CustomerReport.css'
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Col, Row } from "react-bootstrap";
const data = {
  labels: [
    "APR 01",
    "APR 05",
    "APR 10",
    "APR 15",
    "APR 20",
    "APR 25",
    "APR 30",
  ],
  datasets: [
    {
      label: "Open",
      data: [35, 20, 28, 38, 25, 30, 34],
      borderColor: "green",
      backgroundColor: "rgba(0, 255, 0, 0.2)",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      borderWidth: 3,
    },
    {
      label: "Solved",
      data: [25, 15, 18, 30, 22, 26, 30],
      borderColor: "blue",
      backgroundColor: "rgba(0, 0, 255, 0.2)",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      borderWidth: 3,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  tension: 0.4,
  scales: {
    x: { grid: { display: false } },
    y: {
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
  },
};

const CustomerReport = () => {
  return (
    <div className="container-fluid">
      <Topbar />
      <div className="row">
        <h3 className="">Complaints Report</h3>
        <Row className="mt-4">
          <Col md={3}>
            <input type="date" />
          </Col>
          <Col md={3}>
            <div className="cr-generate">
              <button className="cr-generate-btn">Generate</button>
            </div>
          </Col>
          <Col >
            <button className="black-btn cr-export">Export</button>
          </Col>
        </Row>
      </div>
      <Row className="mt-5">
        <Col md={6} sm={12} className="">
          <div className="complaints-report-box ">
            <h4>Complaints Report</h4>
            <Line data={data} options={options} />
            <div
              style={{ textAlign: "center", fontSize: "18px", marginTop: 10 }}
            >
              <span style={{ color: "green", fontWeight: "bold" }}>
                Open: {56}
              </span>{" "}
              &nbsp;&nbsp;
              <span style={{ color: "blue", fontWeight: "bold" }}>
                Solved: {56}
              </span>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} className="">
          <div className="connections-report-box">
             <h4>Connections Report</h4>
            <Line data={data} options={options} />
            <div
              style={{ textAlign: "center", fontSize: "18px", marginTop: 10 }}
            >
              <span style={{ color: "green", fontWeight: "bold" }}>
                Open: {56}
              </span>{" "}
              &nbsp;&nbsp;
              <span style={{ color: "blue", fontWeight: "bold" }}>
                Completed: {56}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} sm={12} className="">
          <div className="dues-report-box">
           <h4>Dues Report</h4>
            <Line data={data} options={options} />
            <div
              style={{ textAlign: "center", fontSize: "18px", marginTop: 10 }}
            >
              <span style={{ color: "green", fontWeight: "bold" }}>
                Open: {56}
              </span>{" "}
              &nbsp;&nbsp;
              <span style={{ color: "blue", fontWeight: "bold" }}>
                Closed: {56}
              </span>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} className="">
          <div className="leads-report-box">
             <h4>Leads Report</h4>
            <Line data={data} options={options} />
            <div
              style={{ textAlign: "center", fontSize: "18px", marginTop: 10 }}
            >
              <span style={{ color: "green", fontWeight: "bold" }}>
                Open: {56}
              </span>{" "}
              &nbsp;&nbsp;
              <span style={{ color: "blue", fontWeight: "bold" }}>
                Completed: {56}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <br></br>
    </div>
  );
};
export default CustomerReport;
