import React, { useState, useEffect } from "react";
import "./Leadsource.css";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";

const Leadsource = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [rows, setRows] = useState([]);
  console.log(rows, 19);
  const [leadSource, setLeadSource] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [addButtonEnable, setAddButtonEnable] = useState(true);
  const [leadEditError, setLeadEditError] = useState(""); // State to store edit error
  const [leadInputValue, setLeadInputValue] = useState("");

  const handleAddRow = () => {
    setIsAdding(true);
  };

  //______________________
  const [isUnique, setIsUnique] = useState(true);
  const [uniqueError, setUniqueError] = useState("");
  useEffect(() => {
    if (leadSource) {
      checkLead(leadSource);
    }
  }, [leadSource]);

  const checkLead = async (leadId) => {
    if (typeof leadId !== "string" && typeof leadId !== "number") {
      return;
    }
  
    try {
      const response = await axios.get(`${apiUrl}checklead/${leadId}`);
      if (response.data.exists) {
        setIsUnique(false);
        setUniqueError("Lead Name already exists!");
      } else {
        setIsUnique(true);
        setUniqueError("");
      }
    } catch (error) {
      console.error("Error checking Lead ID:", error);
    }
  };

  const handleLeadInputChange = (e) => {
    const value = e.target.value; // Extract the input value

    if (leadSource?.actualValue !== value && value) {
      checkLead(value); // Pass the raw value to `checkRole`
    } else {
      setUniqueError("");
      setIsUnique(true); // Reset state
    }

    setLeadSource(value); // Update the state
  };

  //_________________

  const handleLeadSubmit = async (e) => {
    e.preventDefault();

    // Validate the input
    if (!leadSource.trim()) {
      Swal.fire("Warning!", "Please enter lead source data.", "warning");
      return;
    }
  try {
       const response = await axios.post(apiUrl + "addlead", {
         leadSource,
       });
 
       Swal.fire("Success!", response.data.message, "success");
 
       setRows((prev) => [...prev, { _id: response.data.id, leadSource }]);
       setIsAdding(false);
       setLeadSource("");
     } catch (error) {
      console.error("Error submitting data", error);

      // Handle errors properly
      const errorMessage =
        error.response?.data?.message || "Failed to submit data.";
      Swal.fire("Error!", errorMessage, "error");
    }
  };

  const listView =()=> {
    axios
    .post(apiUrl + "viewlead", {})
    .then((res) => {
      setRows(res.data.message);
    })
    .catch((error) => {
      console.error("Error fetching data", error);
    });
  } 

  useEffect(() => {
    listView()
  }, []);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.post(apiUrl + "deletelead", {
          _id: id,
        });

        Swal.fire("Deleted!", response.data.Message, "success");
        setRows((prev) => prev.filter((row) => row._id !== id));
      } catch (error) {
        Swal.fire("Error!", "Failed to delete lead source.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  const handleEdit = (row) => {
    setEditingRowId(row._id);
    setEditValue(row.leadSource);
    setAddButtonEnable(false); //
  };

  const handleLeadEditChange = (e) => {
    setEditValue(e.target.value);
  };
  // const handleEditSave = async () => {
  //   try {
  //     const response = await axios.post(apiUrl+"updatelead", {
  //       leadId: editingRowId,
  //       leadSource: editValue,
  //     });

  //     Swal.fire("Updated!", response.data.Message, "success");

  //     setRows((prev) =>
  //       prev.map((row) =>
  //         row._id === editingRowId ? { ...row, leadSource: editValue } : row
  //       )
  //     );
  //     setEditingRowId(null);
  //   } catch (error) {
  //     Swal.fire("Error!", "Failed to update lead source.", "error");
  //     console.error("Edit Error:", error);
  //   }
  // };
  // const handleEditSave = async () => {
  //   Swal.fire({
  //     title: "Do you want to save the changes?",
  //     showDenyButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: "Save",
  //     denyButtonText: `Don't save`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       // Check if the value is unchanged
  //       const currentRow = rows.find((row) => row._id === editingRowId);
  //       if (currentRow && currentRow.leadSource=== editValue.trim()) {
  //         Swal.fire("No Update Found", "No changes were made to the Lead Source.", "info");

  //         setRows((prev) =>
  //           prev.map((row) =>
  //             row._id === editingRowId
  //               ? { ...row, leadSource: editValue }
  //               : row
  //           )
  //         );

  //         setEditingRowId(null);
  //         return; // Exit without calling the API
  //       }

  //       try {
  //         const response = await axios.post(apiUrl + "updatelead", {
  //           leadId: editingRowId,
  //           leadSource: editValue,
  //         });

  //         Swal.fire("Saved!", response.data.Message, "success");

  //         // Update the specific row in the table
  //         setRows((prev) =>
  //           prev.map((row) =>
  //             row._id === editingRowId
  //               ? { ...row, leadSource: editValue }
  //               : row
  //           )
  //         );

  //         setEditingRowId(null); // Exit edit mode
  //       } catch (error) {
  //         Swal.fire("Error!", "Failed to update lead Source.", "error");

  //       }
  //     } else if (result.isDenied) {
  //       Swal.fire("Changes are not saved", "", "info");
  //       setRows((prev) =>
  //         prev.map((row) =>
  //           row._id === editingRowId
  //             ? { ...row, leadSource: editValue }
  //             : row
  //         )
  //       );

  //       setEditingRowId(null);

  //     }
  //   });
  // };
  const handleEditSave = async () => {
    setAddButtonEnable(true);

    // Check if the edited lead source already exists
    const isDuplicate = rows.some(
      (row) =>
        row.leadSource.toLowerCase() === editValue.trim().toLowerCase() &&
        row._id !== editingRowId
    );

    if (isDuplicate) {
      Swal.fire("Error!", "Lead Source already exists!", "error");
      return;
    }

    // Check if the value is unchanged before showing the confirmation popup
    const currentRow = rows.find((row) => row._id === editingRowId);
    if (currentRow && currentRow.leadSource === editValue.trim()) {
      Swal.fire(
        "No Update Found",
        "No changes were made to the Lead Source.",
        "info"
      );
      setEditingRowId(null); // Exit edit mode
      return; // Exit without showing confirmation popup
    }

    // Show confirmation dialog for saving changes
    Swal.fire({
      title: "Do you want to save the changes?",

      showCancelButton: true,
      confirmButtonText: "Save",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(apiUrl + "updatelead", {
            leadId: editingRowId,
            leadSource: editValue,
          });

          Swal.fire("Saved!", response.data.Message, "success");

          // Update the specific row in the table
          setRows((prev) =>
            prev.map((row) =>
              row._id === editingRowId ? { ...row, leadSource: editValue } : row
            )
          );

          setEditingRowId(null); // Exit edit mode
        } catch (error) {
          Swal.fire("Error!", "Failed to update Lead Source.", "error");
          console.error("Edit Error:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle cancel button click
        setEditingRowId(null); // Exit edit mode
      }
    });
  };

  const handleLeadCancel = () => {
    // Reset input values if necessary
    setLeadInputValue(""); // Assuming `inputValue` holds the input state
    setIsAdding(false); // Exit the addition mode
    setIsUnique(null); // Reset uniqueness state
    setUniqueError(""); // Clear error message
    setLeadSource(""); // Clear the input field value
  };

  return (
    <>
      <Topbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h3 className="head mt-4">Settings</h3>
          </div>
          <div className="col-md-6 col-sm-12">
            {!isAdding && addButtonEnable && (
              <button className="black-btn add-btn mt-4" onClick={handleAddRow}>
                Add
              </button>
            )}
          </div>
        </div>
        <div>
          <table className="table-border">
            <thead className="table-th">
              <tr className="lead-head">
                <th className="table-th-content table-head">Lead Sources</th>
                <th className="table-th-content table-head text-center">
                  Operations
                </th>
              </tr>
            </thead>
            <tbody>
              {isAdding && (
                <tr>
                  <td className="lead-td">
                    <input
                      type="text"
                      required
                      value={leadSource}
                      onChange={handleLeadInputChange}
                      placeholder="Enter Lead Source"
                      className="adding-input"
                      style={{ textTransform: "capitalize" }}
                    />
                  </td>
                  <td className="lead-td d-flex">
                    <button
                      className="blue-btn-new mx-3"
                      onClick={handleLeadSubmit}
                    >
                      Submit
                    </button>
                    <button
                      className="cancle-btn mx-3"
                      onClick={handleLeadCancel}
                      value="cancel"
                      // disabled={!isUnique}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              )}

              {!isUnique && <p className="text-danger">{uniqueError}</p>}

              {rows.length === 0  &&!isAdding ? (
                <tr>
                  <td colSpan="2" className="text-center text-muted p-3">
                    No data found
                  </td>
                </tr>
              ) : (
                rows.map((row) => (
                  <tr key={row._id}>
                    <td className="lead-td">
                      {editingRowId === row._id ? (
                        <input
                          required
                          type="text"
                          value={editValue}
                          onChange={handleLeadEditChange}
                          className="adding-input"
                          style={{ textTransform: "capitalize" }}
                        />
                      ) : (
                        row.leadSource
                      )}
                    </td>
                    <td className="lead-td">
                      {editingRowId === row._id ? (
                        <button
                          className="blue-btn mx-3"
                          onClick={handleEditSave}
                        >
                          Save
                        </button>
                      ) : (
                        <>
                          <div className="d-flex icon-div">
                            <div className="">
                              <MdModeEdit
                                className="edit-icon"
                                onClick={() => handleEdit(row)}
                              />
                              Edit
                            </div>
                            <div>
                              <MdDelete
                                className="delete-icon"
                                onClick={() => handleDelete(row._id)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </td>
                    {leadEditError && (
                      <p className="text-danger">{leadEditError}</p>
                    )}{" "}
                    {/* Show error in red */}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Leadsource;
