import React, { useState } from "react";
import "./Register.css";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import axios from "axios";

const Register = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const backward = () => {
    navigate("/");
  };
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  const [staffId, setstaffId] = useState();
  const [staffName, setstaffName] = useState();
  const [designation, setdesignation] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [contactNumber, setcontactNumber] = useState();

  const [staffIdError, setstaffIdError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");
  const [contactNumberError, setcontactNumberError] = useState("");

  const validatestaffId = () => {
    if (!staffId) {
      setstaffIdError("User ID is required");
      return false;
    }

    // Regex: First 6 characters must be alphanumeric, total length must be at least 8
    const regex = /^[a-zA-Z0-9]{6}.{2,}$/;

    if (!regex.test(staffId)) {
      setstaffIdError(
        "User ID must start with 6 alphanumeric characters and be at least 8 characters long"
      );
      return false;
    }

    setstaffIdError("");
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setpasswordError("Password is required");
      return false;
    }
    if (password.length < 6) {
      setpasswordError("Password must be at least 6 characters long.");
      return false;
    }
    setpasswordError("");
    return true;
  };

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      setemailError("Email is required");
      return false;
    }
    if (!emailRegex.test(email)) {
      setemailError("Please enter a valid email address");
      return false;
    }
    setemailError("");
    return true;
  };

  const validatecontactNumber = () => {
    const mobileRegex = /^[7-9]\d{9}$/;
    if (!contactNumber) {
      setcontactNumberError("Mobile number is required");
      return false;
    }
    if (!mobileRegex.test(contactNumber)) {
      setcontactNumberError(
        "Please enter a valid 10-digit mobile number starting with 7, 8, or 9"
      );
      return false;
    }
    setcontactNumberError("");
    return true;
  };

  const submitData = (e) => {
    e.preventDefault();
    const isValidstaffId = validatestaffId();
    const isValidPassword = validatePassword();
    const isValidEmail = validateEmail();
    const isValidcontactNumber = validatecontactNumber();

    if (
      !isValidstaffId ||
      !isValidPassword ||
      !isValidEmail ||
      !isValidcontactNumber
    ) {
      return;
    }
    if (!staffName) {
      alert("Name cannot be Empty");
      return;
    }

    try {
      axios
        .post(apiUrl + "register", {
          staffId,
          staffName,
          designation,
          password,
          email,
          contactNumber,
        })
        .then((res) => {
          alert(res.data.message);
          navigate("/");
        });
    } catch (error) {
      alert("Cannot Register", error);
    }
  };

  return (
    <div className="container-fluid register">
      <div className="register-col">
        <Row className="register-box">
          <Col sm={12} md={2}>
            <IoArrowBackCircleSharp onClick={backward} className="backward" />
          </Col>
          <Col sm={12} md={8}>
            <h1 className="register-txt">Register</h1>
          </Col>
        </Row>
        <Row sm={12}>
          <form onSubmit={submitData} className="register-form">
            <Col sm={12}>
              {" "}
              <label>
                <b>Staff ID</b>
              </label>{" "}
            </Col>

            <Col sm={12}>
              {" "}
              <input
                type="text"
                name="staffId"
                onBlur={validatestaffId}
                onChange={(e) => setstaffId(e.target.value)}
                placeholder="Enter Your Staff ID"
              />
              {staffIdError && <p style={{ color: "red" }}>{staffIdError}</p>}{" "}
            </Col>

            <Col sm={12}>
              <label>
                <b>StaffName</b>
              </label>{" "}
            </Col>
            <Col sm={12}>
              <input
                type="text"
                name="staffName"
                onChange={(e) => setstaffName(e.target.value)}
                placeholder="Enter Your Staff Name"
              />
            </Col>
            <Col sm={12}>
              <label>
                <b>Designation</b>
              </label>{" "}
            </Col>
            <Col sm={12}>
              <input
                type="text"
                name="designation"
                onChange={(e) => setdesignation(e.target.value)}
                placeholder="Enter Your Designation"
              />
            </Col>

            <Col sm={12}>
              <label>
                <b>Password</b>
              </label>
            </Col>
            <Col sm={12}>
              <input
                type={show ? "text" : "password"}
                name="password"
                onBlur={validatePassword}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Your Password"
              />

              <label onClick={handleShow} className="show-hide-register">
                {show ? <BiSolidHide /> : <BiSolidShow />}
              </label>
              {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
            </Col>

            <Col sm={12}>
              <label>
                <b>E-Mail</b>
              </label>
            </Col>
            <Col sm={12}>
              <input
                type="email"
                name="email"
                onBlur={validateEmail}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your E-Mail"
              />
              {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            </Col>

            <Col sm={12}>
              <label>
                <b>Mobile Number</b>
              </label>
            </Col>
            <Col sm={12}>
              <input
                type="tele"
                name="contactNumber"
                onBlur={validatecontactNumber}
                onChange={(e) => setcontactNumber(e.target.value)}
                placeholder="Enter Your Mobile Number"
              />
              {contactNumberError && (
                <p style={{ color: "red" }}>{contactNumberError}</p>
              )}
            </Col>

            <Col className="btn">
              <button type="submit">Submit</button>
            </Col>
          </form>
          <Col md={3} sm={12}></Col>
        </Row>
      </div>
    </div>
  );
};
export default Register;
