import React, { useState } from "react";
import Topbar from "../Topbar/Topbar";
import "./Attendance.css";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from "@mui/material";

export default function Attendance() {
  const options = ["Saravanan", "Ravikumar", "Sathishkumar", "Venkatesh"];
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSelectedValues(
        selectedValues.length === options.length ? [] : options
      );
    } else {
      setSelectedValues(value);
    }
  };
  return (
    <div>
      <Topbar />
      <div className="container">
        <h3 className="">Attendance</h3>
        {/* Row 1 */}
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <FormControl sx={{ width: "200px" }}>
              <InputLabel
                id="checkbox-select-label"
                shrink={selectedValues.length > 0}
              ></InputLabel>
              <Select
                labelId="checkbox-select-label"
                id="checkbox-select"
                multiple
                value={selectedValues}
                onChange={handleChange}
                displayEmpty
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  textAlign: "center", // ✅ Align text to center
                  display: "flex",
                  alignItems: "center", // ✅ Vertically center text
                }}
                renderValue={(selected) =>
                  selected.length === 0 ? "Select Staff" : selected.join(", ")
                }
              >
                <MenuItem value="all">
                  <ListItemText primary="All" />
                  <Checkbox
                    checked={selectedValues.length === options.length}
                  />
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemText primary={option} />
                    <Checkbox checked={selectedValues.includes(option)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-2 col-sm-12">
            <div>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3 col-sm-12 text-center">
            <div className="att-generate">
              <button className="att-generate-btn">Generate</button>
            </div>
          </div>
        </div>
        {/* Row 2 */}
        <div className="row card-background mt-4">
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-4">
              {" "}
              <h4> Interjet </h4>
            </div>
            <div className="col-md-6 col-sm-12 mt-3 ">
              <div className="attendance-exp-btn">
                <button className="black-btn  text-center">
                  <b>Export</b>{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col mt-4 m-1 ">
              <div className="row card-border">
                <div className="row green-card align-box ">
                  <p className="text-center">Present</p>
                </div>
                <div className="row">
                  <h5 className="text-center">1</h5>
                </div>
              </div>
              <div className="row">
                <p className="text-center">
                  {" "}
                  <b>Avg/M - 29D</b>
                </p>
              </div>
            </div>

            <div className="col mt-4 m-1  ">
              <div className="row card-border">
                {" "}
                <div className="row red-card align-box">
                  {" "}
                  <p className="text-center">Off</p>
                </div>
                <div className="row">
                  {" "}
                  <h5 className="text-center">1</h5>
                </div>
              </div>
              <div className="row">
                <p className="text-center">
                  <b> Avg/M - 29D </b>
                </p>
              </div>
            </div>
            <div className="col mt-4 m-1 ">
              <div className="row card-border">
                <div className="row purple-card align-box">
                  {" "}
                  <p className="text-center work-card">Work Time</p>
                </div>
                <div className="row">
                  {" "}
                  <h5 className="text-center">1</h5>
                </div>
              </div>
              <div className="row">
                <p className="text-center">
                  <b> Avg/M - 29D </b>
                </p>
              </div>
            </div>
            <div className="col mt-4 ">
              <div className="row card-border">
                {" "}
                <div className="row brown-card align-box">
                  {" "}
                  <p className="text-center">Idle Time</p>
                </div>
                <div className="row">
                  {" "}
                  <h5 className="text-center">1</h5>
                </div>
              </div>
              <div className="row">
                <p className="text-center">
                  <b>Avg/M - 29D</b>
                </p>
              </div>
            </div>
            <div className="col mt-4 m-1  ">
              <div className="row card-border">
                {" "}
                <div className="row yellow-card align-box">
                  {" "}
                  <p className="text-center">Extra Time</p>
                </div>
                <div className="row">
                  {" "}
                  <h5 className="text-center">1</h5>
                </div>
              </div>

              <div className="row">
                <p className="text-center">
                  <b> Avg/M - 29D</b>
                </p>
              </div>
            </div>
            <div className="col mt-4 m-1  ">
              <div className="row card-border">
                <div className="row blue-card align-box">
                  {" "}
                  <p className="text-center">Break Time</p>
                </div>
                <div className="row">
                  {" "}
                  <h5 className="text-center">1</h5>
                </div>
              </div>
              <div className="row">
                <p className="text-center">
                  <b>Avg/M - 29D</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Row 3 */}
        <div className="row mt-5">
          {/* <div className="scrolltable">
            <table className="table table-bordered">
              <thead className="table-th text-center">
                <tr>
                  <th className="table-th-content">Customer ID</th>

                  <th className="table-th-content">Expiry Date</th>

                  <th className="table-th-content">Customer Name</th>

                  <th className="table-th-content">Device Status</th>

                  <th className="table-th-content">Account Status</th>

                  <th className="table-th-content">Contact Number</th>

                  <th className="table-th-content">Alternate Number</th>

                  <th className="table-th-content">Mail ID</th>

                  <th className="table-th-content">Area</th>

                  <th className="table-th-content">Account Manager</th>

                  <th className="table-th-content">Activation Date</th>

                  <th className="table-th-content">Last Renewal Date</th>

                  <th className="table-th-content">Last Logoff Date</th>

                  <th className="table-th-content">Billing Address</th>

                  <th className="table-th-content">Installation Address</th>

                  <th className="table-th-content">Installation Charges</th>

                  <th className="table-th-content">Refund Deposit</th>

                  <th className="table-th-content">Refund Description</th>

                  <th className="table-th-content">Plan Name</th>

                  <th className="table-th-content">Delete</th>
                </tr>
              </thead>
            </table>
          </div> */}
          <div className="attendance-table-container">
            <table className="  attendance-table  table-bordered">
              <thead>
                <tr>
                  <th className="row-label blue-txt">Date</th>
                  <th className="table-th attendance-th">Mon 1</th>
                  <th className="table-th">Tue 2</th>
                  <th className="table-th">Wed 3</th>
                  <th className="table-th">Thu 4</th>
                  <th className="table-th">Fri 5</th>
                  <th className="table-th">Sat 6</th>
                  <th className="table-th">Sun 7</th>
                  <th className="table-th">Mon 8</th>
                  <th className="table-th">Tue 9</th>
                </tr>
              </thead>
              <tbody className="tr:nth-child(even)">
                <tr>
                  <td className="row-label blue-txt attendance-td ">Status</td>
                  <td>P</td> <td>P</td> <td>P</td> <td>P</td> <td>P</td>{" "}
                  <td>P</td> <td>P</td> <td>P</td> <td>P</td>
                </tr>
                <tr>
                  <td className="row-label blue-txt attendance-td ">In Time</td>
                  <td>9:30 AM</td> <td>9:30 AM</td> <td>9:30 AM</td>{" "}
                  <td>9:30 AM</td> <td>9:30 AM</td> <td>9:30 AM</td>{" "}
                  <td>9:30 AM</td> <td>9:30 AM</td> <td>9:30 AM</td>
                </tr>
                <tr>
                  <td className="row-label blue-txt attendance-td ">
                    Break Duration
                  </td>
                  <td>20 M</td> <td>20 M</td> <td>20 M</td> <td>20 M</td>{" "}
                  <td>20 M</td> <td>20 M</td> <td>20 M</td> <td>20 M</td>{" "}
                  <td>20 M</td>
                </tr>
                <tr>
                  <td className="row-label blue-txt attendance-td ">
                    Out Time
                  </td>
                  <td>6:30 PM</td> <td>6:30 PM</td> <td>6:30 PM</td>{" "}
                  <td>6:30 PM</td> <td>6:30 PM</td> <td>6:30 PM</td>{" "}
                  <td>6:30 PM</td> <td>6:30 PM</td> <td>6:30 PM</td>
                </tr>
                <tr>
                  <td className="row-label blue-txt attendance-td ">
                    Work Time
                  </td>
                  <td>5:25 H</td> <td>5:25 H</td> <td>5:25 H</td>{" "}
                  <td>5:25 H</td> <td>5:25 H</td> <td>5:25 H</td>{" "}
                  <td>5:25 H</td> <td>5:25 H</td> <td>5:25 H</td>
                </tr>
                <tr>
                  <td className="row-label blue-txt attendance-td ">
                    Idle Time
                  </td>
                  <td>2:35 H</td> <td>2:35 H</td> <td>2:35 H</td>{" "}
                  <td>2:35 H</td> <td>2:35 H</td> <td>2:35 H</td>{" "}
                  <td>2:35 H</td> <td>2:35 H</td> <td>2:35 H</td>
                </tr>
                <tr>
                  <td className="row-label attendance-td  blue-txt">
                    Extra Time
                  </td>
                  <td>1:15 H</td> <td>1:15 H</td> <td>1:15 H</td>{" "}
                  <td>1:15 H</td> <td>1:15 H</td> <td>1:15 H</td>{" "}
                  <td>1:15 H</td> <td>1:15 H</td> <td>1:15 H</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
