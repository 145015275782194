import React, { useState } from "react";
import "./RolesList.css";
import { useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
// const roles = [
//   "Super Admin", "Admin", "Network Admin", "Network Engineer", "Fiber Admin",
//   "Fiber Engineer", "Marketing Admin", "Marketing Executive", "Marketing / Fiber",
//   "Helper", "Accounts Admin", "Accountant", "HR", "Telecalling"
// ];




const RolesList = ({ onSelectRole }) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
const[roles, setRoles]=useState([])

useEffect(() => {
  axios
    .post(apiUrl+"viewrole", {})
    .then((res) => {
       setRoles(res.data.message);
    console.log(res.data.message);
      
      
     
    })
    .catch((error) => {
      console.error("Error fetching data", error);
    });
}, []);






  return (
    <Container>
    <div className="roles-top">
      <h2 className="pb-2">Administration</h2>
      <div className="roles-container">
        <div className="roles-box">
          <div className="roles-title">Roles</div>
          <ul>
            {roles.map((role, index) => (
              <li key={index} className="role-item" onClick={() => onSelectRole(role.role)}>
                {role.role}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </Container>
  );
};

export default RolesList;