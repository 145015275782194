import React, { useState, useEffect } from "react";
import Topbar from "../Topbar/Topbar";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import "react-calendar/dist/Calendar.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css
import "react-date-range/dist/theme/default.css"; // theme css
import { useSearchParams } from "react-router-dom";
import "./Stock.css";
import axios from "axios";

const Stock = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const [stockEntries, setStockEntries] = useState([]);

  const defaultCards = [
    { title: "2F", class: "yellow-card" },
    { title: "4F", class: "purple-card" },
    { title: "6F", class: "orange-card" },
    { title: "ONU", class: "blue-card" },
    { title: "Single Band", class: "pink-card" },
    { title: "Dual Band", class: "green-card" },
    { title: "Splitter", class: "red-card" },
    { title: "Cat 6 Cable", class: "purple-card" },
  ];

  //table stock data
  const [stocksDatas, setStocksData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  const [datePopup, setDatePopup] = useState(false);
  const [stockSettingPopup, setStockSettingPopup] = useState(false);
  const [viewStockCount, setStockViewCount] = useState([]);

  const [showCustom, setShowCustom] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const goToAddStock = (stockId) => {
    navigate(`/addstock?id=${stockId}`);
  };

  const handleCancel = () => setShowCustom(false);
  const handleApply = () => {
    console.log("Selected Range:", range);
    setShowCustom(false);
  };

  const navigate = useNavigate();

  const gotoaddStock = (e) => {
    e.preventDefault();
    navigate("/addstock");
  };

  const handleOpenDatePopup = () => {
    setDatePopup(true);
  };
  const handleClosePopUp = () => {
    setDatePopup(false);
  };

  const handleSettingOpen = () => {
    setStockSettingPopup(true);
  };
  const handleSettingClose = () => {
    setStockSettingPopup(false);
  };
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter the stocksData based on the search term
  const filteredStocksData = stockEntries.filter((item) =>
    // console.log(item.name)
    item.name.toLowerCase().includes(searchTerm)
  );

  function formatUnit(unit, category) {
    if (!unit) return ""; // Return empty if no material found.

    // Negate the unit for specific categories
    if (
      ["damage", "installation", "complaint"].includes(category.toLowerCase())
    ) {
      return unit > 0 ? `-${unit}` : unit;
    }

    // Return the unit in its original state otherwise
    return unit;
  }

  //fetch count
  useEffect(() => {
    axios
      .get(apiUrl + "stock/summary", {})
      .then((res) => {
        setStockViewCount(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching", error);
      });
  }, [apiUrl, setStockViewCount]);

  useEffect(() => {
    axios
      .get(apiUrl + "/stock/entries", {})
      .then((res) => {
        setStocksData(res.data);
        setStockEntries(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching", error);
      });
  }, [apiUrl, setStockViewCount]);

  return (
    <div>
      <Topbar />
      <Container fluid>
        <h3 className=" px-2  ">Stock</h3>
        {/* {vieweStockCount.map((d,i)=>{

        })} */}
        <Row className="row-width">
          {defaultCards.map((card, index) => {
            // Match the backend data to this card by title (case-insensitive)
            const match = viewStockCount.find(
              (item) =>
                item.materialType?.toLowerCase() === card.title.toLowerCase() ||
                item.material?.toLowerCase() === card.title.toLowerCase()
            );

            const count = match ? match.totalStock : 0;

            return (
              <Col key={index} className="dashborder mt-4 mb-5 mx-2">
                <Row className={card.class}>
                  <p className="text-center box-txt">{card.title}</p>
                </Row>
                <Row className="number">
                  <h5 className="text-center pt-2">{count}</h5>
                </Row>
              </Col>
            );
          })}
        </Row>

        <Row className="" xs={1} sm={2}>
          <Col className="mt-2 mb-2">
            <button onClick={gotoaddStock} className="black-btn">
              Add
            </button>{" "}
            &emsp;&emsp;
            <button
              onClick={() => window.location.reload()}
              className="black-btn "
            >
              Refresh
            </button>
          </Col>

          <Col className="mt-2 text-end">
            <button className="black-btn">Export</button> &emsp;&emsp;
            <button className="black-btn" onClick={handleSettingOpen}>
              Settings
            </button>
          </Col>
        </Row>
        <br />
        <div className="table-scroll">
          <table className="table stock-table">
            <thead className="table-th text-center table-th-re">
              <tr>
                <td rowSpan={2} className="wrap stock-table-align">
                  Date
                </td>
                <td rowSpan={2} className="wrap stock-table-align">
                  Category
                </td>
                <td rowSpan={2} className="wrap stock-table-align">
                  Vendor And Customer Name
                </td>
                <td rowSpan={2} className="wrap stock-table-align">
                  Updated By
                </td>
                <td colSpan={3} className="wrap">
                  Fiber
                </td>
                <td colSpan={4} className="wrap">
                  Router
                </td>
                <td colSpan={3} className="wrap">
                  Splitter
                </td>
                <td colSpan={2} className="wrap">
                  Closure Box
                </td>
                <td colSpan={2} className="wrap">
                  Patch Chord
                </td>
                <td rowSpan={2} className="wrap">
                  Cat 6 Cable
                </td>
              </tr>
              <tr className="table-th text-center">
                <td className="wrap sub-bg-color">2F</td>
                <td className="wrap sub-bg-color">4F</td>
                <td className="wrap sub-bg-color">6F</td>
                <td className="wrap sub-bg-color">ONU</td>
                <td className="wrap sub-bg-color">Single band</td>
                <td className="wrap sub-bg-color">Dual band</td>
                <td className="wrap sub-bg-color">Enterprise</td>
                <td className="wrap sub-bg-color">1 : 1</td>
                <td className="wrap sub-bg-color">1 : 4</td>
                <td className="wrap sub-bg-color">1 : 6</td>
                <td className="wrap sub-bg-color">2 Way</td>
                <td className="wrap sub-bg-color">Mini</td>
                <td className="wrap sub-bg-color">Green</td>
                <td className="wrap sub-bg-color">Blue</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    placeholder="Select date"
                    className="filter-input bg-white table-search stock-search-wrap"
                    name="date"
                    onClick={handleOpenDatePopup}
                    onFocus={handleOpenDatePopup}
                    readOnly
                    value={selectedRange}
                  />
                </td>

                <td>
                  <select
                    class="filter-select bg-white table-search"
                    name="category"
                  >
                    <option value="">Select</option>
                    <option value="Purchase">Purchase</option>
                    <option value="Recovery">Recovery</option>
                    <option value="Damage">Damage</option>
                    <option value="Instalation">Instalation</option>
                    <option value="Complaint">Complaint</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    class="filter-input bg-white table-search "
                    placeholder="Search"
                    name="search"
                    autoComplete="off"
                    value={searchTerm}
                    style={{ width: "100%" }}
                    onChange={handleSearchChange}
                  />
                </td>
                <td>
                  <select
                    class="filter-select bg-white table-search"
                    name="designation"
                    autoComplete="off"
                  >
                    <option value="">Select</option>
                    <option value="">Santhoesh</option>
                    <option value="">Ravikumar</option>
                    <option value="">Arun</option>
                  </select>
                </td>
              </tr>
              {stockEntries.map((entry, index) => (
                <tr key={index} onClick={() => goToAddStock(entry._id)}>
                  {/* Date, Category, Vendor, and Updated By */}
                  <td className="text-center">
                    {new Date(entry.date).toISOString().split("T")[0]}
                  </td>
                  <td className="text-center">{entry.category}</td>
                  <td className="text-center">{entry.name}</td>
                  <td className="text-center">{entry.updatedBy}</td>

                  {/* Fiber subcategories */}
                  {["2F", "4F", "6F"].map((type) => (
                    <td key={type} className="text-center">
                      {formatUnit(
                        entry.materials.find(
                          (material) =>
                            material.material === "Fiber" &&
                            material.materialType === type
                        )?.unit,
                        entry.category
                      )}
                    </td>
                  ))}

                  {/* Router subcategories */}
                  {["ONU", "Single band", "Dual band", "Enterprise"].map(
                    (type) => (
                      <td key={type} className="text-center">
                        {formatUnit(
                          entry.materials.find(
                            (material) =>
                              material.material === "Router" &&
                              material.materialType === type
                          )?.unit,
                          entry.category
                        )}
                      </td>
                    )
                  )}

                  {/* Splitter subcategories */}
                  {["1 : 1", "1 : 4", "1: 6"].map((type) => (
                    <td key={type} className="text-center">
                      {formatUnit(
                        entry.materials.find(
                          (material) =>
                            material.material === "Splitter" &&
                            material.materialType === type
                        )?.unit,
                        entry.category
                      )}
                    </td>
                  ))}

                  {/* Closure Box subcategories */}
                  {["2 Way", "Mini"].map((type) => (
                    <td key={type} className="text-center">
                      {formatUnit(
                        entry.materials.find(
                          (material) =>
                            material.material === "Closure Box" &&
                            material.materialType === type
                        )?.unit,
                        entry.category
                      )}
                    </td>
                  ))}

                  {/* Patch Chord and Cat 6 Cable */}
                  {["Green", "Blue"].map((type) => (
                    <td key={type} className="text-center">
                      {formatUnit(
                        entry.materials.find(
                          (material) =>
                            material.material === "Patch Chord" &&
                            material.materialType === type
                        )?.unit,
                        entry.category
                      )}
                    </td>
                  ))}

                  <td className="text-center">
                    {formatUnit(
                      entry.materials.find(
                        (material) => material.material === "Cat 6 Cable"
                      )?.unit,
                      entry.category
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
      /
    </div>
  );
};
export default Stock;
