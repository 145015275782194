import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import "./Customer.css";
import axios from "axios";
import Swal from "sweetalert2";
import Topbar from "../Topbar/Topbar";
import { FaDivide } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";


export default function Customer({ customerid, onDeleteSuccess }) {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [data, setData] = useState([]);
  const [customerdelete, setCustomerdelete] = useState(false);
  const [filters, setFilters] = useState({
    
    customerid: "",
    expirydate: "",
    customername: "",
    devicestatus: "",
    accountstatus: "",
    contactnum: "",
    alternatenum: "",
    emailid: "",
    area: "",
    accountmanager: "",
    activationdate: "",
    lastrenewaldate: "",
    lastlogoffdate: "",
    billingadd: "",
    installationadd: "",
    installationcharge: "",
    refunddeposit: "",
    refunddes: "",
    planname: "",
  });

  const [statusCounts, setStatusCounts] = useState({
    active: 0,
    inactive: 0,
    suspended: 0,
    disabled: 0,
    online: 0,
    offline: 0,
  });

  const [columnVisibility, setColumnVisibility] = useState({
    // selectAll: true,
    customerid: true,
    expirydate: true,
    customername: true,
    devicestatus: true,
    accountstatus: true,
    contactnum: true,
    alternatenum: true,
    emailid: true,
    area: true,
    accountmanager: true,
    activationdate: true,
    lastrenewaldate: true,
    lastlogoffdate: true,
    billingadd: true,
    installationadd: true,
    installationcharge: true,
    refunddeposit: true,
    refunddes: true,
    planname: true,
    delete: true,
  });

  useEffect(() => {
    axios.post(apiUrl+"view", {}).then((res) => {
      const customers = res.data.message || [];
      setData(customers);

      const counts = {
        active: customers.filter((c) => c.accountstatus === "Active").length,
        inactive: customers.filter((c) => c.accountstatus === "Inactive")
          .length,
        suspended: customers.filter((c) => c.accountstatus === "Suspended")
          .length,
        disabled: customers.filter((c) => c.accountstatus === "Disable").length,
        online: customers.filter((c) => c.devicestatus === "Online").length,
        offline: customers.filter((c) => c.devicestatus === "Offline").length,
      };
      setStatusCounts(counts);
    });
  }, [customerdelete]);

  const handleDelete = async (customerid) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.post(apiUrl+"delete", {
          customerid: customerid,
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        setCustomerdelete(true);
      } catch (error) {
        Swal.fire("Error!", "Something went wrong while deleting.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  const navi = useNavigate();

  const add = () => {
    navi("/add_customer");
  };

  const [setting, setSetting] = useState(false);

  const settingPopup = (e) => {
    e.preventDefault();
    setSetting(!setting);
  };

  const handleColumnVisibilityChange = (e) => {
    const { name, checked } = e.target;
    setColumnVisibility((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const filteredData = data.filter((customer) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      return String(customer[key]).toLowerCase().includes(filters[key]);
    });
  });

  const getAccountStatusClass = (status) => {
    if (!status) return "";
    switch (status.toLowerCase().trim()) {
      case "active":
        return "status-active";
      case "inactive":
        return "status-inactive";
      case "suspended":
        return "status-suspended";
      case "disable":
        return "status-disable";
      default:
        return "";
    }
  };

  const getDeviceStatusClass = (status) => {
    if (!status) return "";
    switch (status.toLowerCase().trim()) {
      case "online":
        return "device-online";
      case "offline":
        return "device-offline";
      default:
        return "";
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value.toLowerCase(), // Normalize input for case-insensitive search
    }));
  };

  const gotocustomeredit = (customerid) => {
    navi("/view_customer", {
      state: { customerid: customerid }, // Pass the customer ID to the edit page
    });
  };
  let changedata = (data) =>
  {
    if(data == "customerid")
    {
      return "  Customer Id"
    }
    if(data == "expirydate")
      {
        return " Expiry Date"
      }
      if(data == "customername")
        {
          return " Customer Name"
        }
    if(data == "devicestatus")
       {
            return " Device Status"
      }   
      if(data == "accountstatus")
        {
          return " Account Status"
        } 

        if(data == "contactnum")
          {
            return " Contact Number"
          }
          if(data == "alternatenum")
            {
              return " Alternate Number"
            }     
  if(data == "emailid")
        {
                return " Email ID "
    }
    if(data == "area")
      {
        return " Area"
      }
      if(data == "accountmanager")
        {
          return " Account Manager"
        } 
        
        if(data == "activationdate")
          {
            return " Activation Date"
          }
 if(data == "lastrenewaldate")
{
    return " Last Renewal Date"
} 
if(data == "lastlogoffdate")
  {
      return " Last Log Off Date"
  }
  if(data == "billingadd")
    {
        return " Billing Address"
    }
    
    if(data == "installationadd")
      {
          return " Installation Address"
      }
      
      if(data == "installationcharge")
        {
            return " Installation Charge"
        }
        if(data == "refunddeposit")
          {
              return " Refund Description"
          }  
          if(data == "planname")
            {
                return " Plan Name"
            } 
            
            if(data == "delete")
              {
                  return " Delete"
              }  
    else
    {
      return data;
    }

   
      
  }
  return (
    <div className="customer-scroll-stop">
      <Topbar />
      <Container fluid className="pt-1 customer-row">
        <h2 className="px-2">Customers</h2>

        <Row className=" row-width">
          <Col className="dashborder mt-4   mb-5 mx-2 ">
            <Row className="purple-card">
              <p className="text-center box-txt "
               style={{ marginTop: "15px" }}
               >
                Total Customers
              </p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-2">{data.length}</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1  ">
            <Row className="green-card">
              <p className="text-center  box-txt">Active</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1 ">{statusCounts.active}</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5  mx-1">
            <Row className="grey-card ">
              <p className="text-center  box-txt">Inactive</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">{statusCounts.inactive}</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="yellow-card row-margin">
              <p className="text-center  box-txt">Online</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">{statusCounts.online}</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="blue-card">
              <p className="text-center  box-txt">Offline</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">{statusCounts.offline}</h5>
            </Row>
          </Col>
          <Col className="dashborder mt-4 mb-5 mx-1">
            <Row className="brown-card">
              <p className="text-center  px-1 box-txt">Suspended</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">{statusCounts.suspended}</h5>
            </Row>{" "}
          </Col>
          <Col className="dashborder mx-1  mt-4 mb-5">
            <Row className="red-card">
              <p className="text-center  box-txt">Disable</p>
            </Row>
            <Row className="number">
              <h5 className="text-center pt-1">{statusCounts.disabled}</h5>
            </Row>
          </Col>
        </Row>

        <Row className="button-bottom-custmer" xs={1} sm={2}>
          <Col className="mt-2 mb-2">
            <button onClick={add} className="black-btn btnadd black-btn1">
              Add
            </button>{" "}
            &emsp;&emsp;
            <button
              onClick={() => window.location.reload()}
              className="black-btn black-btn1"
            >
              Refresh
            </button>
          </Col>

          <Col className="mt-2 text-end">
            <button className="black-btn black-btn1 btnadd">Export</button>{" "}
            &emsp;&emsp;
            <button onClick={settingPopup} className="black-btn black-btn1">
              Settings
            </button>
            {setting && (
              
              <div className="setting-popup-overlay">
                <div className="setting-popup-container">
                  <button className="setting-close-btn" onClick={settingPopup}>
                    &times;
                  </button>
                  <div className="setting-popup-content">
                  <div className="setting-checkbox-row">
  <label>
    <input
      className="checkbox-customer"
      type="checkbox"
      checked={Object.values(columnVisibility).every((v) => v)}
      onChange={(e) => {
        const isChecked = e.target.checked;
        setColumnVisibility(
          Object.keys(columnVisibility).reduce((acc, key) => {
            acc[key] = isChecked;
            return acc;
          }, {})
        );
      }}
    />
    <span className="checkbox-down ms-1" style={{ fontSize: "17px" }}>
      {Object.values(columnVisibility).every((v) => v) ? "Unselect All" : "Select All"}
    </span>
  </label>
</div>

                    <div className="setting-checkbox-grid">
                      {Object.keys(columnVisibility).map((key) => (
                        <label key={key} className="label-setting-popup">
                          <input
                            type="checkbox"
                            name={key}
                            checked={columnVisibility[key]}
                            onChange={handleColumnVisibilityChange}
                            className="checbox-popup"
                          />
                          <span className="checkbox-down1">
                            {changedata(key)}
                          </span>
                        </label>
                      ))}
                    </div>
 
                    <button
                      className="setting-submit-btn"
                      onClick={settingPopup}
                    >
                      Submit
                    </button>
 
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <br />

        <div className="scrolltable">
          <table className="table table-bordered">
            <thead className="table-th text-center">
              <tr>
                {columnVisibility.customerid && (
                  <th className="table-th-content">Customer ID</th>
                )}
                {columnVisibility.expirydate && (
                  <th className="table-th-content">Expiry Date</th>
                )}
                {columnVisibility.customername && (
                  <th className="table-th-content">Customer Name</th>
                )}
                {columnVisibility.devicestatus && (
                  <th className="table-th-content">Device Status</th>
                )}
                {columnVisibility.accountstatus && (
                  <th className="table-th-content">Account Status</th>
                )}
                {columnVisibility.contactnum && (
                  <th className="table-th-content">Contact Number</th>
                )}
                {columnVisibility.alternatenum && (
                  <th className="table-th-content">Alternate Number</th>
                )}
                {columnVisibility.emailid && (
                  <th className="table-th-content">Mail ID</th>
                )}
                {columnVisibility.area && (
                  <th className="table-th-content">Area</th>
                )}
                {columnVisibility.accountmanager && (
                  <th className="table-th-content">Account Manager</th>
                )}
                {columnVisibility.activationdate && (
                  <th className="table-th-content">Activation Date</th>
                )}
                {columnVisibility.lastrenewaldate && (
                  <th className="table-th-content">Last Renewal Date</th>
                )}
                {columnVisibility.lastlogoffdate && (
                  <th className="table-th-content">Last Logoff Date</th>
                )}
                {columnVisibility.billingadd && (
                  <th className="table-th-content">Billing Address</th>
                )}
                {columnVisibility.installationadd && (
                  <th className="table-th-content">Installation Address</th>
                )}
                {columnVisibility.installationcharge && (
                  <th className="table-th-content">Installation Charges</th>
                )}
                {columnVisibility.refunddeposit && (
                  <th className="table-th-content">Refund Deposit</th>
                )}
                {columnVisibility.refunddes && (
                  <th className="table-th-content">Refund Description</th>
                )}
                {columnVisibility.planname && (
                  <th className="table-th-content">Plan Name</th>
                )}
                {columnVisibility.delete && (
                  <th className="table-th-content">Delete</th>
                )}
              </tr>
            </thead>

            <tbody className="tr:nth-child(even)">
              <tr className="search-input-box-text-center">
                {" "}
                {columnVisibility.customerid && (
                  <td>
                    <input
                      className="table-search background-change"
                      name="customerid"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="text"
                    />
                  </td>
                )}
                {columnVisibility.expirydate && (
                  <td>
                    <input
                      className="table-search background-change"
                      type="date"
                      name="expirydate"
                      onChange={handleFilterChange}
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.customername && (
                  <td>
                    <input
                      className="table-search background-change"
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      name="customername"
                      onChange={handleFilterChange}
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.devicestatus && (
                  <td>
                    <select
                      className="table-search background-change"
                      name="devicestatus"
                      placeholder="Search"
                      onChange={handleFilterChange}
                    >
                      <option>Select</option>
                      <option value="online">Online</option>
                      <option value="offline">Offline</option>
                    </select>
                  </td>
                )}
                {columnVisibility.accountstatus && (
                  <td>
                    <select
                      className="table-search background-change"
                      name="accountstatus"
                      onChange={handleFilterChange}
                      placeholder="Search"
                    >
                      <option>Select</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                      <option value="suspended">Suspended</option>
                      <option value="disable">Disable</option>
                    </select>
                  </td>
                )}
                {columnVisibility.contactnum && (
                  <td>
                    <input
                      className="table-search background-change"
                      name="contactnum"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="tel"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      
                    />
                  </td>
                )}
                {columnVisibility.alternatenum && (
                  <td>
                    <input
                      className="table-search background-change"
                      name="alternatenum"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="tel"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                    />
                  </td>
                )}
                {columnVisibility.emailid && (
                  <td>
                    <input
                      className="table-search background-change"
                      name="emailid"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="email"
                    />
                  </td>
                )}
                {columnVisibility.area && (
                  <td>
                    <input
                      className="table-search background-change"
                      style={{ textTransform: "capitalize" }}
                      name="area"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="text"
                    />
                  </td>
                )}
                {columnVisibility.accountmanager && (
                  <td>
                    <input
                      className="table-search background-change"
                      style={{ textTransform: "capitalize" }}
                      name="accountmanager"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="text"
                    />
                  </td>
                )}
                {columnVisibility.activationdate && (
                  <td>
                    <input
                      className="table-search table-search-date background-change"
                      type="date"
                      name="activationdate"
                      onChange={handleFilterChange}
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.lastrenewaldate && (
                  <td>
                    <input
                      className="table-search table-search-date background-change"
                      type="date"
                      name="lastrenewaldate"
                      onChange={handleFilterChange}
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.lastlogoffdate && (
                  <td>
                    <input
                      className="table-search-date table-search background-change"
                      type="date"
                      name="lastlogoffdate"
                      onChange={handleFilterChange}
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.billingadd && (
                  <td>
                    <input
                      className="table-search background-change"
                      style={{ textTransform: "capitalize" }}
                      name="billingadd"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="text"
                    />
                  </td>
                )}
                {columnVisibility.installationadd && (
                  <td>
                    <input
                      className="table-search background-change"
                      style={{ textTransform: "capitalize" }}
                      name="installationadd"
                      onChange={handleFilterChange}
                      placeholder="Search"
                      type="text"
                    />
                  </td>
                )}
                {columnVisibility.installationcharge && (
                  <td>
                    <input
                      className="table-search background-change"
                      name="installationcharge"
                      onChange={handleFilterChange}
                      type="tel"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.refunddeposit && (
                  <td>
                    <input
                      className="table-search background-change"
                      name="refunddeposit"
                      onChange={handleFilterChange}
                      type="tel"
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key) || (e.key && !/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")) {
                         e.preventDefault();
                       }
                     }}
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.refunddes && (
                  <td>
                    <input
                      className="table-search background-change"
                      style={{ textTransform: "capitalize" }}
                      name="refunddes"
                      onChange={handleFilterChange}
                      type="text"
                      placeholder="Search"
                    />
                  </td>
                )}
                {columnVisibility.planname && (
                  <td>
                    <input
                      className="table-search background-change"
                      style={{ textTransform: "capitalize" }}
                      name="planname"
                      onChange={handleFilterChange}
                      type="text"
                      placeholder="Search"
                    />
                  </td>
                )}
              </tr>

              

       

              {filteredData.length === 0 ? (
  <tr>
    <td colSpan="100%" style={{ textAlign: "center" }}>No data found</td>
  </tr>
) : (
  filteredData.map((d, i) => (
    <tr key={i}>
      {columnVisibility.customerid && (
        <td onClick={() => gotocustomeredit(d.customerid)}>
          {d.customerid} <CiEdit />
        </td>
      )}
      {columnVisibility.expirydate && <td>{d.expirydate}</td>}
      {columnVisibility.customername && <td>{d.customername}</td>}
      {columnVisibility.devicestatus && (
        <td>
          <span className={getDeviceStatusClass(d.devicestatus)}>
            {d.devicestatus}
          </span>
        </td>
      )}
      {columnVisibility.accountstatus && (
        <td>
          <span className={getAccountStatusClass(d.accountstatus)}>
            {d.accountstatus}
          </span>
        </td>
      )}
      {columnVisibility.contactnum && <td>{d.contactnum}</td>}
      {columnVisibility.alternatenum && <td>{d.alternatenum}</td>}
      {columnVisibility.emailid && <td>{d.emailid}</td>}
      {columnVisibility.area && <td>{d.area}</td>}
      {columnVisibility.accountmanager && <td>{d.accountmanager}</td>}
      {columnVisibility.activationdate && <td>{d.activationdate}</td>}
      {columnVisibility.lastrenewaldate && <td>{d.lastrenewaldate}</td>}
      {columnVisibility.lastlogoffdate && <td>{d.lastlogoffdate}</td>}
      {columnVisibility.billingadd && <td>{d.billingadd}</td>}
      {columnVisibility.installationadd && <td>{d.installationadd}</td>}
      {columnVisibility.installationcharge && <td>{d.installationcharge}</td>}
      {columnVisibility.refunddeposit && <td>{d.refunddeposit}</td>}
      {columnVisibility.refunddes && <td>{d.refunddes}</td>}
      {columnVisibility.planname && <td>{d.planname}</td>}
      {columnVisibility.delete && (
        <td>
          <button
            onClick={() => handleDelete(d.customerid)}
            className="deletebtn text-center"
          >
            <MdDelete size={20} />
          </button>
        </td>
      )}
    </tr>
  ))
)}

         
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
}