import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setStaffid,setStaffname,setEmail,setDesignation } from "../Redux/actions";
import { useNavigate } from "react-router-dom";
const Logout = () => {
  const dispatch = useDispatch();
 const navigate = useNavigate();
 useEffect(() => {
    // Clear Redux state
    dispatch(setStaffid(""));
    dispatch(setStaffname(""));
    dispatch(setEmail(""));
    dispatch(setDesignation(""));

    // Navigate to login page
    navigate("/");
  }, [dispatch, navigate]);
  return <div>

  </div>;
};

export default Logout;
