import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import { FaStarOfLife } from "react-icons/fa6";
import { Modal, Box, Button } from "@mui/material";
import { ImCross } from "react-icons/im";
import { FiX, FiUpload } from "react-icons/fi"; // Close & Upload icons
import { MdPadding } from "react-icons/md";
import "./Add_Complaint.css"

export default function OrderDetails_Complaint() {
  const [installCostumerName, setinstallCustomerName] = useState("");
  const [installContactNum, setinstallContactNum] = useState("");
  const [installAlternateNum, setinstallAlternateNum] = useState("");
  const [installAddress, setinstallAddress] = useState("");
  const [installLocation, setinstallLocation] = useState("");
  const [installLeadSource, setinstallLeadSource] = useState("");
  const [installPlanName, setinstallPlanName] = useState("");
  const [installNextFollowUpDate, setinstallNextFollowUpDate] = useState("");
  const [installLeadStatus, setinstallLeadstatus] = useState("");
  const [installSalesName, setinstallSalesName] = useState("");
  const [installEmailId, setinstallEmailId] = useState("");
  const [installPlanSpeed, setinstallPlanSpeed] = useState("");
  const [installPlanMonth, setinstallPlanMonth] = useState("");
  const [installPlanAmount, setinstallPlanAmount] = useState("");
  const [installStaticIpCost, setinstallStaticIpCost] = useState("");
  const [installFiberCableMeter, setinstallFiberCableMeter] = useState("");
  const [installInstallationCost, setinstallInstallationCost] = useState("");
  const [installRouterModel, setinstallRouterModel] = useState("");
  const [installRouterCost, setinstallRouterCost] = useState("");
  const [installOttPackDetails, setinstallOttPackDetails] = useState("");
  const [installOthers, setinstallOthers] = useState("");
  const [installOthersCost, setinstallOthersCost] = useState("");
  const [installAdvanceAmount, setinstallAdvanceAmount] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);


  const[isOpenAdvancePayment,setIsOpenAdvancePayment]=useState(false)

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  let [error1, setError1] = useState("");
  let [error, setError] = useState("");

  const [isOpenplan, setIsOpenplan] = useState(false);
  const [isOpenComplaintOpen, setIsOpenComplaintOpen] = useState(false);

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Ensures a 10-digit number starting with 6-9
    return phoneRegex.test(number);
  };

  const handlePhoneNumberChange = (e) => {
    setinstallContactNum(e.target.value); // update phone number as user types
  };

  // Validate phone number when the input field loses focus (onBlur)
  const handlePhoneNumberBlur = () => {
    const regex = /^[0-9]{10}$/; // Regex for a 10-digit phone number
    if (!regex.test(installContactNum)) {
      setError("Please enter a valid 10-digit phone number");
    } else {
      setError("");
    }
  };

  const handlePhoneNumberChange1 = (e) => {
    setinstallAlternateNum(e.target.value); // update phone number as user types
  };

  // Validate phone number when the input field loses focus (onBlur)
  const handlePhoneNumberBlur1 = () => {
    const regex1 = /^[0-9]{10}$/; // Regex for a 10-digit phone number
    if (!regex1.test(installAlternateNum)) {
      setError1("Please enter a valid 10-digit phone number");
    } else {
      setError1("");
    }
  };

  return (
    <>
     
      
        <form>
        <div className='plan-details-container-border-complaint mt-5 text-center ms-5'>
  <p  style={{ fontSize:"13px"}}className='mt-3'><span className='text-primary'>Network</span> Complaint - <span className='text-primary'>Fiber Cut,</span> Customer Affected - <span className='text-primary'>Saravanan @ </span>Location - <span className='text-primary'>Ryy2345wwer,</span></p>                         
   <p style={{ fontSize:"13px"}}> Complaint Open Date - <span className='text-primary'>12/12/2024 , </span> Complaint Open Last - <span className='text-primary'>12 Days</span></p>                         
   </div>

          

      
            <Row className="text-start lead-id-row  mb-4 mt-4 ms-4 ">
              <p className="ps-5 paragraph-lead-id">
                Complaint ID - <span className="text-primary">A22</span>
                <span className="lead-start-date">
                  {" "}
                 Started Date & Time -
                  <span className="text-primary">12/12/2024 - 12.45 PM</span>
                </span>
              </p>
            </Row>
            <Row className="ms-3">
              <Col sm={12} md={6} className="text-start ">
                <lable>Customer Affected</lable>
                <br />
                <input
                  type="text"
                  style={{ textTransform: "capitalize " }}
                  className="form-input "
                  placeholder="Enter Custmer Name"
                />
              </Col>

              <Col sm={12} md={6} className="text-start ">
                <lable>Complaint Type</lable>
                <br />
                <input
                  type="text"
                  style={{ textTransform: "capitalize " }}
                  className="form-input complaint-type "
                  placeholder="Enter Complaint type"
                />
              </Col>
              </Row>

              

            <Row className="ms-3">
              <Col xs={12} md={6} className="text-start">
                <lable> Address</lable>
                <br />
                <textarea
                  type="text"
                  onChange={(e) => setinstallAddress(e.target.value)}
                  style={{ textTransform: "capitalize", resize: "none" }}
                  className=" box address-enquiry"
                  placeholder="Enter Installation Address"
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={6} className="text-start">
                    <lable>Dependent</lable>
                    <br />
                    {/* <input
                      
                      style={{ textTransform: "capitalize"}}
                      className="form-input complaint-checkbox "
                      type="checkbox"
                      placeholder="Enter Account Manager"
                    /> Network Team */}

<label class="custom-checkbox">
  <input type="checkbox"/>
  <span className="checkbox-complaint-margin">Network Team</span> 
</label>

                  </Col>
                  <Col xs={12} md={6} className="text-start">
                  <Row className="checkbox-complaint-top">
                  <label class="custom-checkbox ">
  <input type="checkbox"/>
  <span className="checkbox-complaint-margin">Fiber Team</span> 
</label>    
</Row>     
                  </Col>
                </Row>
                <Row>
                  <Row>
                                   <Col xs={12} md={6} className="text-start">
                                     <lable>
                                       Contact Number
                                       <sup>
                                         <FaStarOfLife size={7} />
                                       </sup>
                                     </lable>
                                     <br />
                                     <input
                                       type="tel"
                                       onKeyDown={(e) => {
                                         if (
                                           ["e", "E", "+", "-"].includes(e.key) ||
                                           (e.key &&
                                             !/[0-9]/.test(e.key) &&
                                             e.key !== "Backspace" &&
                                             e.key !== "ArrowLeft" &&
                                             e.key !== "ArrowRight")
                                         ) {
                                           e.preventDefault();
                                         }
                                       }}
                                       value={installContactNum}
                                       onBlur={handlePhoneNumberBlur}
                                       onChange={handlePhoneNumberChange}
                                       required
                                       placeholder="+91"
                                       className="form-input"
                                     />
                                     {error && <p style={{ color: "red" }}>{error}</p>}
                                   </Col>
                 
                                   <Col xs={12} md={6} className="text-start">
                                     <lable>Alternate Number </lable>
                                     <br />
                                     <input
                                       type="tel"
                                       onKeyDown={(e) => {
                                         if (
                                           ["e", "E", "+", "-"].includes(e.key) ||
                                           (e.key &&
                                             !/[0-9]/.test(e.key) &&
                                             e.key !== "Backspace" &&
                                             e.key !== "ArrowLeft" &&
                                             e.key !== "ArrowRight")
                                         ) {
                                           e.preventDefault();
                                         }
                                       }}
                                       value={installAlternateNum}
                                       onBlur={handlePhoneNumberBlur1}
                                       onChange={handlePhoneNumberChange1}
                                       className="form-input"
                                       placeholder="+91"
                                       name="phoneNumber2"
                                     />
                                     {error1 && <p style={{ color: "red" }}>{error1}</p>}
                                   </Col>
                                 </Row>
                                 <Row>
                                 
                                                    <Col xs={12} md={6} className="text-start">
                                                      <lable>Additional Charge</lable>
                                                      <br />
                                                      <input
                                                        className="field form-input"
                                                        
                                                        type="text"
                                                      />
                                                    </Col>
                                                    <Col xs={12} md={6} className="text-start">
                                                      <lable>Complaint Status</lable>
                                                      <br />
                                                      <button
                                                        type="button"
                                                        onClick={() => setIsOpenComplaintOpen(true)}
                                                        style={{backgroundColor:"#fbdc6e",color:"rgb(94, 94, 14)"}}
                                                        className="form-input converted-form "
                                                      >
                                                        Open
                                                      </button>
                                  
                                                      {isOpenComplaintOpen && (
                                                        <div className="overlay1-converted">
                                                          <div className="modal1-converted">
                                                            <Container>
                                                              <button
                                                                className="close-btn1"
                                                                onClick={() => setIsOpenComplaintOpen(false)}
                                                              >
                                                                <span style={{ fontSize: "20px" }}>
                                                                  {" "}
                                                                  &times;{" "}
                                                                </span>
                                                              </button>
                                  
                                                              <div className="open-button border">
                                                                <h5>Open</h5>
                                                              </div>
                                  
                                                              <textarea
                                                                style={{
                                                                  textTransform: "capitalize",
                                                                  resize: "none",
                                                                }}
                                                                placeholder="Enter Notes"
                                                                className="converted-textbox"
                                                              />
                                                              <div className="upload-section">
                                                                <label className="upload-btn">
                                                                  <FiUpload className="upload-icon" />
                                                                  Upload Image
                                                                  <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={handleFileChange}
                                                                    hidden
                                                                  />
                                                                </label>
                                                                {selectedFile && (
                                                                  <p className="file-name">{selectedFile.name}</p>
                                                                )}
                                                              </div>
                                  
                                                              <Row>
                                                                <Col className="date-update-textbox">
                                                                  <label>Next Follow Up Date</label>
                                                                  <br />
                                                                  <input
                                                                    type="date"
                                                                    className="field form-input"
                                                                  />
                                                                </Col>
                                                                <Col className="uploadbtn-install">
                                                                  <button type="button" className="blue-btn">
                                                                    Upload
                                                                  </button>
                                                                </Col>
                                                              </Row>
                                                            </Container>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Col>
                                                  </Row>
                                
                </Row>
              </Col>
            </Row>
           
         
        </form>
     


    </>
  );
}
