import React, { useState } from "react";
import { Link } from "react-router-dom";
 
import "./Header.css";
import Sidebar from "../Sidebar/Sidebar";
import { FaTachometerAlt, FaTasks } from "react-icons/fa";
import HeaderTop from "../Topbar/Topbar";
const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);

  return (
    <header className="header-section" id="sticky">
      <div className="container">
        <div className="hrader-topbar">
          <div className="row justify-content-between">
            {/* <Sidebar /> */}
            <div className="col-lg-3 col-3">
              <div
                className={`menu-open ${menuActive ? "d-none" : ""}`}
                onClick={() => setMenuState(true)}
              >
                <i className="ti-align-justify" id="bar_close"></i>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`side-menu ${menuActive ? "open-mobilemenu" : ""}`}
          id="menu_wrap"
        >
          <div className="menu-close">
            <div className="clox" onClick={() => setMenuState(!menuActive)}>
              <i className="ti-close"></i>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </header>
  );
};

export default Header;
