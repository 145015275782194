import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import "./AddStaff.css"; 
import axios from "axios";
import Topbar from "../Topbar/Topbar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdModeEdit } from "react-icons/md";


const AddStaff = () => {

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const apiUrlImage = process.env.REACT_APP_IMAGE_API_BASE_URL;
  // const [changesCount,setChangesCount] = useState({})


  const [staffId, setStaffId] = useState({ value: "", actualValue: "" });
  const [password, setPassword] = useState("");
  const [doj, setDoj] = useState("");
  const [hideButton, setHideButton] = useState(true);
  const [designation, setDesignation] = useState("");
  const [staffName, setStaffName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternativeNumber1, setAlternativeNumber1] = useState("");
  const [alternativeNumber2, setAlternativeNumber2] = useState("");
  const [address, setAddress] = useState("");
  const [aadhaarPhoto, setAadharPhoto] = useState("");
  const [educationCertificate, setEducationCertificate] = useState("");
  const [drivingLicence, setDrivingLicence] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [staffPhoto, setStaffPhoto] = useState("");
  const [actionStatus, setActionStatus] = useState("");
  const [originalData, setOriginalData] = useState({});

  const [error, setError] = useState(""); // for validation error
  const [error1, setError1] = useState(""); // for validation error
  const [error2, setError2] = useState("");
  const [errorIfsc, setErrorIfsc] = useState("");
  const [bankError, setBankError] = useState("");
  const [ifscCheck, setIfscCheck] = useState(false);
  const [bankCheck, setBankCheck] = useState(false);
  const [destinationRole, setDestinationrole] = useState([]);
  const params = new URLSearchParams(window.location.search);
  let userId = params.get("staffId");
  const [editable, setEditable] = useState(userId ? true : false);
//password icon
const [showpassword, setShowpassword] = useState(false);
const handleShowpassword = () => {
  // setChangesCount(pre=>({...pre,showpassword:pre.showpassword!==showpassword?1:0}))
  setShowpassword(!showpassword);
};
  const navigate = useNavigate();
  //ifsc validation
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

  const handleifscBlurStaffalt1 = (e) => {
    e.preventDefault();
    if (ifsc.trim() === "") {
      // If the field is empty, reset error and validation state
      // setChangesCount(pre=>({...pre,ifscCheck:pre.ifscCheck!==ifscCheck?1:0}))
      setErrorIfsc("");
      setIfscCheck(false);
      return;
    }
  
    // Validate IFSC if the field is not empty
    if (!ifscRegex.test(ifsc)) {
      setErrorIfsc("Invalid IFSC Code. Format: ABCD0123456");
      setIfscCheck(true);
    } else {
      setErrorIfsc("");
      setIfscCheck(false);
    }
  };
  
  //bankAccount validation
  const bankRegex = /^[0-9]{6,18}$/;

  const handleBankNumber = () => {
    if (bankAccountNumber.trim() === "") {
      // If the field is empty, reset error and validation state
      setBankError("");
      setBankCheck(false);
      return;
    }
    // Validate bank account number if the field is not empty
    if (!bankRegex.test(bankAccountNumber)) {
      setBankError("Bank account number must be between 9 to 18 digits.");
      setBankCheck(true);
    } else {
      setBankError("");
      setBankCheck(false);
    }
  };
  

  // Validate phone number when the input field loses focus (onBlur)
  const handlePhoneNumberBlurStaff = () => {
    const regex = /^[6-9][0-9]{9}$/; // Regex for a 10-digit phone number starting with 6, 7, 8, or 9
    if (!regex.test(contactNumber)) {
      setError("Please enter a valid 10-digit phone number starting with 6, 7, 8, or 9");
    } else {
      setError("");
    }
  };
 //alternative 1
  const handlePhoneNumberBlurStaffalt1 = () => {
    if (alternativeNumber1.trim() !== "") {
      const regex1 = /^[6-9][0-9]{9}$/; // Regex for a 10-digit phone number
      if (!regex1.test(alternativeNumber1)) {
        setError1("Please enter a valid 10-digit phone number");
      } else {
        setError1("");
      }
    } else {
      setError1(""); // Clear the error if the field is empty
    }
  };
//alternative 2
  const handlePhoneNumberBlurStaffalt2 = () => {
    if (alternativeNumber2.trim() !== "") {
      const regex1 = /^[6-9][0-9]{9}$/; // Regex for a 10-digit phone number
      if (!regex1.test(alternativeNumber2)) {
        setError2("Please enter a valid 10-digit phone number");
      } else {
        setError2("");
      }
    } else {
      setError2(""); // Clear the error if the field is empty
    }
  };

  const inputStaffId = (e) => {
    if (staffId.actualValue !== e.target.value && e.target.value) {
      checkStaffId(e.target.value);
    } else {
      setUniqueError("");
    }
    setStaffId((previous) => ({ ...previous, value: e.target.value }));
  };




  const [errorEmail, setErrorEmail] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu)$/i;
    return emailRegex.test(email);
  };

  const handleEmailBlur = () => {
    if (email.trim() !== "") {
      if (!validateEmail(email)) {
        setErrorEmail("Please enter a valid email address (e.g., example@domain.com).");
      } else {
        setErrorEmail("");
      }
    } else {
      setErrorEmail(""); // Clear the error if the field is empty
    }
  };
  //add axios
  const addStaff = async (e) => {
    e.preventDefault();
 

    // Validation check for 10 digits
     if (!/^[6-9][0-9]{9}$/.test(contactNumber)) {
      setError("Contact number must be exactly 10 digits.");
      alert("The contact number should be start with 6 to 9");
      return;
    } else {
      const formData = new FormData();
      formData.append("aadhaarPhoto", aadhaarPhoto);
      formData.append("drivingLicence", drivingLicence);
      formData.append("staffPhoto", staffPhoto);
      formData.append("educationCertificate", educationCertificate);
      formData.append("staffId", staffId.value);
      formData.append("password", password);
      formData.append("doj", doj);
      formData.append("designation", designation);
      formData.append("staffName", staffName);
      formData.append("email", email);
      formData.append("contactNumber", contactNumber);
      formData.append("alternativeNumber1", alternativeNumber1);
      formData.append("alternativeNumber2", alternativeNumber2);
      formData.append("address", address);
      formData.append("bankAccountNumber", bankAccountNumber);
      formData.append("ifsc", ifsc);
      formData.append("actionStatus", actionStatus);


      if (email && !validateEmail(email)) {
        setErrorEmail("Invalid email format. Must end with .com, .org, .net, or .edu");
       
        return; // Prevent submission if email is invalid
      } else {
        setErrorEmail(""); // Clear error if validation passes
      }
//alternative 1
      if (
        alternativeNumber1.trim() !== "" &&
        !/^[6-9][0-9]{9}$/.test(alternativeNumber1)
      ) {
        setError1("Please enter a valid 10-digit phone number");
        return; // Prevent form submission
      }
    
      // Clear error if valid or empty
      setError1("");
    
      //alternative 2
      if (
        alternativeNumber2.trim() !== "" &&
        !/^[6-9][0-9]{9}$/.test(alternativeNumber2)
      ) {
        setError1("Please enter a valid 10-digit phone number");
        return; // Prevent form submission
      }
    
      // Clear error if valid or empty
      setError1("");

      //bank const bankRegex = ;
    if (
      bankAccountNumber.trim() !== "" &&
      !/^[0-9]{6,18}$/.test(bankAccountNumber)
    ) {
      setBankError("Bank account number must be between 9 to 18 digits.");
      return; // Prevent form submission
    }
    setBankError("")

    //ifsc
    if (
      ifsc.trim() !== "" &&
      !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifsc)
    ) {
      setBankError("Invalid IFSC Code. Format: ABCD0123456");
      return; // Prevent form submission
    }
    setErrorIfsc("")
      
    

      try {
        Swal.fire({
          title: "Do you want to Save the data?",
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          // denyButtonText: `Don't save`
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await axios.post(apiUrl + "addstaff", formData);
              Swal.fire({
                title: "Staff Details Added Successfully!..",
                icon: "success",
                draggable: true,
              }).then(() => {
                navigate("/staffsection");
              });
            } catch (error) {
              console.log("Error submitting data", error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            }
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      } catch (error) {
        console.error("Error handling confirmation dialog", error);
      }
      
    }
  };

  //______________________
  const [isUnique, setIsUnique] = useState(true);
  const [uniqueError, setUniqueError] = useState("");

  useEffect(() => {
    if (!userId && staffId) {
      checkStaffId(staffId.value);
    }
  }, [staffId.value]);

  const checkStaffId = async (id) => {
    try {
      const response = await axios.get(apiUrl + `checkstaffid/${id}`);
      if (response.data.exists) {
        setIsUnique(false);
        setUniqueError("staff ID already exists!");
      } else {
        setIsUnique(true);
        setUniqueError("");
      }
    } catch (error) {
      console.error("Error checking staff Id:", error);
    }
  };
  //_________________

  //update axios

//   const updateStaff = async (e) => {
//     e.preventDefault();
    
//      if (! /^[6-9][0-9]{9}$/.test(contactNumber)) {
//       setError("Contact number must be exactly 10 digits.");
//       alert("The contact number should be start with 6 to 9");
//       return;
//     } 

//     const currentData = {
//       staffId: staffId.value,
//       password,
//       doj,
//       designation,
//       staffName,
//       email,
//       contactNumber,
//       alternativeNumber1,
//       alternativeNumber2,
//       address,
//       aadhaarPhoto,
//       educationCertificate,
//       drivingLicence,
//       bankAccountNumber,
//       ifsc,
//       staffPhoto,
//       actionStatus,
//     };

//     const hasChanges = Object.keys(currentData).some(
//       (key) => currentData[key] !== originalData[key]
//     );
  
//     if (!hasChanges) {
//       Swal.fire({
//         title: "No changes detected!",
//         text: "You haven't made any changes to the data.",
//         icon: "info",
//       });
//       return; // Stop the update process
//     }
//     const formData = new FormData();
//     formData.append("aadhaarPhoto", aadhaarPhoto);
//     formData.append("drivingLicence", drivingLicence);
//     formData.append("staffPhoto", staffPhoto);
//     formData.append("educationCertificate", educationCertificate);
//     formData.append("staffId", staffId.value);
//     formData.append("password", password);
//     formData.append("doj", doj);
//     formData.append("designation", designation);
//     formData.append("staffName", staffName);
//     formData.append("email", email);
//     formData.append("contactNumber", contactNumber);
//     formData.append("alternativeNumber1", alternativeNumber1);
//     formData.append("alternativeNumber2", alternativeNumber2);
//     formData.append("address", address);
//     formData.append("bankAccountNumber", bankAccountNumber);
//     formData.append("ifsc", ifsc);
//     formData.append("actionStatus", actionStatus);

//     if (email && !validateEmail(email)) {
//       setErrorEmail("Invalid email format. Must end with .com, .org, .net, or .edu");
     
//       return; // Prevent submission if email is invalid
//     } else {
//       setErrorEmail(""); // Clear error if validation passes
//     }
// //alternative 1
//     if (
//       alternativeNumber1.trim() !== "" &&
//       !/^[6-9][0-9]{9}$/.test(alternativeNumber1)
//     ) {
//       setError1("Please enter a valid 10-digit phone number");
//       return; // Prevent form submission
//     }
  
//     // Clear error if valid or empty
//     setError1("");
  
//     //alternative 2
    
//     if (
//       alternativeNumber2.trim() !== "" &&
//       !/^[6-9][0-9]{9}$/.test(alternativeNumber2)
//     ) {
//       setError2("Please enter a valid 10-digit phone number");
//       return; // Prevent form submission
//     }
  
//     // Clear error if valid or empty
//     setError2("");

// //bank const bankRegex = ;
//     if (
//       bankAccountNumber.trim() !== "" &&
//       !/^[0-9]{6,18}$/.test(bankAccountNumber)
//     ) {
//       setBankError("Bank account number must be between 9 to 18 digits.");
//       return; // Prevent form submission
//     }
  
//     // Clear error if valid or empty
//     setBankError("");

//      //ifsc
//      if (
//       ifsc.trim() !== "" &&
//       !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifsc)
//     ) {
//       setErrorIfsc("Invalid IFSC Code. Format: ABCD0123456");
//       return; // Prevent form submissionin
//     }

//     setErrorIfsc("")
//     try {
//       Swal.fire({
//         title: "Are you sure?",
//         text: "Do you want to save this data?",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonText: "Yes, save it!",
//         cancelButtonText: "No, cancel!",
//         reverseButtons: true,
//       }).then(async (result) => {
//         if (result.isConfirmed) {
//           await axios
//             .put(apiUrl + `update?userId=${userId}`, formData)
//             .then((res) => {
//               Swal.fire({
//                 title: "Staff Details Updated Successfully!..",
//                 icon: "success",
//                 draggable: true,
//               }).then(() => {
//                 navigate("/staffsection");
//               });
    
//               console.log(res.data);
//             })
//             .catch((error) => {
//               console.error("Error updating data:", error);
//               Swal.fire({
//                 title: "Error!",
//                 text: "Failed to update staff details.",
//                 icon: "error",
//               });
//             });
//         } else {
//           Swal.fire({
//             title: "Cancelled",
//             text: "Your changes were not saved.",
//             icon: "info",
//           });
//         }
//       });
//     } catch (error) {
//       console.log(error.message);
//     }
//   };
const updateStaff = async (e) => {
  e.preventDefault();

  // Validation checks
  if (!/^[6-9][0-9]{9}$/.test(contactNumber)) {
    setError("Contact number must be exactly 10 digits.");
    alert("The contact number should start with 6 to 9");
    return;
  }

  if (email && !validateEmail(email)) {
    setErrorEmail("Invalid email format. Must end with .com, .org, .net, or .edu");
    return;
  } else {
    setErrorEmail("");
  }

  if (
    alternativeNumber1.trim() !== "" &&
    !/^[6-9][0-9]{9}$/.test(alternativeNumber1)
  ) {
    setError1("Please enter a valid 10-digit phone number");
    return;
  }

  setError1("");

  if (
    alternativeNumber2.trim() !== "" &&
    !/^[6-9][0-9]{9}$/.test(alternativeNumber2)
  ) {
    setError2("Please enter a valid 10-digit phone number");
    return;
  }

  setError2("");

  if (
    bankAccountNumber.trim() !== "" &&
    !/^[0-9]{6,18}$/.test(bankAccountNumber)
  ) {
    setBankError("Bank account number must be between 6 to 18 digits.");
    return;
  }

  setBankError("");

  if (
    ifsc.trim() !== "" &&
    !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifsc)
  ) {
    setErrorIfsc("Invalid IFSC Code. Format: ABCD0123456");
    return;
  }

  setErrorIfsc("");

  // Collect only modified fields
  const currentData = {
    staffId: staffId.value,
    password,
    doj,
    designation,
    staffName,
    email,
    contactNumber,
    alternativeNumber1,
    alternativeNumber2,
    address,
    aadhaarPhoto,
    educationCertificate,
    drivingLicence,
    bankAccountNumber,
    ifsc,
    staffPhoto,
    actionStatus,
  };

  const modifiedData = {};
  Object.keys(currentData).forEach((key) => {
    if (currentData[key] !== originalData[key]) {
      modifiedData[key] = currentData[key];
    }
  });

  if (Object.keys(modifiedData).length === 0) {
    Swal.fire({
      title: "No changes detected!",
      text: "You haven't made any changes to the data.",
      icon: "info",
    });
    return; // Stop the update process
  }

  // Append modified fields to FormData
  const formData = new FormData();
  Object.keys(modifiedData).forEach((key) => {
    formData.append(key, modifiedData[key]);
  });

  try {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios
          .put(apiUrl + `update?userId=${userId}`, formData)
          .then((res) => {
            Swal.fire({
              title: "Staff Details Updated Successfully!..",
              icon: "success",
              draggable: true,
            }).then(() => {
              navigate("/staffsection");
            });
            console.log(res.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
            Swal.fire({
              title: "Error!",
              text: "Failed to update staff details.",
              icon: "error",
            });
          });
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your changes were not saved.",
          icon: "info",
        });
      }
    });
  } catch (error) {
    console.log(error.message);
  }
};

  useEffect(() => {
    async function apiCall() {
      await axios.get(apiUrl + `staff?staffId=${userId}`).then((response) => {
        const data = response?.data?.data;

        setOriginalData(data);
        setStaffId(() => ({
          value: data?.staffId,
          actualValue: data?.staffId,
        }));
        setPassword(data?.password);
        setDoj(data?.doj);
        setDesignation(data?.designation);
        setStaffName(data?.staffName);
        setEmail(data?.email);
        setContactNumber(data?.contactNumber);
        setAlternativeNumber1(data?.alternativeNumber1);
        setAlternativeNumber2(data?.alternativeNumber2);
        setAddress(data?.address);
        setAadharPhoto(data?.aadhaarPhoto);
        setEducationCertificate(data?.educationCertificate);
        setDrivingLicence(data?.drivingLicence);
        setBankAccountNumber(data?.bankAccountNumber);
        setIfsc(data?.ifsc);
        setStaffPhoto(data?.staffPhoto);
        setActionStatus(data?.actionStatus);
      });
    }
    if (userId) apiCall();
  }, []);

  //destination dropdown ===map from roles

  useEffect(() => {
    axios
      .post(apiUrl + "viewrole", {})
      .then((res) => {
        setDestinationrole(res.data.message);
        console.log(res.data.message);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);
  console.log(editable);

    // Get current date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];

  return (
    <Container>
      <Topbar />

      <Container fluid className="mt-3 add-container">
        <form onSubmit={userId ? updateStaff : addStaff}>

<Col className="submit text-end">
  {/* Show Edit button only if userId exists and hideButton is true */}
  {userId && hideButton && (
    <button
      type="button"
      className="black-btn text-white black-btn-re"
      onClick={(e) => {
        e.preventDefault();
        setEditable(false);
        setHideButton(false); // Hide Edit button when clicked, showing Submit
      }}
    >
     Edit <MdModeEdit className="staffedit-icon" /> 
    </button>
  )}

  {/* Show Submit button in two cases: 
      1. When adding a new staff (userId is undefined) 
      2. When editing and the Edit button was clicked (hideButton is false) */}
  {(!userId || !hideButton) && (
    <button
      type="submit"
      className="blue-btn px-4 text-white ms-2"
      disabled={!isUnique}
    >
      Submit
    </button>
  )}

  <ToastContainer />
</Col>
 

          <div className="form-head add-staff mb-0">
            {userId ? <h5 className="text-white">Update Staff</h5> : <h5 className="text-white">Add Staff</h5>}
          </div>

          <div className="add-staff-container">
            <div className="add-staff-form">
              <Row>
                <Col>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Staff Id*
                        </Form.Label>
                        <input
                          type="text"
                          placeholder="Enter ID"
                          required
                          value={staffId.value}
                          className="form-input"
                          onChange={inputStaffId}
                          disabled={editable}
                        />
                      </Form.Group>
                      {!isUnique && (
                        <p className="text-danger">{uniqueError}</p>
                      )}
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Password
                        </Form.Label>
                        <input
                         type={showpassword ? "text" : "password"}
                         
                          placeholder="Enter Password"
                          value={password}
                          className="form-input"
                          onChange={(e) => setPassword(e.target.value)}
                          disabled={editable}
                        />
                        <label onClick={handleShowpassword} className="staff-show-hide">
                                        {showpassword ? <BiSolidHide /> : <BiSolidShow />}
                                      </label>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Date of Join
                        </Form.Label>

                        <input
                          type="date"
                          className="form-input"
                          value={doj}
                          onChange={(e) => setDoj(e.target.value)}
                          max={today} // Restricts future dates
                          disabled={editable}
                        />
                        
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                      <Form.Group className="custom-dropdown">
                        <Form.Label className="label-margin-1">
                          Designation
                        </Form.Label>
                        <Form.Select
                          className="form-input "
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                          disabled={editable}
                        >
                          <option>Select</option>

                          {destinationRole.map((data, i) => {
                            return <option>{data.role}</option>;
                          })}
                         
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="label-margin-2">
                      Staff Name
                    </Form.Label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter Staff name"
                      value={staffName}
                      className="form-input inputfield "
                      style={{ textTransform: "capitalize" }}
                      disabled={editable}
                      onChange={(e) => setStaffName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="label-margin-2">Email Id</Form.Label>
                    <br />
                    <input
                      type="email"
                      placeholder="Enter Email ID"
                      value={email}
                      className="form-input inputfield "
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={editable}
                      onBlur={handleEmailBlur}
                    />
                    {errorEmail && <p style={{ color: "red" }}>{errorEmail}</p>}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Contact Number*
                        </Form.Label>
                        <InputGroup>
                          
                          <input
                            type="tel"
                            onKeyDown={(e) => {
                              if (
                                ["e", "E", "+", "-"].includes(e.key) ||
                                (e.key &&
                                  !/[0-9]/.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight")
                              ) {
                                e.preventDefault();
                              }
                            }}
                            required
                            placeholder="Enter Phone Number"
                            className="form-input"
                            disabled={editable}
                            value={contactNumber}
                            onBlur={handlePhoneNumberBlurStaff}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setContactNumber(e.target.value);
                              }
                            }}
                          />
                        </InputGroup>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Alternate Number 1
                        </Form.Label>
                        <InputGroup>
                          
 


<input
      type="tel"
      onKeyDown={(e) => {
        if (
          ["e", "E", "+", "-"].includes(e.key) ||
          (e.key &&
            !/[0-9]/.test(e.key) &&
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight")
        ) {
          e.preventDefault();
        }
      }}
      value={alternativeNumber1}
      placeholder="Enter Phone Number"
      className="form-input"
      disabled={editable}
      onChange={(e) => {
        if (e.target.value.length <= 10) {
          setAlternativeNumber1(e.target.value);
        }
      }}
      onBlur={handlePhoneNumberBlurStaffalt1}
    />
                        </InputGroup>
                        {error1 && <p style={{ color: "red" }}>{error1}</p>}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Alternate Number 2
                        </Form.Label>
                        <br />

                      
                        <input
                          type="tel"
                          placeholder="Enter Phone Number"
                          className="form-input"
                          disabled={editable}
                          onKeyDown={(e) => {
                            if (
                              ["e", "E", "+", "-"].includes(e.key) ||
                              (e.key &&
                                !/[0-9]/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight")
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={handlePhoneNumberBlurStaffalt2}
                          value={alternativeNumber2}
                          onChange={(e) => {
                            if (e.target.value.length <= 10) {
                              setAlternativeNumber2(e.target.value);
                            }
                          }}
                        />

                        {error2 && <p style={{ color: "red" }}>{error2}</p>}
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Aadhaar
                        </Form.Label>

                        <input
                          type="file"
                          variant="light"
                          className="btn-upload   file-upload form-input"
                          disabled={editable}
                          style={{ height: "45px" }}
                          onChange={(e) => setAadharPhoto(e.target.files[0])}
                        />
                      </Form.Group>

                      {userId ? (
                        // Display photo only on the update page
                        <div>
                          <a
                            href={apiUrlImage + `${aadhaarPhoto?.fileName}`}
                            download={aadhaarPhoto?.fileName} // Allows downloading the file
                          >
                            <img
                              alt=""

                              src={
                                aadhaarPhoto?.fileName
                                  ? apiUrlImage + aadhaarPhoto.fileName
                                  : apiUrlImage + "default.jpg"
                              }
                              width={50}
                              height={50}
                              style={{
                                borderRadius: "50%",
                                padding: "5px",
                                marginLeft: "65px",
                              }}
                            />
                          </a>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="label-margin-2">Address</Form.Label>
                    <br />
                    <textarea
                      as="textarea"
                      rows={5}
                      placeholder="Enter Address"
                      className="form-input form-input-extent"
                      disabled={editable}
                      value={address}
                      style={{ textTransform: "capitalize", resize: "none" }}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Education Certificate
                        </Form.Label>
                        <input
                          type="file"
                          // variant="light"
                          className="btn-upload   file-upload form-input"
                          style={{ height: "45px" }}
                          onChange={(e) =>
                            setEducationCertificate(e.target.files[0])
                          }
                          disabled={editable}
                        />
                      </Form.Group>
                      {userId ? (
                        // Display photo only on the update page
                        <div>
                          <a
                            href={
                              apiUrlImage + `${educationCertificate?.fileName}`
                            }
                            download={educationCertificate?.fileName} // Allows downloading the file
                          >
                            <img
                              alt=""

                              src={
                                educationCertificate?.fileName
                                  ? apiUrlImage + educationCertificate.fileName
                                  : apiUrlImage + "default.jpg"
                              }
                              width={50}
                              height={50}
                              style={{
                                borderRadius: "50%",
                                padding: "5px",
                                marginLeft: "65px",
                              }}
                            />
                          </a>
                        </div>
                      ) : null}
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Driving Licence
                        </Form.Label>
                        <input
                          type="file"
                          variant="light"
                          className="btn-upload  file-upload form-input"
                          style={{ height: "45px" }}
                          disabled={editable}
                          onChange={(e) => setDrivingLicence(e.target.files[0])}
                        />
                      </Form.Group>
                      {userId ? (
                        // Display photo only on the update page
                        <div>
                          <a
                            href={apiUrlImage + `${drivingLicence?.fileName}`}
                            download={drivingLicence?.fileName} // Allows downloading the file
                          >
                            <img
                              alt=""
                              src={
                                drivingLicence?.fileName
                                  ? apiUrlImage + drivingLicence.fileName
                                  : apiUrlImage + "default.jpg"
                              }
                              width={50}
                              height={50}
                              style={{
                                borderRadius: "50%",
                                padding: "5px",
                                marginLeft: "65px",
                              }}
                            />
                          </a>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Bank Account Number
                        </Form.Label>
                        <InputGroup>
                          {/* <InputGroup.Text>+91</InputGroup.Text> */}
                          <input
                            type="text"
                            onBlur={handleBankNumber}
                            value={bankAccountNumber}
                            placeholder="Enter Bank Account Number"
                            className="form-input"
                            onChange={(e) =>
                              setBankAccountNumber(e.target.value)
                            }
                            disabled={editable}
                          />
                        </InputGroup>
                        {bankError && (
                          <p style={{ color: "red" }}>{bankError}</p>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          IFSC Code
                        </Form.Label>
                        <input
                          type="text"
                          placeholder="Enter Ifsc Code"
                          value={ifsc}
                          onBlur={handleifscBlurStaffalt1}
                          className="form-input"
                          maxLength="11"
                          // style={{ textTransform: "uppercase" }}
                          onChange={(e) => setIfsc(e.target.value)}
                          disabled={editable}
                        />
                        {errorIfsc && (
                          <p style={{ color: "red" }}>{errorIfsc}</p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="label-margin-1">
                          Staff Photo
                        </Form.Label>
                        <input
                          type="file"
                          variant="light"
                          className="btn-upload w-100  file-upload form-input"
                          style={{ height: "45px" }}
                          onChange={(e) => setStaffPhoto(e.target.files[0])}
                          disabled={editable}
                        />
                      </Form.Group>
                      {userId ? (
                        // Display photo only on the update page
                        <div>
                          <a
                            href={
                              staffPhoto?.fileName
                                ? apiUrlImage + staffPhoto.fileName
                                : apiUrlImage + "default.jpg"
                            }
                            download={staffPhoto?.fileName || "default.jpg"} // Allows downloading the file
                          >
                            <img
                              alt="Staff"
                              src={
                                staffPhoto?.fileName
                                  ? apiUrlImage + staffPhoto.fileName
                                  : apiUrlImage + "default.jpg"
                              }
                              width={50}
                              height={50}
                              style={{
                                borderRadius: "50%",
                                padding: "5px",
                                marginLeft: "65px",
                              }}
                            />
                          </a>
                        </div>
                      ) : null}
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group className="custom-dropdown">
                        <Form.Label className="label-margin-1">
                          Activity Status
                        </Form.Label>
                        <Form.Select
                          className="form-input-select"
                          value={actionStatus}
                          onChange={(e) => setActionStatus(e.target.value)}
                          disabled={editable}
                        >
                          <option
                            className="activity-status-active"
                            value="select"
                          >
                            Selete
                          </option>
                          <option
                            className="activity-status-active"
                            value="Active"
                          >
                            Active
                          </option>
                          <option
                            className="activity-status-inactive"
                            value="Inactive"
                          >
                            Inactive
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Container>
    </Container>
  );
};
export default AddStaff;
