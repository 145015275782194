import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { Container, Row, Col } from "react-bootstrap";
import "./Login.css"; // Ensure you have the CSS file
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setStaffid,
  setStaffname,
  setEmail,
  setDesignation,
} from "../Redux/actions";

const Login = () => {
  const [staffId, setstaffId] = useState();
  const [password, setPassword] = useState();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const gotoPassword = () => {
    navigate("/password");
  };
  const handleShow = () => {
    setShow(!show);
  };

  const [staffIdError, setstaffIdError] = useState("");

  const Id = useSelector((state) => state.staffid);

  useEffect(() => {
    if (Id != "") {
      navigate("/dashboard"); // If logged out, stay on the login page
    }
  }, [Id, navigate]);

  const validatestaffId = () => {
    if (!staffId) {
      setstaffIdError("User ID is required");
      return false;
    }

    // Regex: First 6 characters must be alphanumeric, total length must be at least 8
    const regex = /^[a-zA-Z0-9]{6}.{2,}$/;

    if (!regex.test(staffId)) {
      setstaffIdError(
        "User ID must start with 6 alphanumeric characters and be at least 8 characters long"
      );
      return false;
    }

    setstaffIdError("");
    return true;
  };

  const [passwordError, setpasswordError] = useState("");
  const validatePassword = () => {
    if (!password) {
      setpasswordError("Password is required");
      return false;
    }
    if (password.length < 6) {
      setpasswordError("Password must be at least 6 characters long.");
      return false;
    }
    setpasswordError("");
    return true;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const isstaffIdValid = validatestaffId();
    const isPasswordValid = validatePassword();
    if (!isstaffIdValid || !isPasswordValid) return;

    axios
      .post(apiUrl + "login", {
        staffId: staffId,
        password: password,
      })
      .then((res) => {
        console.log(res.data.email);

        if (res.data.message === "Login Successful") {
          toast.success(res.data.message || "Login Successful! 🎉");
          dispatch(setStaffid(res.data.staffId));
          dispatch(setStaffname(res.data.staffName));
          dispatch(setEmail(res.data.email));
          dispatch(setDesignation(res.data.designation));

          setTimeout(() => navigate("/dashboard"), 3000);
        } else {
          toast.error("Login failed. Please check your credentials ❌");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Login failed. Please check your credentials ❌");
      });
  };

  return (
    <div className="container-fluid login">
      <div className="login-col">
        <h1 className="login-txt">Log In</h1>

        <form onSubmit={handleLogin} className="login-form">
          <label>
            <b>Staff ID</b>
          </label>
          <input
            value={staffId}
            type="text"
            name="staffId"
            onBlur={validatestaffId}
            onChange={(e) => setstaffId(e.target.value)}
            placeholder="Enter Your Staff ID"
          />
          {staffIdError && <p style={{ color: "red" }}>{staffIdError}</p>}

          <label>
            <b>Password</b>
          </label>
          <input
            type={show ? "text" : "password"}
            name="password"
            onBlur={validatePassword}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Your Password"
          />
          <label onClick={handleShow} className="show-hide">
            {show ? <BiSolidHide /> : <BiSolidShow />}
          </label>
          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}

          <button onClick={gotoPassword} className="f-password" type="button">
            Forgot Password?
          </button>

          <button type="submit" className="log-btn">
            Log In
          </button>
          <br></br>
          <ToastContainer />
        </form>
      </div>
    </div>
  );
};

export default Login;
