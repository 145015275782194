import React, { useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import Topbar from "../Topbar/Topbar";
import "./Location.css";
import { FaTrash } from "react-icons/fa";

export default function Location() {
  const [selected, setSelected] = useState("");
  const [show, setShow] = useState(false);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const tableData = [
    {
      date: "24/12/2024",
      time: "12.30PM",
      type: "In",
      location: "45 ft road",
      id: "2351qr123124",
      contact: "2341341414244",
    },
    {
      date: "24/12/2024",
      time: "12.30PM",
      type: "Complaint",
      location: "Lawspet",
      id: "2351qr123124",
      contact: "2341341414244",
    },
    {
      date: "24/12/2024",
      time: "12.30PM",
      type: "Recovery",
      location: "Anna nagar",
      id: "2351qr123124",
      contact: "2341341414244",
    },
    {
      date: "24/12/2024",
      time: "12.30PM",
      type: "Break",
      location: "Office",
      id: "2351qr123124",
      contact: "2341341414244",
    },
    {
      date: "24/12/2024",
      time: "12.30PM",
      type: "In",
      location: "Office",
      id: "2351qr123124",
      contact: "2341341414244",
    },
    {
      date: "24/12/2024",
      time: "12.30PM",
      type: "Out",
      location: "Office",
      id: "2351qr123124",
      contact: "2341341414244",
    },
  ];

  console.log(tableData);

  return (
    <div>
      <Topbar />
      <div className="container">
        <h3>Location</h3>
        <Row className="row-btn mt-5 mb-5">
          <Col className="locationRow-btn1" sm={12} md={3}>
            <select
              className="form-select"
              value={selected}
              onChange={handleChange}
              style={{ backgroundColor: "#EEF7FF" }}
            >
              <option value="">Select Staff</option>
              <option value="Saravanan">Saravanan</option>
              <option value="Arun">Arun</option>
              <option value="Ravikumar">Ravikumar</option>
              <option value="Santhosh">Santhosh</option>
            </select>

            <Dropdown
              show={show}
              onToggle={(isOpen) => setShow(isOpen)}
              className="location-month-calendar"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Select Date Range</Tooltip>}
              >
                <button
                  className="location-btn btn"
                  onClick={() => setShow(!show)}
                >
                  Last Month&emsp;
                  <FaCalendarAlt className="ms-4" />
                </button>
              </OverlayTrigger>

              <Dropdown.Menu>
                <Dropdown.Item>Today</Dropdown.Item>
                <Dropdown.Item>Yesterday</Dropdown.Item>
                <Dropdown.Item>Last 7 Days</Dropdown.Item>
                <Dropdown.Item>Last 30 Days</Dropdown.Item>
                <Dropdown.Item>This Month</Dropdown.Item>
                <Dropdown.Item>Last Month</Dropdown.Item>
                <Dropdown.Item>Custom Range</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col sm={12} md={3}></Col>
          <Col sm={12} md={3}></Col>

          <Col className="row-btn2 align-items-center" sm={12} md={3}>
            <button
              className="black-btn me-3"
              onClick={() => window.location.reload()}
            >
              Refresh
            </button>

            <button className="black-btn">Export</button>
          </Col>
        </Row>

        <div className="scrolltable">
          <table className="table table-bordered">
            <thead className="table-th text-center">
              <tr>
                <th className="table-th-content">Date</th>
                <th className="table-th-content">Time</th>
                <th className="table-th-content">Activity</th>
                <th className="table-th-content">Address</th>
                <th className="table-th-content">Latitude</th>
                <th className="table-th-content">Longitude</th>
                <th className="table-th-content">Delete</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : ""}
                >
                  <td className="border border-gray-300 p-2 text-center">
                    {item.date}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {item.time}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {item.type}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {item.location}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {item.id}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {item.contact}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    <FaTrash />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
