import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { useState } from 'react';

export default function FiberTeam_Complaint() {

  const [unitFiberTeam, setUnitFiberTeam] = useState(0);

  const incrementUnitFiberTeam = () => {
    setUnitFiberTeam(prevUnit => prevUnit + 1);
  };

  return (
    <div>
        <Container className='fiberteam-container'>
            <form>
            <div className='plan-details-container-border-complaint mt-5 text-center ms-5'>
  <p  style={{ fontSize:"13px"}}className='mt-3'><span className='text-primary'>Network</span> Complaint - <span className='text-primary'>Fiber Cut,</span> Customer Affected - <span className='text-primary'>Saravanan @ </span>Location - <span className='text-primary'>Ryy2345wwer,</span></p>                         
   <p style={{ fontSize:"13px"}}> Complaint Open Date - <span className='text-primary'>12/12/2024 , </span> Complaint Open Last - <span className='text-primary'>12 Days</span></p>                         
   </div>
   <div className='mt-5 mb-4' >
   <Row className='fiber-team-assignedby pt-1  ms-1 '>
    <p  className='ms-5'>Assigned By<span className='text-primary'> Ravikumar</span><span className='ms-5'>  Assigned Date </span><span className='text-primary'> 12/12/2024</span></p>
   </Row>
   </div>
   <label><strong> Staff Name</strong></label>
   <div className='staff-name-fiberteam mt-3 mb-3'>
    <Row className='fiber-team-work-container'><Col md={3} sm={3}>
    <div className='fiber-team-name'>
      
    <p>Shanmugam</p>
    </div>
    
    </Col>
    <Col md={3} sm={3}>
    <div className='fiber-team-name' >
    <p>vimal</p>
    </div>
    </Col>
    </Row>
   </div>
<lable>Added Materials</lable>
<div className='fiber-team-fiber-container mt-3 mb-4'>
  <Row className='mt-2'>
    <Col ms={2} sm={2}>
    <p>Fiber(2F) - <span className='text-primary'>20 M</span></p>
    </Col>
    <Col ms={2} sm={2}>
    <p>Splitter(1:1) - <span className='text-primary'>5</span></p>
    </Col>
    <Col ms={2} sm={2}>
    <p>Blue Patch Chord - <span className='text-primary'>2</span></p>
    </Col>
  </Row>
</div>
<Row  className='mb-5'>
  <Col md={3} sm={3}>
  <label>Materials Required</label>
  <br/>
  <select
        
          class="dropbtn form-input-select overlay2"
          >
         <option value="">Select</option>
          <option
        className="onlineback"

        value="Online"
        >
         Online
        </option>
         <option className="offlineback" value="Offline">
         Offline
         </option>
      </select>
  
  </Col>
  <Col md={3} sm={3}>
  <label>
    Unit</label>
    <br/>
    <input 
    className='form-input text-center'
    value={unitFiberTeam} readOnly 
    />


    
    </Col>
    <Col sm={1} md={1} className='text-start'>
    
<button type="button" className='increment-btn-fiber bg-primary' onClick={incrementUnitFiberTeam}><strong><span style={{fontSize:"20px"}}>+</span></strong></button>
    
    </Col>
</Row>

            </form>
        </Container>

    </div>
  )
}
